import { useLazyQuery } from '@apollo/client';
import { useUser } from 'components/UserManagment/UserProvider';
import { FILES_BY_USERS } from 'components/Whiteboard/graphQL/queries';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import DeleteListFileExam from './DeleteListFileExam';
import { GoTrash } from 'react-icons/go';
import { IoIosClose } from 'react-icons/io';
import ExamModal from './ExamModal';

import './styles/viewListFilesExam.css';

const ViewListFilesExam = ({ viewFileExams, setviewFileExams }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExamUrl, setSelectedExamUrl] = useState('');
  const fileNameExam = localStorage.getItem('fileNameExam');
  //ocultar un componente view al momento de abrir el examen en whiteboard
  const [showComponentViewListFiles, setShowComponentViewListFiles] = useState(true);

  const handleCloseAll = () => {
    setviewFileExams(false);
    setIsModalOpen(false); // Asegura que el modal se cierre
  };

  const history = useHistory();
  const { user } = useUser();
  const [getFilesByUsers, { loading: queryLoading, error, data: filesData }] =
    useLazyQuery(FILES_BY_USERS, {
      variables: { userId: user.attributes.sub },
      fetchPolicy: 'network-only'
    });

  const [filesExam, setfilesExam] = useState();
  const [selectExam, setselectExam] = useState();
  const [deleteExam, setdeleteExam] = useState(false);

  const containerRef = useRef(null);

  const handleClickOutside = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      handleCloseAll();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getFilesByUsers();
  }, [viewFileExams, deleteExam, selectExam, fileNameExam]);

  useEffect(() => {
    if (filesData?.filesByUsers?.items) {
      const examFiles = filesData.filesByUsers.items
        .filter(file => file.mode.includes('exam'))
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setfilesExam(examFiles);
    }
  }, [filesData]);

  const formatFecha = fecha => {
    const dateObject = new Date(fecha);
    const dia = dateObject.getDate();
    const mes = dateObject.getMonth() + 1;
    const año = dateObject.getFullYear();
    return `${dia}/${mes}/${año}`;
  };

  const handleLinkUpdateExam = url => {
    handleCloseAll();
    history.push('/admin/exam-editor/' + url);
  };

  const handleLinkToExam = url => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 750) {
      history.push('/take-exam/' + url);
    } else {
      setSelectedExamUrl(url);
      setIsModalOpen(true);
      setShowComponentViewListFiles(false);
    }
  };

  if (!viewFileExams) return null;

  return (
    <div
      className={`viewListFilesExam__container ${
        viewFileExams ? '' : 'closeViewListFilesExam'
      }`}
      ref={containerRef}
    >
      {showComponentViewListFiles && (
        <div className="viewListFilesExam__map-container">
          <div className="close-list">
            <IoIosClose
              className="click-closeViewListFilesExam"
              onClick={handleCloseAll}
            />
          </div>

          {filesExam?.map(exam => (
            <article key={exam.id} className="viewListFilesExam__exam-article">
              <section
                className="viewListFilesExam__exam-view-update"
                onClick={() => {
                  handleLinkUpdateExam(exam.url);
                  localStorage.setItem('fileExamID', exam.url);
                  localStorage.setItem('fileNameExam', exam.name);
                }}
              >
                <svg
                  width="43"
                  height="43"
                  viewBox="0 15 87 87"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="12"
                    y="23"
                    width="63"
                    height="70"
                    rx="8"
                    stroke="white"
                    stroke-width="4"
                  />
                  <rect
                    x="31.8526"
                    y="71.2571"
                    width="58.9119"
                    height="6.49592"
                    transform="rotate(-53.0088 31.8526 71.2571)"
                    stroke="white"
                    stroke-width="2"
                  />
                  <path d="M31 71L31.8819 71.3263L28.5 80.5L28 81L31 71Z" fill="white" />
                  <path
                    d="M37.301 76.4803L36.3966 74.9642L28.5 80.5L28 81L37.301 76.4803Z"
                    fill="white"
                  />
                </svg>
                Editable
              </section>
              <section className="viewListFilesExam__exam-data">
                <div>
                  <p>{exam.name}</p>
                  <p>-</p>
                  <p>{formatFecha(exam.updatedAt)}</p>
                </div>
                <span></span>
                {deleteExam && exam.id === selectExam?.id ? (
                  <DeleteListFileExam
                    selectExam={selectExam}
                    setselectExam={setselectExam}
                    setviewFileExams={setviewFileExams}
                  />
                ) : (
                  <GoTrash
                    style={{ fontSize: '20px', color: '#fff', lineHeight: '1' }}
                    onClick={() => {
                      setselectExam(exam);
                      setdeleteExam(true);
                    }}
                  />
                )}
              </section>
              <section
                className="viewListFilesExam__exam-view-update"
                onClick={() => handleLinkToExam(exam.url)}
              >
                <svg
                  width="52"
                  height="48"
                  viewBox="0 20 87 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="scale(1, -1) translate(0, -80)">
                    <rect
                      x="12"
                      y="0"
                      width="52"
                      height="60"
                      rx="8"
                      stroke="white"
                      stroke-width="4"
                    />
                    <rect x="22" y="45" width="29" height="2" fill="white" />
                    <rect x="22" y="38" width="29" height="2" fill="white" />
                  </g>
                </svg>
                Exam
              </section>
            </article>
          ))}
        </div>
      )}
      {isModalOpen && <ExamModal examUrl={selectedExamUrl} onClose={handleCloseAll} />}
    </div>
  );
};

export default ViewListFilesExam;
