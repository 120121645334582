// React y Hooks
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// Componentes y Hooks personalizados
import { useUser } from 'components/UserManagment/UserProvider';
import { useUserRole } from 'services/cognito.service';
import { useUserClassRoom } from 'components/UserManagment/hooks/useUserClassRoom';

// Constantes y estilos
import { USER_ROLES } from 'enums/constants.enum';
import styles from './styles.module.scss';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ReactComponent as HomeIcon } from 'assets/whiteboard/black/home-black.svg';
import { ReactComponent as LiveIcon } from 'assets/whiteboard/live-blue.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/sync.svg';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { exitFullScreen } from 'components/DigitalBook/funtions/utils';

export default function HeaderSlideView() {
  const { user } = useUser();
  const { classRoomName } = useUserClassRoom();
  const { state } = useCurrentSlide();
  const history = useHistory();
  const userRole = useUserRole();

  const handleBack = () => {
    exitFullScreen();
    history.push('/learning-content/document');
  };

  const handleBackHome = () => {
    history.push('/student-profile');
  };

  return (
    <div
      className={[
        styles.headerButtonsAndPage,
        styles.headerButtonsAndPage__noEdit,
        styles.headerButtonsAndPage__readOnly
      ].join(' ')}
    >
      <div className={styles.containerHeaderLeft}>
        <BackIcon
          style={{ color: 'blue', zIndex: '1000' }}
          onClick={handleBack}
          className={`${styles.backIcon} ${styles.backIcon__readOnly}`}
        />

        <div onClick={handleBackHome}>
          <HomeIcon className={styles.homeIcon} />
        </div>

        <div className={styles.texts}>
          <span style={{ fontSize: '15px' }}>
            P{state.propsLevel.numeration + state.propsLevel.slideIndex + 1}
          </span>
          <span style={{ fontSize: '15px' }}>{user?.attributes.name}</span>
        </div>
      </div>

      <div className={styles.containerHeaderCenter}>
        <p className={styles.userCurrentLevel}>
          {classRoomName.replace('CLASSROOM', 'ROOM ')}
        </p>
      </div>

      <div
        className={
          [USER_ROLES.TEACHERS, USER_ROLES.ADMINS].includes(userRole)
            ? styles.containerHeaderRight
            : styles.containerHeaderRightStudent
        }
      >
        {[USER_ROLES.TEACHERS, USER_ROLES.ADMINS].includes(userRole) && (
          <SyncIcon className={styles.syncIcon} />
        )}

        <LiveIcon style={{ color: 'blue' }} className={styles.liveIcon} />
      </div>
    </div>
  );
}
