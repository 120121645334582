import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import styles from './styles.module.scss';

export const ButtonSave = ({ onClick }) => {
  const { state } = useCurrentSlide();
  const textButton = state.loader.load ? state.loader.message : 'Save';
  const handleClickSave = () => {
    if (!state.loader.load) onClick();
  };
  return (
    <p className={styles.saveButton} onClick={handleClickSave}>
      {textButton}
    </p>
  );
};

export const ButtonEdit = () => {
  const { state, action } = useCurrentSlide();

  const handleClickEdit = () => {
    action.handleEditSlideMode();
    action.setSelectedSlideIndex(state.propsLevel.slideIndex);
  };

  return (
    <p onClick={handleClickEdit} className={styles.editModeButton}>
      Edit
    </p>
  );
};
