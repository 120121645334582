import { createContext, useCallback, useContext, useMemo } from 'react';
import { useCurrentSlide } from './useCurrentSlide';
import { elementEnum } from '../components/Slide/slide-types';
import { v4 as uuid } from 'uuid';
import { useConfigText } from './useConfigText';
import { sortOnlyShapesByZIndex } from '../components/Slide/slide-functions';

const ShapeMenuContext = createContext(null);

export const ShapeMenuProvider = ({ children }) => {
  const { state: stateCurrentSlide, action: actionCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText } = useConfigText();

  const handleMoveToFront = () => {
    actionCurrentSlide.setShapeMenu(drawMenu => ({
      ...drawMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));

    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const draftFind = currentDraft[stateCurrentSlide.selectedSlideIndex].shapes.find(
        el => el.id === stateCurrentSlide.selectIdShape
      );
      const arrDraft = currentDraft;
      const arrShapes = arrDraft[stateCurrentSlide.selectedSlideIndex].shapes;
      const cantElements = arrShapes.length;
      const arrChangedShapes = arrShapes.map(el => {
        const zIndex = draftFind.zIndex <= el.zIndex ? el.zIndex - 1 : el.zIndex;
        if (el.id === stateCurrentSlide.selectIdShape)
          return { ...el, zIndex: cantElements };
        else return { ...el, zIndex };
      });

      arrDraft[stateCurrentSlide.selectedSlideIndex] = {
        ...arrDraft[stateCurrentSlide.selectedSlideIndex],
        shapes: sortOnlyShapesByZIndex(arrChangedShapes)
      };

      actionCurrentSlide.handleSaveHistory(arrDraft);
      return [...arrDraft];
    });
  };

  const handleMoveOneFordward = () => {
    actionCurrentSlide.setShapeMenu(drawMenu => ({
      ...drawMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));

    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const draftFind = currentDraft[stateCurrentSlide.selectedSlideIndex].shapes.find(
        el => el.id === stateCurrentSlide.selectIdShape
      );
      const arrDraft = currentDraft;
      const arrShapes = arrDraft[stateCurrentSlide.selectedSlideIndex].shapes;
      const cantElements = arrShapes.length;
      const arrChangedShapes = arrShapes.map(el => {
        if (draftFind.zIndex + 1 === el.zIndex) return { ...el, zIndex: el.zIndex - 1 };
        else if (el.id === stateCurrentSlide.selectIdShape)
          return {
            ...el,
            zIndex: el.zIndex >= cantElements ? cantElements : el.zIndex + 1
          };
        else return { ...el };
      });

      arrDraft[stateCurrentSlide.selectedSlideIndex] = {
        ...arrDraft[stateCurrentSlide.selectedSlideIndex],
        shapes: sortOnlyShapesByZIndex(arrChangedShapes)
      };
      actionCurrentSlide.handleSaveHistory(arrDraft);
      return [...arrDraft];
    });
  };

  const handleMoveBack = () => {
    actionCurrentSlide.setShapeMenu(drawMenu => ({
      ...drawMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));

    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const arrDraft = currentDraft;
      const arrShapes = arrDraft[stateCurrentSlide.selectedSlideIndex].shapes;
      const arrChangedShapes = arrShapes.map(el => {
        const zIndex = el.zIndex + 1;

        if (el.id === stateCurrentSlide.selectIdShape) return { ...el, zIndex: 1 };
        else return { ...el, zIndex };
      });

      arrDraft[stateCurrentSlide.selectedSlideIndex] = {
        ...arrDraft[stateCurrentSlide.selectedSlideIndex],
        shapes: sortOnlyShapesByZIndex(arrChangedShapes)
      };

      actionCurrentSlide.handleSaveHistory(arrDraft);
      return [...arrDraft];
    });
  };

  const handleMoveOneBack = () => {
    actionCurrentSlide.setShapeMenu(drawMenu => ({
      ...drawMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));

    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const draftFind = currentDraft[stateCurrentSlide.selectedSlideIndex].shapes.find(
        el => el.id === stateCurrentSlide.selectIdShape
      );
      const arrDraft = currentDraft;
      const arrShapes = arrDraft[stateCurrentSlide.selectedSlideIndex].shapes;
      const arrChangedShapes = arrShapes.map(el => {
        if (draftFind.zIndex - 1 === el.zIndex) return { ...el, zIndex: el.zIndex + 1 };
        else if (el.id === stateCurrentSlide.selectIdShape)
          return { ...el, zIndex: el.zIndex - 1 };
        else return { ...el };
      });

      arrDraft[stateCurrentSlide.selectedSlideIndex] = {
        ...arrDraft[stateCurrentSlide.selectedSlideIndex],
        shapes: sortOnlyShapesByZIndex(arrChangedShapes)
      };

      actionCurrentSlide.handleSaveHistory(arrDraft);
      return [...arrDraft];
    });
  };

  const handleRemoveElement = useCallback(() => {
    actionConfigText.setEditTextMode(editTextMode => ({
      ...editTextMode,
      editTextMode: false,
      openTextEditing: false
    }));

    actionConfigText.setConfigText({
      fontStyle: '',
      fill: '#000',
      fontSize: 16,
      text: '',
      fontFamily: 'Arial',
      rotation: 0
    });

    actionCurrentSlide.setShapeMenu(drawMenu => ({
      ...drawMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));

    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const draftFind = currentDraft[stateCurrentSlide.selectedSlideIndex].shapes.find(
        el => el.id === stateCurrentSlide.selectIdShape
      );
      const arrDraft = currentDraft;
      const arrShapes = arrDraft[stateCurrentSlide.selectedSlideIndex].shapes.filter(
        el => el.id !== stateCurrentSlide.selectIdShape
      );
      const arrChangedShapes = arrShapes?.map(el => {
        if (draftFind?.zIndex < el.zIndex) return { ...el, zIndex: el.zIndex - 1 };
        else return { ...el };
      });

      arrDraft[stateCurrentSlide.selectedSlideIndex] = {
        ...arrDraft[stateCurrentSlide.selectedSlideIndex],
        shapes: sortOnlyShapesByZIndex(arrChangedShapes)
      };

      actionCurrentSlide.handleSaveHistory(arrDraft);
      // actionCurrentSlide.setSelectIdShape(null);

      // console.log(arrDraft, draftFind);
      return [...arrDraft];
    });
  }, [stateCurrentSlide.selectIdShape, stateCurrentSlide.currentDraft]);

  const handleCopyElement = () => {
    const draftFind = stateCurrentSlide.currentDraft[
      stateCurrentSlide.selectedSlideIndex
    ].shapes.find(el => el.id === stateCurrentSlide.selectIdShape);
    actionCurrentSlide.setCopiedElement(draftFind);
    actionCurrentSlide.setShapeMenu(drawMenu => ({
      ...drawMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));
  };

  const handlePasteElement = () => {
    const { x, y } = stateCurrentSlide.shapeMenu;
    actionCurrentSlide.setShapeMenu(drawMenu => ({
      ...drawMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));

    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const arrDraft = currentDraft;
      arrDraft[stateCurrentSlide.selectedSlideIndex] = {
        ...arrDraft[stateCurrentSlide.selectedSlideIndex],
        shapes: [
          ...arrDraft[stateCurrentSlide.selectedSlideIndex].shapes,
          {
            ...stateCurrentSlide.copiedElement,
            zIndex: arrDraft[stateCurrentSlide.selectedSlideIndex].shapes.length + 1,
            id: uuid(),
            x,
            y
          }
        ]
      };
      actionCurrentSlide.handleSaveHistory(arrDraft);
      return [...arrDraft];
    });
  };

  const value = useMemo(
    () => ({
      action: {
        handleMoveToFront,
        handleMoveOneFordward,
        handleMoveBack,
        handleMoveOneBack,
        handleRemoveElement,
        handleCopyElement,
        handlePasteElement
      }
    }),
    [
      stateCurrentSlide.currentDraft,
      stateCurrentSlide.selectIdShape,
      stateCurrentSlide.selectedSlideIndex,
      stateCurrentSlide.shapeMenu
    ]
  );

  return <ShapeMenuContext.Provider value={value} children={children} />;
};

export const useShapeMenu = () => {
  const context = useContext(ShapeMenuContext);
  return context;
};
