import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import React from 'react';

export default function BoldTool({ style, testBtn, stylesTextControls }) {
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { state: stateConfigText, action: actionConfigText } = useConfigText();
  const validateStyleIfExist = text => {
    if (text.split(' ')?.includes('bold')) return text.replace('bold', '').trim();
    else return `${text} bold`.trim();
  };

  const handleClickOption = () => {
    actionConfigText.handleEditConfigText(
      { fontStyle: validateStyleIfExist(stateConfigText.configText.fontStyle) },
      stateCurrentSlide.selectIdShape,
      true
    );
  };

  return (
    <React.Fragment>
      <div
        onClick={handleClickOption}
        className={`
            ${testBtn} 
            ${style} 
            ${stylesTextControls}
          `}
      >
        <p style={{ fontSize: 16 }}>B</p>
      </div>
    </React.Fragment>
  );
}
