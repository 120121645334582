import React, { useState, useEffect } from 'react';
import CrystalButton from 'components/common/CrystalButton';
import { useColorScheme } from '../hooks/useColorScheme';

import { ARROW_DIRECTIONS } from './constants';

function QuestionNavigator({
  getPrevExamSection,
  getPreviousQuestion,
  creatingQuestion,
  getNextQuestion,
  willCreateQuestion,
  creatingExamSection,
  getNextExamSection,
  willCreateSection,
  handleSave,
  currentExamQuestionEditingValues,
  currentExamQuestionNumber,
  totalQuestions,
  totalQuestionsInCurrentSection,
  currentExamSection,
  examSectionNumber,
  firstQuestionInSection,
  lastQuestionInSection,
  totalSections
}) {
  const [clickedButton, setClickedButton] = useState(null);
  const [activeArrows, setActiveArrows] = useState([
    ARROW_DIRECTIONS.RIGHT,
    ARROW_DIRECTIONS.RIGHT.UP
  ]);

  useEffect(() => {
    const storedArrows = localStorage.getItem('activeArrows');
    if (storedArrows) {
      setActiveArrows(JSON.parse(storedArrows));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('activeArrows', JSON.stringify(activeArrows));
  }, [activeArrows]);

  const handleArrowClick = buttonName => {
    setClickedButton(buttonName);
    setTimeout(() => {
      setClickedButton(null);
    }, 500);
  };

  const getStrokeWidth = arrowName => {
    if (activeArrows.includes(arrowName)) {
      return '2.5';
    }
    return '0.3';
  };

  const { CurrentColor } = useColorScheme();
  const arrowColorClass = CurrentColor === 'dark' ? 'arrow-blue' : 'arrow-blue';

  useEffect(() => {
    // Verificar si estamos creando una nueva sección
    if (creatingExamSection) {
      // Setear las flechas activas a left y right
      setActiveArrows([ARROW_DIRECTIONS.UP, ARROW_DIRECTIONS.RIGHT]);
    } else if (
      firstQuestionInSection && // Si es la primera pregunta de la sección (excepto la sección 1)
      examSectionNumber !== 0 // y no es la sección 1
    ) {
      // Activar left (para ir a la sección anterior)
      // Activar down (para ir a la siguiente pregunta dentro de la misma sección)
      const arrows = [ARROW_DIRECTIONS.LEFT, ARROW_DIRECTIONS.RIGHT];

      // Si hay más secciones después de la sección actual, activar right (para ir a la siguiente sección)
      if (examSectionNumber < totalSections - 1) {
        arrows.push(ARROW_DIRECTIONS.RIGHT);
      }

      setActiveArrows(arrows);
    } else if (
      currentExamQuestionNumber === 1 &&
      !creatingQuestion &&
      !creatingExamSection
    ) {
      setActiveArrows([ARROW_DIRECTIONS.DOWN, ARROW_DIRECTIONS.RIGHT]);
    } else if (
      currentExamQuestionNumber !== 1 &&
      currentExamQuestionNumber === totalQuestionsInCurrentSection &&
      totalQuestionsInCurrentSection > 1
    ) {
      // Si es la primera pregunta de la sección actual (que no es la pregunta 1)
      // y hay más de una pregunta en la sección actual
      setActiveArrows([ARROW_DIRECTIONS.DOWN, ARROW_DIRECTIONS.UP]);
    } else if (totalQuestionsInCurrentSection > 1) {
      setActiveArrows([ARROW_DIRECTIONS.RIGHT, ARROW_DIRECTIONS.LEFT]);
    } else if (totalQuestionsInCurrentSection > 1 && currentExamQuestionNumber - 1) {
      setActiveArrows([ARROW_DIRECTIONS.RIGHT, ARROW_DIRECTIONS.LEFT]);
    } else {
      // Si solo hay una pregunta en la sección actual, activar los botones right y left
      setActiveArrows([ARROW_DIRECTIONS.RIGHT, ARROW_DIRECTIONS.LEFT]);
    }
    if (currentExamQuestionNumber !== 1 && currentExamQuestionNumber === totalQuestions) {
      // Si no es la pregunta 1 y es la última pregunta, activar solo la flecha hacia arriba
      setActiveArrows([ARROW_DIRECTIONS.LEFT]);
    }
    if (
      currentExamQuestionNumber !== 1 &&
      currentExamQuestionNumber === totalQuestions &&
      totalQuestionsInCurrentSection > 1
    ) {
      setActiveArrows([ARROW_DIRECTIONS.UP]);
    }
  }, [
    currentExamQuestionNumber,
    creatingQuestion,
    creatingExamSection,
    totalQuestions,
    totalQuestionsInCurrentSection,
    firstQuestionInSection,
    examSectionNumber,
    totalSections
  ]);

  return (
    <div className="question-navigator" style={{ zIndex: '15' }}>
      <CrystalButton
        type="button"
        onClick={() => {
          getPrevExamSection();
          handleArrowClick('left');
        }}
        className="left"
        style={{ padding: 0 }}
      >
        <svg
          width="25"
          height="29"
          viewBox="0 0 28.5 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clickedButton === 'left' ? 'arrow-filled-left' : 'arrow'}
        >
          <path
            d="M0.297075 14.6386L12.275 0.973051V7.51993L12.275 7.74493H12.5H24.775L24.775 22.0051H12.5H12.275L12.275 22.2301V28.7618L0.297075 14.6386Z"
            stroke="#17E423"
            strokeWidth={getStrokeWidth('left')}
          />
        </svg>
      </CrystalButton>
      <div className="up-down">
        <CrystalButton
          type="button"
          onClick={() => {
            getPreviousQuestion();
            handleArrowClick('up');
          }}
          className="down"
          alt="prev subquestion"
          title="prev subquestion"
        >
          <svg
            width="25"
            height="29"
            viewBox="0 0 28.5 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clickedButton === 'up' ? 'arrow-filled-up' : 'arrow'}
          >
            <path
              d="M15.5489 0.484575L29.2144 12.4625H22.6676H22.4426V12.6875V24.9625H8.18243V12.6875V12.4625H7.95743H1.42566L15.5489 0.484575Z"
              stroke="#3765C2"
              strokeWidth={getStrokeWidth('up')}
            />
          </svg>
        </CrystalButton>
        <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
          {currentExamQuestionNumber}
        </div>

        <CrystalButton
          type="button"
          disabled={creatingQuestion}
          onClick={() => {
            handleSave();
            handleArrowClick('down');
            getNextQuestion();
          }}
          alt={willCreateQuestion ? 'Create question' : 'next question'}
          title={willCreateQuestion ? 'Create question' : 'next question'}
          style={{ padding: 0 }}
        >
          <svg
            width="25"
            height="29"
            viewBox="0 0 28.5 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clickedButton === 'down' ? 'arrow-filled-down' : 'arrow'}
          >
            <path
              d="M14.4511 24.8904L0.785551 12.9125L7.33243 12.9125H7.55743V12.6875V0.4125L21.8176 0.4125V12.6875V12.9125H22.0426H28.5743L14.4511 24.8904Z"
              stroke="#3866C5"
              strokeWidth={getStrokeWidth('down')}
            />
          </svg>
        </CrystalButton>
      </div>
      <CrystalButton
        type="button"
        onClick={() => {
          handleArrowClick('right');
          setTimeout(() => {
            handleSave();
            getNextExamSection(currentExamQuestionEditingValues);
          }, 100);
        }}
        className="right"
      >
        <svg
          width="25"
          height="29"
          viewBox="0 0 28.5 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clickedButton === 'right' ? 'arrow-filled-right' : 'arrow'}
        >
          <path
            d="M24.7029 14.7364L12.725 28.4019V21.8551V21.6301H12.5H0.225V7.36993H12.5H12.725V7.14493V0.613161L24.7029 14.7364Z"
            stroke="#17E423"
            strokeWidth={getStrokeWidth('right')}
          />
        </svg>
      </CrystalButton>
    </div>
  );
}

export default QuestionNavigator;
