import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { createContext, useState, useContext, useMemo, useCallback } from 'react';
import { elementEnum, shapeEnum } from '../slide-types';
import { returnShapeFinded, saveEnteredText } from '../slide-functions';

export const KonvaContext = createContext(null);

export const KonvaProvider = ({ children }) => {
  const { action: actionCurrentSlide, state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText, state: stateConfigText } = useConfigText();
  const [styleStage] = useState();

  // TextKonva - ImageKonva - ShapeKonva
  const handleShapeSelect = useCallback(
    (event, id) => {
      const clickedOnEmpty = event?.target === event?.target.getStage();
      actionCurrentSlide.setShapeMenu(shapeMenu => ({ ...shapeMenu, isOpen: false }));
      if (clickedOnEmpty) actionCurrentSlide.setSelectIdShape(null);
      else if (!id) return;
      else actionCurrentSlide.setSelectIdShape(id);
    },
    [actionCurrentSlide]
  );

  const handleShapeDragStar = () => {
    actionCurrentSlide.setShapeMenu(shapeMenu => ({ ...shapeMenu, isOpen: false }));
  };

  const handleShapeDragEnd = event => {
    const { id, ...attrs } = event.target.attrs;
    actionCurrentSlide.handleChangeShape(id, attrs, true);
  };

  const handleShapeTransformStart = () => {
    actionConfigText.setEditTextMode(editTextMode => ({
      ...editTextMode,
      openTextTool: false
    }));
  };

  const handleShapeTransform = (event, shapeRef, shapeProps) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);
    const props = {
      ...shapeProps,
      x: node.x(),
      y: node.y(),
      rotation: event.target.attrs.rotation,
      width: Math.max(node.width() * scaleX, 20),
      height: Math.max(node.height() * scaleY, 20)
    };

    actionCurrentSlide.handleChangeShape(shapeProps.id, props, false);
    actionCurrentSlide.setShapeMenu(shapeMenu => ({ ...shapeMenu, isOpen: false }));
  };

  const handleShapeTransformEnd = event => {
    const { id, ...attrs } = event.target.attrs;
    const { currentDraft, selectedSlideIndex } = stateCurrentSlide;
    const shapeFinded = returnShapeFinded(currentDraft[selectedSlideIndex].shapes, id);

    actionCurrentSlide.handleChangeShape(id, attrs, true);
    actionConfigText.setConfigText({ ...event.target.attrs });

    if (shapeFinded.shape === shapeEnum.text)
      actionConfigText.setEditTextMode(editTextMode => ({
        ...editTextMode,
        openTextEditing: true
      }));
  };

  const handleTextSelect = useCallback(
    (event, textProps) => {
      const updateTextDraft = () => {
        actionConfigText.setEditTextMode(editTextMode => ({
          ...editTextMode,
          openTextTool: true,
          openTextEditing: true,
          textareaPainted: false
        }));
        actionConfigText.setConfigText({ ...event.target.attrs });
        handleShapeSelect(event, textProps.id);
      };

      if (event.evt.button === 2) return;
      if (stateConfigText.editTextMode.textareaPainted) return;
      if (stateConfigText.editTextMode.openTextEditing) return updateTextDraft();
      // if (stateConfigText.editTextMode.openTextTool) updateTextDraft();
      if (stateCurrentSlide.shapeMenu.isOpen) return;
      else updateTextDraft();
    },
    [stateConfigText.selectAreaDimentions, stateConfigText.editTextMode]
  );

  const handleTextContextMenu = (event, textProps) => {
    const { attrs } = event.target;
    handleShapeSelect(event, attrs.id);
    actionCurrentSlide.handleOpenMenu(event, { element: elementEnum.SHAPE_MENU_ELEMENT });
  };

  const handleImageSelect = (event, imageProps) => {
    handleShapeSelect(event, imageProps.id);
  };

  const handleImageContextMenu = event => {
    actionCurrentSlide.handleOpenMenu(event, { element: elementEnum.SHAPE_MENU_ELEMENT });
  };

  // StageKonva
  const handleStageMouseDown = useCallback(
    event => {
      const saveTextAndClear = () => {
        actionCurrentSlide.setCurrentDraft(prevDraft => {
          return saveEnteredText(
            prevDraft,
            stateConfigText.selectAreaDimentions,
            stateCurrentSlide.selectedSlideIndex,
            stateConfigText.configText,
            stateCurrentSlide.whiteboardSize.normal.scale
          );
        });
        actionConfigText.setConfigText(configText => ({ ...configText, text: '' }));
        // actionCurrentSlide.handleSaveHistory();
      };

      const updateTextAndClear = () => {
        const updateSelectedText = (shapes, selectedId, config) =>
          shapes.map(txt => (txt.id === selectedId ? { ...txt, ...config } : txt));
        actionCurrentSlide.setCurrentDraft(currentDraft => {
          const arrTextEdit = currentDraft.map((el, index) =>
            stateCurrentSlide.selectedSlideIndex === index
              ? {
                  ...el,
                  shapes: updateSelectedText(
                    el.shapes,
                    stateCurrentSlide.selectIdShape,
                    stateConfigText.configText
                  )
                }
              : el
          );
          actionCurrentSlide.handleSaveHistory(arrTextEdit);
          return arrTextEdit;
        });
        actionConfigText.setConfigText(configText => ({ ...configText, text: '' }));
      };

      if (
        stateConfigText.configText.text &&
        stateConfigText.editTextMode.textareaPainted
      ) {
        saveTextAndClear();
      } else if (stateConfigText.configText.text) {
        updateTextAndClear();
      } else if (stateCurrentSlide.selectIdShape) {
        handleShapeSelect(event, null);
      } else {
        actionConfigText.setConfigText(configText => ({ ...configText, text: '' }));
      }

      actionConfigText.beginAndFinishSelection(event, 'begin', 'text');
      // handleShapeSelect(event, null);
    },
    [
      stateConfigText.configText,
      stateConfigText.selectAreaDimentions,
      stateCurrentSlide,
      actionConfigText,
      actionCurrentSlide,
      handleShapeSelect
    ]
  );

  const handleStageContextMenu = event => {
    const attrs = { element: elementEnum.SHAPE_MENU_CANVAS };
    actionCurrentSlide.handleOpenMenu(event, attrs);
  };

  const value = useMemo(
    () => ({
      state: {
        styleStage
      },
      action: {
        handleShapeSelect,
        handleShapeDragStar,
        handleShapeDragEnd,
        handleShapeTransformStart,
        handleShapeTransform,
        handleShapeTransformEnd,

        handleTextSelect,
        handleTextContextMenu,

        handleImageSelect,
        handleImageContextMenu,

        handleStageMouseDown,
        handleStageContextMenu
      }
    }),
    [handleStageMouseDown, handleTextSelect]
  );

  return <KonvaContext.Provider value={value} children={children} />;
};

export const useKonva = () => {
  const context = useContext(KonvaContext);
  if (!context) {
    throw new Error('review use of useCurrentSlide');
  }
  return context;
};
