import { gql } from '@apollo/client';

export const FETCH_NOTES_USER = gql`
  query MyQuery($createBy: String!) {
    listTextNotes(filter: { createBy: { eq: $createBy } }) {
      items {
        date
        for
        from
        id
        message
        role
        typing
        createBy
        deadline
        fontColor
        fontFamily
        fontSize
        type
      }
    }
  }
`;

export const SUBS_ON_CREATE_NOTES_USER = gql`
  subscription onCreateTextNote {
    onCreateTextNote {
      id
      createBy
    }
  }
`;

export const SUBS_ON_UPDATE_NOTES_USER = gql`
  subscription onUpdateTextNote {
    onUpdateTextNote {
      id
      createBy
    }
  }
`;

export const SUBS_ON_DELETE_NOTES_USER = gql`
  subscription onDeleteTextNote {
    onDeleteTextNote {
      id
      createBy
    }
  }
`;

export const FETCH_BACKGROUND_NOTE = gql`
  query getBackgroundNote($id: ID!) {
    getBackgroundNote(id: $id) {
      BackgroundColor
      id
    }
  }
`;

export const SUBS_ON_CHANGE_BACKGROUND_NOTA = gql`
  subscription onChangeBackgroundNote($id: ID!) {
    onChangeBackgroundNote(id: $id) {
      BackgroundColor
      id
    }
  }
`;
