import { ANIMATE_NOTES_WHITEBOARD } from 'store/actions/WhiteBoardActions';

export const useEventAnimateNotes = whiteBoardSocket => {
  const emitEventAnimateNotes = (emitData, onCallback) => {
    whiteBoardSocket.emit(ANIMATE_NOTES_WHITEBOARD, emitData);
    whiteBoardSocket.on(ANIMATE_NOTES_WHITEBOARD, onCallback);
  };

  const onEventAnimateNotes = callback => {
    whiteBoardSocket.on(
      ANIMATE_NOTES_WHITEBOARD,
      response => response && callback(response)
    );
  };

  const removeEventAnimateNotes = callback => {
    whiteBoardSocket.remove(ANIMATE_NOTES_WHITEBOARD);
  };

  return { emitEventAnimateNotes, onEventAnimateNotes, removeEventAnimateNotes };
};
