import { useState, useEffect } from 'react';

function useAudioPlayer(audioSrc) {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  console.log(playing);

  function stop() {
    setCurTime(0);
    setPlaying(false);
  }

  useEffect(() => {
    if (curTime === duration) {
      setCurTime(0);
      setPlaying(false);
    }
  }, [curTime]);

  useEffect(() => {
    const audio = document.getElementById(audioSrc);
    if (audio) {
      // state setters wrappers
      const setAudioData = () => {
        setDuration(audio.duration);
        setCurTime(audio.currentTime);
      };

      const setAudioTime = () => setCurTime(audio.currentTime);

      // DOM listeners: update React state on DOM events
      audio.addEventListener('loadeddata', setAudioData);

      audio.addEventListener('timeupdate', setAudioTime);

      // React state listeners: update DOM on React state changes
      playing ? audio.play() : audio.pause();

      if (clickedTime && clickedTime !== curTime) {
        audio.currentTime = clickedTime;
        setClickedTime(null);
      }

      if (curTime === 0) {
        audio.currentTime = 0;
      }

      // effect cleanup
      return () => {
        audio.removeEventListener('loadeddata', setAudioData);
        audio.removeEventListener('timeupdate', setAudioTime);
      };
    }
  }, [audioSrc, playing]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    stop
  };
}

export default useAudioPlayer;
