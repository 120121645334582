/** Cursor icons */
import { whiteBoardContext } from '../WhiteBoardProvider';
import { v1 as uuidv1 } from 'uuid';
import * as Actions from '../../../store/actions/WhiteBoardActions';
import { bindActionCreators } from 'redux';
import {
  notifyDrawEvent,
  noteMoving,
  noteUp
} from '../../../store/actions/WhiteBoardActions';
import { connect } from 'react-redux';
import { WhiteBoardRepository } from './WhiteBoardRepository';
import { ToolTemplate } from './ToolTemplate';
import { getStagePointerPosition } from './utils';

let currentId;
let to = {};
let payload = null;

class NoteTool extends ToolTemplate {
  static contextType = whiteBoardContext;

  onMouseDown = event => {};

  onMouseUp = event => {
    this.whiteBoardEvent = new WhiteBoardRepository(payload);
    to = getStagePointerPosition(event.currentTarget.getStage());
    const width = 150;
    const height = 150;
    payload = {
      classRoomId: this.props.classRoomId,
      page: this.props.page,
      id: uuidv1(),
      x: to.x,
      y: to.y,
      width,
      height,
      color: 'Black',
      fill: 'White',
      text: '',
      size: 0,
      opacity: this.context.opacity
    };

    this.props.noteUp(payload);
    this.props.notifyDrawEvent({ ...payload, eventType: Actions.NOTE_UP });
    window.sessionStorage.setItem('isNewWhiteboardModification', 'true');
    this.save(payload);
    return;
  };

  onMouseMove = event => {};

  render() {
    return null;
  }
}

// TODO move this to context, query classRoom and page with graphql
const mapStateToProps = state => {
  return {
    classRoomId: state.whiteBoard.classRoomId,
    page: state.whiteBoard.page
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ notifyDrawEvent, noteMoving, noteUp }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  NoteTool
);
