import React from 'react';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { ReactComponent as UndoBlack } from 'assets/whiteboard/black/undo-black.svg';
import lodash from 'lodash';

export default function UndoChangeTool({ style, stylesTestButton }) {
  const { state, action } = useCurrentSlide();

  const handleUndoChange = () => {
    const { actualPosition, history } = state.modificationHistory;
    if (actualPosition > 0) {
      action.setModificationHistory(modificationHistory => {
        const position = modificationHistory.actualPosition - 1;
        const { idSelectSlide, draft } = history[position];
        action.setCurrentDraft(lodash.cloneDeep(draft));
        action.setSelectedSlideIndex(idSelectSlide);
        return {
          ...modificationHistory,
          actualPosition: position
        };
      });
    }
  };

  return (
    <React.Fragment>
      <UndoBlack onClick={handleUndoChange} className={`${stylesTestButton} ${style}`} />
    </React.Fragment>
  );
}
