import { v4 as uuid } from 'uuid';
import { shapeEnum } from './slide-types';

export const calcDimensionsText = (firstPosition, lastPosition, prop) => {
  const { x: x1, y: y1 } = firstPosition;
  const { x: x2, y: y2 } = lastPosition;

  let newTextDimentions = {};

  if (x1 > x2 && y1 > y2)
    newTextDimentions = { x: x1 - x2, y: y1 - y2, [prop]: lastPosition };
  else if (x1 > x2 && y1 < y2)
    newTextDimentions = {
      x: x1 - x2,
      y: y2 - y1,
      [prop]: { x: firstPosition.x - (x1 - x2), y: lastPosition.y - (y2 - y1) }
    };
  else if (x1 < x2 && y1 > y2)
    newTextDimentions = {
      x: x2 - x1,
      y: y1 - y2,
      [prop]: { x: lastPosition.x - (x2 - x1), y: firstPosition.y - (y1 - y2) }
    };
  else newTextDimentions = { x: x2 - x1, y: y2 - y1, [prop]: firstPosition };
  return newTextDimentions;
};

export const saveEnteredText = (
  currentDraf,
  dimensions,
  idSelectSlide,
  configText,
  scale
) => {
  const updatedDraft = [...currentDraf];
  if (updatedDraft[idSelectSlide] && updatedDraft[idSelectSlide].shapes) {
    const txts = [...updatedDraft[idSelectSlide].shapes];
    const scaleText = 1 + (1 - scale);

    txts.push({
      id: uuid(),
      draggable: true,
      shape: shapeEnum.text,
      x: dimensions.firstPosition.x * scaleText,
      y: dimensions.firstPosition.y * scaleText,
      fontStyle: configText.fontStyle,
      fontSize: configText.fontSize * scaleText,
      width: dimensions.x * scaleText,
      height: dimensions.y * scaleText,
      fill: configText.fill,
      text: configText.text,
      fontFamily: configText.fontFamily,
      rotation: configText.rotation,
      lineHeight: 1,
      zIndex: txts.length + 1,
      align: 'left'
    });
    updatedDraft[idSelectSlide].shapes = txts;
  }
  return updatedDraft;
};

export const sortOnlyShapesByZIndex = arrShapes => {
  return [...arrShapes].sort((a, b) => a.zIndex - b.zIndex);
};

export const returnShapeFinded = (shapesDraf, selectIdShape) => {
  return shapesDraf.find(el => el.id === selectIdShape);
};

export const convertUrlToFile = async (url, fileName, mimeType) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: mimeType });
};

export const calculateScaleFullScreen = (width, height) => {
  const screenWidth = window.outerWidth;
  const screenHeight = window.outerHeight;

  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;

  const browserSpaceWidth = screenWidth - innerWidth;
  const browserSpaceHeight = screenHeight - innerHeight;

  const escalaPantallaCompleta = Math.min(
    (screenWidth - browserSpaceWidth) / width,
    (screenHeight - browserSpaceHeight) / height
  );

  return escalaPantallaCompleta;
};
