import React from 'react';

export default function IconBack() {
  return (
    <svg
      width="22.208px"
      height="6.538px"
      viewBox="0 0 22.208 6.538"
      enableBackground="new 0 0 22.208 6.538"
      style={{
        zoom: 2
      }}
    >
      <line
        fill="none"
        stroke={'blue'}
        strokeWidth="1.1962"
        strokeMiterlimit="10"
        x1="6.508"
        y1="3.294"
        x2="22.208"
        y2="3.294"
      />
      <polygon fill={'blue'} points="6.674,6.538 0.208,3.443 6.692,0 " />
    </svg>
  );
}
