import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { stylesTextEditing, stylesTextResize } from './textarea-functions';
import { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import ResizableRect from 'react-resizable-rotatable-draggable';
import './textarea-styles.scss';
import { styled } from '@material-ui/core';

export const TextareaPainted = () => {
  const { state } = useConfigText();

  return (
    <div
      style={{
        border: '0.5px solid #00000045',
        minWidth: '50px',
        minHeight: '50px',
        width: state.selectAreaDimentions.x - 8,
        height: state.selectAreaDimentions.y - 8,
        position: 'absolute',
        background: 'transparent',
        left: state.selectAreaDimentions.movePosition.x + 4,
        top: state.selectAreaDimentions.movePosition.y + 4,
        boxSizing: 'border-box'
      }}
    >
      {/* Box Empty */}
    </div>
  );
};

export const TextareaEditing = () => {
  const { state: stateConfigText, action: actionConfigText } = useConfigText();
  const { state: stateCurrentSlide, action: actionCurrentSlide } = useCurrentSlide();
  const styleTextarea = stylesTextEditing(
    stateConfigText.configText,
    stateCurrentSlide.whiteboardSize.normal.scale
  );
  const textRef = useRef(null);

  const rndConfig = {
    minWidth: 50,
    minHeight: 50,
    rotation: stateConfigText.configText.rotation,
    size: {
      width: stateConfigText.configText.width,
      height: stateConfigText.configText.height
    },
    position: {
      x: stateConfigText.configText.x * stateCurrentSlide.whiteboardSize.normal.scale,
      y: stateConfigText.configText.y * stateCurrentSlide.whiteboardSize.normal.scale
    }
  };

  const BoxEmpty = styled('div')(() => ({
    '& + div': {
      transform: `rotate(${stateConfigText.configText.rotation}deg) scale(${stateCurrentSlide.whiteboardSize.normal.scale}) !important`
    }
  }));

  const handleSaveChanges = () => {
    const updateSelectedText = (shapes, selectedId) =>
      shapes.map(shape => (shape.id === selectedId ? { ...shape } : shape));
    const arrTextEdit = stateCurrentSlide.currentDraft.map((el, index) =>
      stateCurrentSlide.selectedSlideIndex === index
        ? {
            ...el,
            shapes: updateSelectedText(el.shapes, stateCurrentSlide.selectIdShape)
          }
        : el
    );
    actionCurrentSlide.handleSaveHistory(arrTextEdit);
  };

  const listenerDragStop = () => {
    handleSaveChanges();
  };

  const listenerResizeStop = () => {
    handleSaveChanges();
  };

  const listenerDrag = (deltaX, deltaY) => {
    const atts = {
      x: stateConfigText.configText.x + deltaX,
      y: stateConfigText.configText.y + deltaY
    };

    actionConfigText.handleEditConfigText(atts, stateCurrentSlide.selectIdShape, false);
  };

  const listenerResize = style => {
    let { top, left, width, height } = style;
    const attrs = {
      width: Math.round(width),
      height: Math.round(height)
      // x: Math.round(left),
      // y: Math.round(top)
    };

    actionConfigText.setConfigText(selectAreaDimentions => ({
      ...selectAreaDimentions,
      ...attrs
    }));

    const updateSelectedText = (shapes, selectedId, config) =>
      shapes.map(txt => (txt.id === selectedId ? { ...txt, ...config } : txt));
    const arrTextEdit = stateCurrentSlide.currentDraft.map((el, index) =>
      stateCurrentSlide.selectedSlideIndex === index
        ? {
            ...el,
            shapes: updateSelectedText(el.shapes, stateCurrentSlide.selectIdShape, attrs)
          }
        : el
    );

    actionCurrentSlide.setCurrentDraft(arrTextEdit);
  };

  useEffect(() => {
    if (textRef?.current) {
      const inputValueLength = textRef.current.value.length;

      textRef.current.setSelectionRange(inputValueLength, inputValueLength);
    }
    return () => {};
  }, [textRef]);

  return (
    <>
      <BoxEmpty className="resizableEditing"></BoxEmpty>
      <ResizableRect
        left={rndConfig.position.x}
        top={rndConfig.position.y}
        width={rndConfig.size.width}
        height={rndConfig.size.height}
        minWidth={rndConfig.minWidth}
        minHeight={rndConfig.minHeight}
        rotateAngle={rndConfig.rotation}
        zoomable="n, w, s, e, nw, ne, se, sw"
        onResize={listenerResize}
        onResizeEnd={listenerResizeStop}
        onDrag={listenerDrag}
        onDragEnd={listenerDragStop}
        rotatable={false}
      />
      <div
        style={{
          width: rndConfig.size.width,
          height: rndConfig.size.height,
          background: 'transparent',
          position: 'absolute',
          transformOrigin: 'top left',
          transform: `translate(${rndConfig.position.x}px, ${rndConfig.position.y}px) rotate(${stateConfigText.configText.rotation}deg) scale(${stateCurrentSlide.whiteboardSize.normal.scale})`,
          top: 0,
          left: 0
        }}
      >
        <textarea
          autoFocus={true}
          ref={textRef}
          value={stateConfigText.configText.text}
          onMouseDown={e => e.stopPropagation()}
          onChange={actionConfigText.handleChangeTextEdit}
          className={styles.StyleTextareaEditing}
          style={styleTextarea}
        />
      </div>
    </>
  );
};

export const TextareaResize = () => {
  const { action, state } = useConfigText();
  const styleTextarea = stylesTextResize(state.configText);
  const textRef = useRef(null);

  const rndConfig = {
    minWidth: 50,
    minHeight: 50,
    rotation: state.selectAreaDimentions.rotation,
    size: {
      width: state.selectAreaDimentions.x,
      height: state.selectAreaDimentions.y
    },
    position: {
      x: state.selectAreaDimentions.firstPosition.x,
      y: state.selectAreaDimentions.firstPosition.y
    }
  };

  const listenerDrag = (deltaX, deltaY) => {
    action.setSelectAreaDimentions(selectAreaDimentions => ({
      ...selectAreaDimentions,
      firstPosition: {
        x: selectAreaDimentions.firstPosition.x + deltaX,
        y: selectAreaDimentions.firstPosition.y + deltaY
      }
    }));
  };

  const listenerResize = style => {
    let { top, left, width, height } = style;
    const attrs = {
      x: Math.round(width),
      y: Math.round(height),
      firstPosition: {
        x: Math.round(left),
        y: Math.round(top)
      }
    };

    action.setSelectAreaDimentions(selectAreaDimentions => ({
      ...selectAreaDimentions,
      ...attrs
    }));
  };

  const listenerRotate = rotateAngle => {
    action.setSelectAreaDimentions(selectAreaDimentions => ({
      ...selectAreaDimentions,
      rotation: rotateAngle
    }));
  };

  return (
    <>
      <div className="resizableResize"></div>
      <ResizableRect
        left={rndConfig.position.x}
        top={rndConfig.position.y}
        width={rndConfig.size.width}
        height={rndConfig.size.height}
        minWidth={rndConfig.minWidth}
        minHeight={rndConfig.minHeight}
        rotateAngle={rndConfig.rotation}
        zoomable="n, w, s, e, nw, ne, se, sw"
        onRotate={listenerRotate}
        onResize={listenerResize}
        onDrag={listenerDrag}
        rotatable={true}
      />
      <div
        onKeyDown={e => e.stopPropagation()}
        onKeyUp={e => e.stopPropagation()}
        style={{
          width: rndConfig.size.width,
          height: rndConfig.size.height,
          background: 'transparent',
          position: 'absolute',
          transform: `translate(${rndConfig.position.x}px, ${rndConfig.position.y}px) rotate(${rndConfig.rotation}deg)`,
          top: 0,
          left: 0
        }}
      >
        <textarea
          autoFocus={true}
          ref={textRef}
          value={state.configText.text}
          onMouseDown={e => e.stopPropagation()}
          onChange={action.handleChangeTextEdit}
          className={styles.StyleTextareaEditing}
          style={styleTextarea}
        />
      </div>
    </>
  );
};
