import React from 'react';
import styles from './styles.module.scss';

export default function DigitalBookTestTool({ style, testBtn }) {
  return (
    <React.Fragment>
      <div className={`${testBtn} ${style}`}>
        <p className={styles.text}>TEST</p>
        <input name="examPonderation" placeholder="000" maxLength={3} />
      </div>
    </React.Fragment>
  );
}
