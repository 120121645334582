import React, { useState } from 'react';
import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import styles from '../styles.module.scss';
import { orderByFirstLetter } from 'components/DigitalBook/funtions/utils';

const fontsFamily = [
  'Arial',
  'Kavivanar',
  'Anton',
  'Bangers',
  'Bevan',
  'Calligraffitti',
  'Carter One',
  'Chakra Petch',
  'Courgette',
  'Figtree',
  'Fredericka the Great',
  'Grape Nuts',
  'Indie Flower',
  'Josefin Sans',
  'Julius Sans One',
  'Kalam',
  'Marck Script',
  'Open Sans',
  'Parisienne',
  'Permanent Marker',
  'Play',
  'Racing Sans One',
  'Raleway',
  'Roboto',
  'Rubik Bubbles',
  'Secular One',
  'Tillana',
  'Digital-7',
  'BadScript',
  'Futurette',
  'larabiefont',
  'mvboli',
  'zektonit',
  'roundHand'
];

export default function FontTextTool({ style, testBtn, stylesTextControls }) {
  const [openMenu, setOpenMenu] = useState(false);
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText } = useConfigText();
  const handleChangeFontFamily = fontFamily => {
    actionConfigText.handleEditConfigText(
      { fontFamily },
      stateCurrentSlide.selectIdShape,
      true
    );
    setOpenMenu(false);
  };

  return (
    <React.Fragment>
      <div
        className={`${style} ${testBtn} ${stylesTextControls}`}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <p>FONT</p>
        {openMenu && (
          <div className={`${styles.buttonsMenu}`}>
            <ul>
              {orderByFirstLetter(fontsFamily).map(font => (
                <li onClick={() => handleChangeFontFamily(font)}>{font}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
