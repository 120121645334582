import React, { useEffect, useState } from 'react';
import './ModalSaveImage.scss';
import { useQuery } from '@apollo/client';
import { useUser } from '../../../UserManagment/UserProvider';
import { SAVE_BRANAK_ACTIONS } from 'enums/constants.enum';
import { IMG_UPLOAD_KEYS } from 'enums/constants.enum';

// MODULES - SERVICES
import html2canvas from 'html2canvas';
import { v4 as uuid } from 'uuid';
import { S3Service } from '../../../../services/S3.service';
import { useMutation } from '@apollo/client';
import { UPDATE_FILE } from '../../graphQL/mutations';
import { useCreateFile } from '../../hooks/useCreateFile';
import { FILES_BY_USERS } from '../../graphQL/queries';

// COMPONENTS
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from './Form';
import SaveBranakButton from './componentsModalSaveImage/index/SaveBranakButton';
import { useUserRole } from 'services/cognito.service';
import SaveBranakOptions from './componentsModalSaveImage/SaveBranakOptions';
import SaveGalleryOptions from './componentsModalSaveImage/SaveGalleryOptions';

const ModalSaveImg = ({ show, onHide, canvasImage, savedFile }) => {
  const { user } = useUser();
  const userRole = useUserRole();
  const [loadings, setLoadings] = useState([0, 0]);
  const [file, setFile] = useState(null);
  const [saveInBranakMode, setsaveInBranakMode] = useState(false);
  const { createFile } = useCreateFile();
  const { loading: loadingUserFiles, data: userFiles } = useQuery(FILES_BY_USERS, {
    variables: { userId: user.attributes.sub },
    fetchPolicy: 'network-only'
  });
  const [LoadingSaveInBranak, setLoadingSaveInBranak] = useState(false);
  const [scoreForm, setScoreForm] = useState(false);

  const s3Service = new S3Service('public', 'image/png');
  const [updateFile] = useMutation(UPDATE_FILE);

  const isLoading = loadings[0] || loadings[1];

  // verifica si un archivo específico ya existe en la galería de archivos del usuario
  const fileExistInUSerGallery = file => {
    return file && userFiles?.filesByUsers?.items.map(item => item.id).includes(file.id);
  };

  // Guarda la captura en la galeria de los usuarios
  const saveBranak = async (action, useStored) => {
    if (action !== SAVE_BRANAK_ACTIONS.TEST) setLoadingSaveInBranak(true);
    if (isLoading) return;

    const file = await capture();

    const commonActions = () => {
      setLoadingSaveInBranak(false);
      setsaveInBranakMode(null);
      window.sessionStorage.setItem(
        IMG_UPLOAD_KEYS.IS_NEW_WHITE_BOARD_MODIFICATION,
        'false'
      );
      savedFile();
      closeModal();
    };

    if (fileExistInUSerGallery(file) || useStored) {
      if (
        action === SAVE_BRANAK_ACTIONS.UPDATE ||
        action === SAVE_BRANAK_ACTIONS.REPLACE
      ) {
        await createUpdateImage(file, SAVE_BRANAK_ACTIONS.UPDATE, {
          specificId: useStored
            ? window.sessionStorage.getItem('storedUploadedImage')
            : null
        });
      }
    }

    if (action === SAVE_BRANAK_ACTIONS.TEST) {
      setLoadingSaveInBranak(false);
      setScoreForm(true);
    }

    if (
      action === SAVE_BRANAK_ACTIONS.NEW ||
      action === SAVE_BRANAK_ACTIONS.NEW_FROM_DEVICE
    ) {
      await createUpdateImage(file, SAVE_BRANAK_ACTIONS.CREATE, {
        saveInDefault: action === SAVE_BRANAK_ACTIONS.NEW,
        storageInCache: action === SAVE_BRANAK_ACTIONS.NEW_FROM_DEVICE
      });
    }

    commonActions();
  };

  useEffect(() => {
    if (show) {
      setScoreForm(false);
      setsaveInBranakMode(null);
    }
    if (
      show &&
      window.sessionStorage.getItem(IMG_UPLOAD_KEYS.IMG_UPLOADED_FROM) === 'device' &&
      window.sessionStorage.getItem(IMG_UPLOAD_KEYS.IS_NEW_WHITE_BOARD_MODIFICATION) !==
        'true'
    ) {
      const file = capture();
      if (file) {
        setsaveInBranakMode(file);
      }
    }
    if (
      show &&
      window.sessionStorage.getItem(IMG_UPLOAD_KEYS.IMG_UPLOADED_FROM) === 'device' &&
      window.sessionStorage.getItem(IMG_UPLOAD_KEYS.IS_NEW_WHITE_BOARD_MODIFICATION) ===
        'true'
    ) {
      const file = capture();
      if (file) {
        setsaveInBranakMode(file);
      }
    }
  }, [window.sessionStorage.getItem(IMG_UPLOAD_KEYS.CREATE_NEW_IMAGE_IN_BRANAK), show]);

  // crea, atualiza la imagen por la captura
  const createUpdateImage = async (
    file,
    mode,
    { saveInDefault, specificId, storageInCache }
  ) => {
    try {
      await s3Service.deleteFile(file.url);
      const { fileUrl } = await s3Service.uploadImage(file);

      const fileInput = {
        id: mode === 'update' ? (specificId ? specificId : file.id) : undefined,
        name: file.name,
        url: fileUrl,
        mode: 'file',
        currentFolder: saveInDefault
          ? 'default'
          : window.sessionStorage.getItem('currentFileLocation') || 'default',
        userId: mode === 'create' ? user.attributes.sub : undefined
      };

      const { data } =
        mode === 'create'
          ? await createFile({ variables: fileInput })
          : await updateFile({ variables: fileInput });

      if (storageInCache) {
        window.sessionStorage.setItem('storedUploadedImage', data?.createFile?.id);
      }

      if (!data?.updateFile?.id && mode === 'update') {
        throw new Error('Error updating file in DynamoDB');
      }

      setsaveInBranakMode(null);
    } catch (error) {
      if (error.message === 'Choose an image file') {
        console.error('ERROR-TYPE-FILE->', error);
        alert('The selected file must be an image');
      } else {
        console.error('ERROR-UPDATE-IMAGE ->', error);
        alert('Error updating file');
      }
    } finally {
      setLoadings([0, 0]);
    }
  };

  // Realiza la captura de la pizarra
  const capture = async () => {
    const capture = document.getElementById('capture');
    const canvasImageRef = JSON.parse(localStorage.getItem('canvasImageData'));

    try {
      if (!capture || !canvasImageRef) {
        throw new Error('Capture or canvasImageRef not found');
      }

      const canvas = await html2canvas(capture, {
        useCORS: true,
        allowTaint: true,
        scrollX: 0,
        scrollY: 0,
        height: capture.height,
        width: 800,
        scale: 1
      });

      const newFile = await new Promise((resolve, reject) => {
        canvas.toBlob(
          blob => {
            if (blob) {
              const image = canvasImage.file;
              const newFile = {
                id: image.id || '',
                userId: image.userId || '',
                name: `gallery/file_branak_${uuid()}.png`,
                url: image.url || '',
                blob
              };

              resolve(newFile);
            } else {
              reject(new Error('Error generating blob'));
            }
          },
          'image/png',
          1.0
        );
      });

      setFile(newFile);
      return newFile;
    } catch (error) {
      console.error('ERROR-CAPTURE-IMAGE ->', error);
      alert(`Error capturing whiteboard image: ${error.message}`);
      setLoadings([0, 0]);
    }
  };

  // Cierra las opciones de guardado
  const closeModal = () => {
    setLoadings([0, 0]);
    onHide();
  };

  return (
    <Modal
      className={window.classnames('modal-save', { 'modal-form': false })}
      show={show}
      onHide={isLoading ? () => null : onHide}
      backdrop={false ? 'static' : true}
      aria-label="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <React.Fragment>
          {scoreForm && (
            <Form
              file={file}
              closeModal={onHide}
              savedFile={savedFile}
              updateImage={false}
              setScoreForm={setScoreForm}
            />
          )}
          {loadingUserFiles ? (
            <Spinner className="bran-spinner" animation="border" />
          ) : (
            <div className="saving-image-branak-from-gallery" hidden="hidden">
              <div
                className="btn-container"
                style={{
                  flexDirection: window.innerWidth < 750 ? 'column-reverse' : 'row',
                  opacity: 0
                }}
              >
                <SaveBranakButton
                  capture={capture}
                  fileExistInUSerGallery={fileExistInUSerGallery}
                  setLoadings={setLoadings}
                  setsaveInBranakMode={setsaveInBranakMode}
                  saveBranak={saveBranak}
                  isLoading={isLoading}
                  loadings={loadings}
                  show={show}
                  saveInBranakMode={saveInBranakMode}
                />
              </div>
            </div>
          )}
        </React.Fragment>
        {LoadingSaveInBranak && (
          <div className="save-image-loading-view">
            <Spinner
              className="bran-spinner"
              animation="border"
              style={{ width: 50, height: 50 }}
            />
          </div>
        )}
        {!scoreForm &&
          (fileExistInUSerGallery(file) ? (
            <SaveGalleryOptions
              saveInBranakMode={saveInBranakMode}
              fileExistInUSerGallery={fileExistInUSerGallery}
              isLoading={isLoading}
              saveBranak={saveBranak}
              setScoreForm={setScoreForm}
              userRole={userRole}
              closeModal={closeModal}
            />
          ) : (
            <SaveBranakOptions
              saveInBranakMode={saveInBranakMode}
              fileExistInUSerGallery={fileExistInUSerGallery}
              isLoading={isLoading}
              saveBranak={saveBranak}
              setScoreForm={setScoreForm}
              userRole={userRole}
              closeModal={closeModal}
            />
          ))}
      </Modal.Body>
    </Modal>
  );
};

export default ModalSaveImg;
