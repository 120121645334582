import { Draggable } from 'react-beautiful-dnd';
import { Note } from './Note';

export const WhiteboardNotesStudents = props => (
  <>
    <div className="whiteboard-frame"> {/* Empty box */} </div>
    <div className="whiteboard-draggable"> {/* Empty box */} </div>
    <div className={props.classNames}>
      <Note {...props} />
    </div>
  </>
);

export const WhiteboardNotesTeacher = props => (
  <>
    <div className={props.classWhiteboardFrame}>
      <span>DRAG HERE</span>
    </div>

    <Draggable draggableId={'notesContainerHidden'} index={1}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className={props.classDraggable}
        ></div>
      )}
    </Draggable>

    <Draggable draggableId={'notesContainerVisible'} index={2}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className={props.classNames}
        >
          <Note {...props} />
        </div>
      )}
    </Draggable>
  </>
);

export const WhiteboardNotesAdmin = props => (
  <>
    <div className={props.classWhiteboardFrame}>
      <span>DRAG HERE</span>
    </div>

    <Draggable draggableId={'notesContainerHidden'} index={1}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className={props.classDraggable}
        ></div>
      )}
    </Draggable>

    <Draggable draggableId={'notesContainerVisible'} index={2}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className={props.classNames}
        >
          <Note {...props} />
        </div>
      )}
    </Draggable>
  </>
);
