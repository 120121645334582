import React from 'react';
import '../ChatWhiteboard.css';
//const Date = require("simple-datetime-formater")

import moment from 'moment';
import ChatWBAvatar from '../avatar/ChatWBAvatar';

const Message = ({
  name,
  message,
  data,
  setChangedata,
  avatar,
  avatarStatus,
  textColor,
  isUser,
  ownerMode,
  bellNotification
}) => {
  const msText = textColor === 'alternado' ? 'changeColorToViolet' : 'changeColorTowhite';
  const msTextA = textColor === 'alternado' ? 'changeColorToBlue' : 'changeColorTowhite';

  return (
    <div className="message">
      <ChatWBAvatar
        avatar={avatar}
        setChangedata={setChangedata}
        isUser={isUser}
        avatarStatus={avatarStatus}
        ownerMode={ownerMode}
        bellNotification={bellNotification}
      />

      <div className={isUser ? msText : msTextA}>
        <h5 className={isUser ? msText : msTextA}>{name}</h5>
        <p className={isUser ? msText : msTextA} style={{ wordWrap: 'break-word' }}>
          {message}
          <span className={isUser ? `${msText} timeD` : `${msTextA} timeD`}>
            {moment(data).format('hh:mm:A')}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Message;
