import React from 'react';
import styles from './styles.module.scss';
import { ReactComponent as BackIcon } from 'assets/learningContent/iconsBlue/back.svg';
import { ReactComponent as LiveIcon } from 'assets/whiteboard/live-blue.svg';
import { useUser } from 'components/UserManagment/UserProvider';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import EditSlideToolbar from './editSlideToolbar/EditSlideToolbar';

export default function HeaderEditMode() {
  const { action, state } = useCurrentSlide();
  const { user } = useUser();

  const handlBackEditMode = () => {
    action.handleEditSlideMode();
    action.setPropsLevel(propsLevel => ({
      ...propsLevel,
      numeration: 0,
      viewSlide: false,
      slideIndex: 0
    }));
  };

  return (
    <div className={styles.containerHeaderEditMode}>
      <div className={styles.headerLeft}>
        <BackIcon className={styles.backIcon} onClick={handlBackEditMode} />
        <div className={styles.editToolsAndData}>
          <p className={styles.numberPage}>
            P{state.propsLevel.numeration + state.selectedSlideIndex + 1}
          </p>
          <EditSlideToolbar />
          <p className={styles.user}>{user?.attributes.name}</p>
        </div>
      </div>
      <LiveIcon className={styles.liveIcon} />
    </div>
  );
}
