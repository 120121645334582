import React from 'react';

const Alert = ({ show, message, onClose }) => {
  if (!show) return null;

  return (
    <div className="alert alert-danger" role="alert">
      {message}
      <button type="button" className="close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default Alert;
