import React from 'react';
import { ReactComponent as AddSlice } from 'assets/learningContent/iconsBlue/addSlide.svg';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { v4 as uuid } from 'uuid';

export default function AddSlideTool({ style }) {
  const { state, action } = useCurrentSlide();

  const handleCreateSlide = () => {
    if (state.selectedSlideIndex !== null) {
      action.setCurrentDraft(currentDraft => {
        const orderDraft = currentDraft[state.selectedSlideIndex].order;
        const updatedSlides = currentDraft.map((el, index) => {
          if (index > state.selectedSlideIndex) return { ...el, order: el.order + 1 };
          else return { ...el };
        });

        updatedSlides.splice(state.selectedSlideIndex + 1, 0, {
          shapes: [],
          order: orderDraft + 1,
          id: uuid()
        });

        action.handleSaveHistory(updatedSlides);
        return updatedSlides;
      });
    }
  };

  return (
    <React.Fragment>
      <AddSlice onClick={handleCreateSlide} className={style} />
    </React.Fragment>
  );
}
