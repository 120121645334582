import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../common/LoadingSpinner';
import CrystalButton from '../../common/CrystalButton';
import TrashIcon from './assets/img/trash-icon.svg';
import ArchiveIcon from './assets/img/archive-icon.svg';
import { useEditQuestion } from './hooks/useEditQuestion';
import { useLoadExam } from '../hooks/useLoadExam';
import { Link } from 'react-router-dom';
import EditTest from './EditTest';
import { ErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';
import './styles.scss';
import { ReactComponent as PlayIcon } from '../../common/AudioPlayer/assets/play.svg';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  questionValuesVar,
  examSectionNumberVar,
  examQuestionNumberVar,
  examQuestionEditingValuesVar,
  feedbackEditingValuesVar
} from '../typePolicies';

import { useCreateExamSection } from './hooks/useCreateExamSection';
import { useDeleteExamSection } from './hooks/useDeleteExamSection';
import { useUpdateExamSection } from './hooks/useUpdateExamSection';
import { useUpdateExam } from './hooks/useUpdateExam';
import { useColorScheme } from '../hooks/useColorScheme';
import { EditableCheckBox } from '../CheckBox';

import { useParams } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import ModalSaveExam from '../Modals/ModalSaveExam';
import { useDuplicateExam } from './hooks/useDuplicateExam';

import QuestionNavigator from './QuestionNavigator';
import FeedbackEditor from './feedback';
import ViewListFilesExam from './examEditoComponents/ViewListFilesExam';
import generatorIconGreen from './examEditoComponents/img/generatoricongreen.svg';
import generatorIconBlue from './examEditoComponents/img/generatoriconblue.svg';
import pageCleaner from './examEditoComponents/img/pagecleaner.svg';
import { window } from 'd3';
import { UPDATE_EXAM_QUESTION } from './graphQL/mutations';
import { IoIosArrowRoundBack } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import { CiSearch } from 'react-icons/ci';
import Alert from './Alert';

export default function ExamEditor() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [firstQuestionInSection, setFirstQuestionInSection] = useState(null);
  const [lastQuestionInSection, setLastQuestionInSection] = useState(null);

  const { examId } = useParams();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const {
    examData: {
      currentExamSection,
      currentExamQuestion,
      totalSections,
      totalQuestionsInCurrentSection,
      totalQuestions,
      currentExamQuestionRelativeNumber,
      examColorScheme
    },
    loadingExam
  } = useLoadExam(examId);
  const dataExamId = useLoadExam(examId);

  const { updateExam, updatingExam } = useUpdateExam();
  const examQuestionNumber = useReactiveVar(examQuestionNumberVar);
  const examSectionNumber = useReactiveVar(examSectionNumberVar);
  const currentExamQuestionEditingValues = useReactiveVar(examQuestionEditingValuesVar);
  const { CurrentColor, setCurrentColor } = useColorScheme();
  const [ExamColorScheme, setExamColorScheme] = useState('standard');

  const {
    createQuestionRequest,
    deleteQuestionRequest,
    deletingQuestion,
    updateQuestionRequest,
    creatingQuestion,
    updatingQuestion
  } = useEditQuestion(examId);

  const { createExamSectionRequest, creatingExamSection } = useCreateExamSection();
  const { deleteExamSectionRequest, deletingExamSection } = useDeleteExamSection();
  const { updateExamSectionRequest, updatingExamSection } = useUpdateExamSection();
  const { receiveData } = useDuplicateExam(examId);
  const [SavingInGallery, setSavingInGallery] = useState(false);
  const [CurrentView] = useState(0);
  const [saveExamModal, setsaveExamModal] = useState(false);
  const [viewFileExams, setviewFileExams] = useState(false);
  const [updateExamQuestion] = useMutation(UPDATE_EXAM_QUESTION);

  const isLoading =
    loadingExam ||
    creatingQuestion ||
    creatingExamSection ||
    deletingExamSection ||
    updatingExamSection ||
    updatingExam ||
    SavingInGallery;
  const isDeleting = deletingQuestion;
  const willCreateSection = examSectionNumber + 1 === totalSections;
  const willCreateQuestion = examQuestionNumber + 1 === totalQuestionsInCurrentSection;

  const defaultCommand = 'ELIGE LA RESPUESTA CORRECTA PARA LAS SIGUIENTES PREGUNTAS'; // Asigna el valor predeterminado para `command`

  /** Initialize editing data*/
  useEffect(() => {
    if (!loadingExam) {
      const editingValues = examQuestionEditingValuesVar();
      examQuestionEditingValuesVar({
        command: defaultCommand, // Usa el valor predeterminado si currentExamSection.command está vacío
        ...currentExamQuestion,
        ...editingValues,
        id: currentExamQuestion.id,
        examSectionId: currentExamSection.id,
        image: currentExamQuestion.image,
        audio: currentExamQuestion.audio
      });
    }
  }, [
    currentExamQuestion,
    currentExamSection.command,
    currentExamSection.id,
    loadingExam
  ]);

  const handleExamInGallery = async e => {
    setsaveExamModal(true);
  };

  async function createExamSection() {
    const newSectionId = await createExamSectionRequest(examId);
    if (newSectionId) {
      // Verificar si currentExamQuestionEditingValues está vacío
      const currentValues = currentExamQuestionEditingValues || {};

      // Verificar si el campo command está vacío
      const command = currentValues.command || '';
      if (!command) {
        // Mostrar alerta si el campo command está vacío
        setShowAlert(true);
        setAlertMessage('El command no puede estar vacío');
        return; // Salir de la función para evitar el error
      }

      // Actualiza el valor de command en currentExamQuestionEditingValues
      examQuestionEditingValuesVar({
        ...currentValues,
        command, // Usa el valor verificado aquí
        id: null, // Asegúrate de limpiar el ID de la pregunta
        examSectionId: newSectionId // Asigna el nuevo ID de la sección
        // Limpia otros campos relacionados a la pregunta, si es necesario
      });
    }
  }

  async function createQuestion() {
    const newQuestionId = uuidv4();
    if (!creatingQuestion) {
      const command = currentExamQuestionEditingValues.command || '';
      if (!command) {
        setShowAlert(true);
        setAlertMessage('El command no puede estar vacío');
        return;
      }

      createQuestionRequest(
        {
          examSectionId: currentExamSection.id,
          id: newQuestionId,
          command: currentExamQuestionEditingValues.command // Usa el valor verificado aquí
          // Agrega otros campos necesarios, si los hay
        },
        examId
      );

      // Actualiza currentExamQuestionEditingValues con el valor verificado de command
      examQuestionEditingValuesVar({
        ...currentExamQuestionEditingValues,
        id: newQuestionId,
        examSectionId: currentExamSection.id
        // Agrega otros campos necesarios, si los hay
      });
    }
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMessage('');
  };
  useEffect(() => {
    setExamColorScheme(examColorScheme);
  }, [examColorScheme]);

  function clearEditingValues() {
    clearQuestions({});
  }

  async function clearQuestions() {
    examQuestionEditingValuesVar({
      command: currentExamQuestionEditingValues.command || defaultCommand
    });
  }
  //limpia la imagen cuando se esta creando
  async function clearImage(e) {
    // Prevenir el comportamiento predeterminado del botón (como recargar la página)
    e.preventDefault();

    const currentValues = examQuestionEditingValuesVar();

    const updatedValues = {
      ...currentValues,
      image: null
    };

    examQuestionEditingValuesVar(updatedValues);

    try {
      await updateQuestionRequest(updatedValues);
      console.log('Image cleared successfully');
    } catch (error) {
      console.error('Error clearing image:', error);
    }
  }

  async function deleteQuestion() {
    deleteQuestionRequest(currentExamQuestion);
    getPreviousQuestion();
    clearEditingValues();
  }

  async function deleteExamSection() {
    deleteExamSectionRequest(currentExamSection.id, examId);
    getPrevExamSection();
    clearEditingValues();
  }

  const getPreviousQuestion = () => {
    if (examQuestionNumber > 0) {
      clearEditingValues();
      examQuestionNumberVar(examQuestionNumber - 1);
    }
  };

  const getNextQuestion = () => {
    clearEditingValues();
    if (willCreateQuestion) {
      createQuestion();
    }
    examQuestionNumberVar(examQuestionNumber + 1);
  };

  const getNextExamSection = async () => {
    clearEditingValues();
    if (willCreateSection) {
      // Verifica si el campo command está vacío
      const command = currentExamQuestionEditingValues.command || defaultCommand;
      if (!command) {
        setShowAlert(true);
        setAlertMessage('El command no puede estar vacío');
        return; // Salir de la función para evitar crear una sección sin command
      }

      // Crea la nueva sección de examen con el command asignado
      await createExamSectionRequest(examId, false, command);
    }
    examQuestionNumberVar(0);
    examSectionNumberVar(examSectionNumber + 1);
  };

  const getPrevExamSection = () => {
    if (examSectionNumber > 0) {
      clearEditingValues();
      const prevSectionNumber = examSectionNumber - 1;
      examSectionNumberVar(prevSectionNumber);

      // Obtener las preguntas de la sección anterior
      const prevSectionQuestions =
        dataExamId?.examData?.examSections.items[prevSectionNumber]?.questions.items;

      // Si hay preguntas en la sección anterior, establecer el número de pregunta en la última
      if (prevSectionQuestions && prevSectionQuestions.length > 0) {
        const lastQuestionIndex = prevSectionQuestions.length - 1;
        const lastQuestionId = prevSectionQuestions[lastQuestionIndex].id;
        const lastQuestionData = prevSectionQuestions.find(q => q.id === lastQuestionId);

        // Actualizar los valores de edición con los datos de la última pregunta
        examQuestionEditingValuesVar({
          ...lastQuestionData,
          command: dataExamId?.examData?.examSections.items[prevSectionNumber].command
        });

        // Establecer el número de pregunta en el índice de la última pregunta
        examQuestionNumberVar(lastQuestionIndex);
      } else {
        // Si no hay preguntas en la sección anterior, establecer el número de pregunta en 0
        examQuestionNumberVar(0);
      }
    }
  };

  const handleSaveAndOpenExam = async () => {
    try {
      // Guardar los cambios
      updateExamSectionRequest({
        ...currentExamSection,
        command: currentExamQuestionEditingValues.command
      });
      updateQuestionRequest(currentExamQuestionEditingValues);
      updateFeedback(feedbackEditingValuesVar());
    } catch (error) {
      console.error('Error al guardar y abrir el examen:', error);
    }
  };

  const RenderExamFooter = ({ style, className }) => {
    return (
      <div
        className={
          className ? 'current-page-question' + ' ' + className : 'current-page-question'
        }
        style={style}
      >
        <span>
          Page:{' '}
          <span
            className={
              examSectionNumber + 1 === 0 ? 'alternated-text' : 'alternated-text-filled'
            }
          >
            {examSectionNumber + 1}/{totalSections}
          </span>
        </span>
        <span className="ml-2">
          Question:{' '}
          <span
            className={
              examSectionNumber + 1 === 0 ? 'alternated-text' : 'alternated-text-filled'
            }
          >
            {currentExamQuestionRelativeNumber + 1}/{totalQuestions}
          </span>
        </span>
      </div>
    );
  };

  async function handleSave() {
    // save section data
    updateExamSectionRequest({
      ...currentExamSection,
      command: currentExamQuestionEditingValues.command
    });
    // save question data
    updateQuestionRequest(currentExamQuestionEditingValues);
    // save feedback data
    updateFeedback(feedbackEditingValuesVar());
  }

  const duplicateExam = name =>
    new Promise(async (response, reject) => {
      await receiveData(feedbackEditingValuesVar(), ExamColorScheme, name)
        .then(examId => {
          response(examId);
        })
        .catch(err => {
          reject(err);
          console.error('There-was-an-error->', err);
        });
    });

  function updateFeedback(values) {
    const examFeedback = {
      id: examId,
      minScore: values.score.min,
      maxSCore: values.score.max,
      scoreFeedback: values.score.feedback,
      correctAnswers: values.correct.correctList,
      correctAnswersFeedback: values.correct.feedback,
      inCorrectAnswers: values.incorrect.incorrectList,
      inCorrectAnswersFeedback: values.incorrect.feedback,
      ranks: JSON.stringify(values.ranks),
      selectedEvaluations: [...values.selectedEvaluations],
      examColorScheme: ExamColorScheme
    };
    updateExam(examFeedback);
  }

  async function clearAllQuestions() {
    try {
      const allExamSections = dataExamId?.examData?.examSections.items;

      // Identificar la primera sección y la primera pregunta
      const firstSectionId = allExamSections[0]?.id;
      const firstQuestionId = allExamSections[0]?.questions.items[0]?.id;

      if (!firstSectionId || !firstQuestionId) {
        throw new Error('No se encontraron secciones o preguntas en el examen.');
      }

      // Cambiar a la primera sección y primera pregunta
      examSectionNumberVar(0);
      examQuestionNumberVar(0);

      // Dar tiempo para que la interfaz se actualice
      await new Promise(resolve => setTimeout(resolve, 100));

      // Iterar sobre todas las secciones
      for (const examSection of allExamSections) {
        const questionsData = examSection.questions.items;

        // Limpiar o eliminar preguntas en cada sección
        for (const question of questionsData) {
          if (examSection.id === firstSectionId && question.id === firstQuestionId) {
            // Limpiar la primera pregunta pero no eliminarla
            const defaultCommand = currentExamSection.command || 'default command'; // Asigna un valor por defecto a `command`

            await updateExamQuestion({
              variables: {
                id: question.id,
                examSectionId: question.examSectionId,
                audio: null,
                correctOptions: [],
                image: null,
                needsRecording: null,
                optionA: '',
                optionB: '',
                optionC: '',
                optionD: '',
                statement: '',
                command: defaultCommand // Asegúrate de pasar un valor por defecto para `command`
              }
            });
          } else if (examSection.id === firstSectionId) {
            // Eliminar otras preguntas en la primera sección
            await deleteQuestionRequest(question);
          } else {
            // Eliminar secciones que no sean la primera
            await deleteExamSectionRequest(examSection.id, examId);
          }
        }
      }

      // Limpiar las preguntas en el estado local
      clearEditingValues();
    } catch (error) {
      console.error('Error al limpiar las preguntas y eliminar secciones:', error);
    }
  }

  return (
    <div className="exam-editor-container">
      <Alert show={showAlert} message={alertMessage} onClose={handleCloseAlert} />
      <div
        className={
          CurrentColor === 'dark'
            ? 'english-test-admin-dashboard'
            : 'english-test-admin-dashboard__light'
        }
      >
        <div className="content p-3" style={{ height: '100vh' }}>
          <div className="button_clear_image">
            <button onClick={clearImage} className="btn_clear">
              Clear Image
            </button>
          </div>
          <form id="exam-editor-form" className="create-question-form text-center">
            <div className="back">
              <IoIosArrowRoundBack size={30} type="button" onClick={handleGoBack}>
                Go Back
              </IoIosArrowRoundBack>
            </div>

            {!isLoading && (
              <div
                className="test-styling-buttons responsive-header"
                style={{ marginTop: 0, borderTop: 'transparent' }}
              >
                <div className="buttons">
                  <label className="exam-header-title" htmlFor="command">
                    Ralph Hodge Test Generator
                  </label>
                  <div
                    className="push-button"
                    onClick={() =>
                      setCurrentColor(CurrentColor === 'dark' ? 'light' : 'dark')
                    }
                    style={{
                      padding: '3.5px 5px',
                      fontSize: '1rem',
                      borderRight: '0 solid transparent'
                    }}
                  >
                    Background color
                    <PlayIcon
                      style={{ marginLeft: '10px', width: '10px', height: '10px' }}
                      aria-label="play audio"
                    />
                  </div>
                </div>
              </div>
            )}

            {isLoading
              ? null
              : !loadingExam && (
                  <EditTest
                    examId={examId}
                    CurrentView={CurrentView}
                    currentExamQuestionEditingValues={currentExamQuestionEditingValues}
                    currentExamQuestionRelativeNumber={currentExamQuestionRelativeNumber}
                  />
                )}

            <div className="button-clear">
              <button onClick={clearImage} className="btn_clear">
                Clear Image
              </button>
            </div>
            <div className={'responsive__section-2'} style={{ paddingTop: '2em' }}>
              <FeedbackEditor examId={examId} />
            </div>
            {!isLoading && (
              <div className="test-styling-buttons">
                <div
                  className="buttons"
                  style={{
                    flexDirection: 'column',
                    gap: '2em',
                    justifyContent: 'center',
                    alignItems: 'start'
                  }}
                >
                  <div className="push-button__no-feedback">
                    <p
                      style={{
                        width: '135px'
                      }}
                    >
                      Exam color
                    </p>
                    <div className="push-button__no-feedback__selectColors">
                      <EditableCheckBox
                        labelText={'standard'}
                        small
                        isChecked={ExamColorScheme === 'standard'}
                        value="standard"
                        onCheckBoxChange={e => setExamColorScheme(e.target.value)}
                        inputName="score"
                        examColorMode
                        style={{ fontSize: '1rem' }}
                      />
                      <EditableCheckBox
                        labelText={'yellow'}
                        small
                        isChecked={ExamColorScheme === 'yellow'}
                        value="yellow"
                        onCheckBoxChange={e => setExamColorScheme(e.target.value)}
                        inputName="score"
                        examColorMode
                        style={{ fontSize: '1rem' }}
                      />
                      <EditableCheckBox
                        labelText={'green'}
                        small
                        isChecked={ExamColorScheme === 'green'}
                        value="green"
                        onCheckBoxChange={e => setExamColorScheme(e.target.value)}
                        inputName="score"
                        style={{ fontSize: '1rem' }}
                        examColorMode
                      />
                      <EditableCheckBox
                        labelText={'blue'}
                        small
                        isChecked={ExamColorScheme === 'blue'}
                        value="blue"
                        onCheckBoxChange={e => setExamColorScheme(e.target.value)}
                        inputName="score"
                        examColorMode
                        style={{ fontSize: '1rem' }}
                      />
                    </div>
                  </div>

                  <div
                    className="push-button"
                    onClick={() =>
                      setCurrentColor(CurrentColor === 'dark' ? 'light' : 'dark')
                    }
                    style={{
                      paddingBottom: '1em',
                      fontSize: '1rem'
                    }}
                  >
                    Background color
                    <PlayIcon
                      style={{ marginLeft: '10px', width: '10px', height: '10px' }}
                      aria-label="play audio"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="exam-editor-container__title">
              <img src={generatorIconGreen} alt="" />
              <h1>Ralph Hodge Test Generator</h1>
            </div>

            {!isLoading && (
              <div className="test-editor-footer">
                <div className="buttons-navegation-d">
                  <QuestionNavigator
                    getPrevExamSection={getPrevExamSection}
                    getPreviousQuestion={getPreviousQuestion}
                    creatingQuestion={creatingQuestion}
                    getNextQuestion={getNextQuestion}
                    willCreateQuestion={willCreateQuestion}
                    creatingExamSection={creatingExamSection}
                    getNextExamSection={getNextExamSection}
                    willCreateSection={willCreateSection}
                    handleSave={handleSave}
                    currentExamQuestionEditingValues={currentExamQuestionEditingValues}
                    currentExamQuestionNumber={currentExamQuestionRelativeNumber + 1}
                    currentExamSectionNumber={currentExamSection + 1}
                    totalQuestions={totalQuestions}
                    totalQuestionsInCurrentSection={totalQuestionsInCurrentSection}
                    examSectionNumber={examSectionNumber}
                  />
                </div>
                <div className="test-editor-buttons">
                  {!isLoading && (
                    <RenderExamFooter className="responsive-question-footer" />
                  )}
                  <div className="edit-options-container">
                    <div className="edit-options">
                      <CiSearch
                        size={35}
                        style={{ color: '#3D7AF6' }}
                        onClick={() => setviewFileExams(!viewFileExams)}
                      >
                        {deletingQuestion ? <LoadingSpinner /> : null}
                      </CiSearch>
                      <CrystalButton
                        type="button"
                        onClick={clearQuestions}
                        disabled={isDeleting}
                      >
                        {deletingQuestion ? null : (
                          <img
                            src={pageCleaner}
                            style={{ width: 35 }}
                            alt="Delete question Icon"
                          />
                        )}
                      </CrystalButton>
                      <CrystalButton
                        type="button"
                        onClick={clearAllQuestions}
                        disabled={isDeleting}
                      >
                        {deletingQuestion ? (
                          <LoadingSpinner />
                        ) : (
                          <img
                            src={TrashIcon}
                            style={{ width: 27 }}
                            alt="Delete question Icon"
                          />
                        )}
                      </CrystalButton>

                      <CrystalButton
                        type="button"
                        onClick={() => {
                          handleSave();
                        }}
                        className="save-btn ml-5"
                      >
                        Save
                      </CrystalButton>

                      <CrystalButton
                        type="button"
                        onClick={() => {
                          handleSave();
                          handleExamInGallery();
                        }}
                      >
                        {deletingQuestion ? (
                          <LoadingSpinner />
                        ) : (
                          <img
                            src={ArchiveIcon}
                            style={{ width: 27 }}
                            alt="archive exam Icon"
                          />
                        )}
                      </CrystalButton>

                      <CrystalButton
                        type="button"
                        customStyles={{ fontSize: window.innerWidth ? '1.3rem' : '1rem' }}
                        onClick={() => {
                          handleSaveAndOpenExam();
                        }}
                      >
                        <Link
                          to={`/take-exam/${examId}`}
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                          rel="noopener noreferrer"
                          className="save-btn__link"
                        >
                          <img
                            src={generatorIconBlue}
                            style={{ width: 30 }}
                            alt="Delete question Icon"
                          />
                        </Link>
                      </CrystalButton>
                    </div>
                    <RenderExamFooter />
                  </div>
                  <ViewListFilesExam
                    viewFileExams={viewFileExams}
                    setviewFileExams={setviewFileExams}
                  />

                  <div className="buttons-navegation-m">
                    <QuestionNavigator
                      getPrevExamSection={getPrevExamSection}
                      getPreviousQuestion={getPreviousQuestion}
                      creatingQuestion={creatingQuestion}
                      getNextQuestion={getNextQuestion}
                      willCreateQuestion={willCreateQuestion}
                      creatingExamSection={creatingExamSection}
                      getNextExamSection={getNextExamSection}
                      willCreateSection={willCreateSection}
                      handleSave={handleSave}
                      currentExamQuestionEditingValues={currentExamQuestionEditingValues}
                      currentExamQuestionNumber={currentExamQuestionRelativeNumber + 1}
                      currentExamSectionNumber={currentExamSection + 1}
                      totalQuestions={totalQuestions}
                      totalQuestionsInCurrentSection={totalQuestionsInCurrentSection}
                      examSectionNumber={examSectionNumber}
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
        <ModalSaveExam
          show={saveExamModal}
          onHide={() => setsaveExamModal(false)}
          examId={examId}
          setSavingInGallery={setSavingInGallery}
          colorScheme={CurrentColor}
          duplicateExam={duplicateExam}
          createExamSectionRequest={createExamSectionRequest}
          createQuestionRequest={createQuestionRequest}
          dataExamId={dataExamId}
          isLoading={isLoading}
          currentExamSection={currentExamSection}
          totalSections={totalSections}
          firstQuestionInSection={firstQuestionInSection}
          lastQuestionInSection={lastQuestionInSection}
        />
      </div>
    </div>
  );
}

// TODO use a Toast component
function ErrorFallback({ error }) {
  return (
    <div className="alert alert-danger" role="alert">
      {error.message || 'There was an issue contact the page administrator'}
    </div>
  );
}
