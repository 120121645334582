import React from 'react';
import { ICONS_COLORS } from '../../../enums/constants.enum';
import { ReactComponent as CrossBlack } from 'assets/whiteboard/black/cross-black.svg';
import { ReactComponent as CrossGrey } from 'assets/whiteboard/grey/cross-grey.svg';
import { ReactComponent as CrossWhite } from 'assets/whiteboard/white/cross-white.svg';

const BookingTool = ({ color, onClick }) => {
  return (
    <div onClick={onClick}>
      {color === ICONS_COLORS.BLACK && <CrossBlack className="page-icon-item" />}
      {color === ICONS_COLORS.WHITE && <CrossWhite className="page-icon-item" />}
      {color === ICONS_COLORS.GREY && <CrossGrey className="page-icon-item" />}
    </div>
  );
};

export default BookingTool;
