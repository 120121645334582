export const elementEnum = {
  NONE: 'NONE',
  TEXT_RESIZE: 'TEXT RESIZE',
  TEXT_PAINTED: 'TEXT PAINTED',
  TEXT_EDITING: 'TEXT EDTING',
  SHAPE_MENU_ELEMENT: 'SHAPE MENU ELEMENT',
  SHAPE_MENU_CANVAS: 'SHAPE MENU CANVAS'
};

export const shapeEnum = {
  text: 'TEXT',
  image: 'IMAGE'
};
