import React from 'react';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { ReactComponent as RedoBlack } from 'assets/whiteboard/black/redo-black.svg';
import lodash from 'lodash';

export default function RedoChangeTool({ style, stylesTestButton }) {
  const { action, state } = useCurrentSlide();

  const handleRedoChange = () => {
    const { actualPosition, history } = state.modificationHistory;
    if (actualPosition < history.length - 1) {
      action.setModificationHistory(modificationHistory => {
        const position = actualPosition + 1;
        const { idSelectSlide, draft } = history[position];
        action.setCurrentDraft(lodash.cloneDeep(draft));
        action.setSelectedSlideIndex(idSelectSlide);

        return {
          ...modificationHistory,
          modifying: true,
          actualPosition: position
        };
      });
    }
  };

  return (
    <React.Fragment>
      <RedoBlack onClick={handleRedoChange} className={`${stylesTestButton} ${style}`} />
    </React.Fragment>
  );
}
