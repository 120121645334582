import { useCurrentSlide } from './hooks/useCurrentSlide';
import { SlideViewMain, SlideViewReadMain } from './components/Slide/Slide';
import { ButtonSave } from './components/Buttons/ButtonSlide';
import { useEffect } from 'react';
import { enterFullScreen } from './funtions/utils';
import ListSlides from './components/ListSlides/ListSlides';
import HeaderSlideView from './components/HeaderSlideView/HeaderSlideVIew';
import HeaderEditMode from './components/HeaderEditMode/HeaderEditMode';
import styles from './styles.module.scss';
import './assets/fonts/Anton/Anton-Regular.ttf';
import './assets/fonts/Bangers/Bangers-Regular.ttf';
import './assets/fonts/Bevan/Bevan-Regular.ttf';
import './assets/fonts/Calligraffitti/Calligraffitti-Regular.ttf';
import './assets/fonts/Carter_One/CarterOne-Regular.ttf';
import './assets/fonts/Chakra_Petch/ChakraPetch-Regular.ttf';
import './assets/fonts/Courgette/Courgette-Regular.ttf';
import './assets/fonts/Figtree/Figtree-VariableFont_wght.ttf';
import './assets/fonts/Fredericka_the_Great/FrederickatheGreat-Regular.ttf';
import './assets/fonts/Grape_Nuts/GrapeNuts-Regular.ttf';
import './assets/fonts/Indie_Flower/IndieFlower-Regular.ttf';
import './assets/fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf';
import './assets/fonts/Julius_Sans_One/JuliusSansOne-Regular.ttf';
import './assets/fonts/Kalam/Kalam-Regular.ttf';
import './assets/fonts/Marck_Script/MarckScript-Regular.ttf';
import './assets/fonts/Open_Sans/OpenSans-Regular.ttf';
import './assets/fonts/Parisienne/Parisienne-Regular.ttf';
import './assets/fonts/Permanent_Marker/PermanentMarker-Regular.ttf';
import './assets/fonts/Play/Play-Regular.ttf';
import './assets/fonts/Racing_Sans_One/RacingSansOne-Regular.ttf';
import './assets/fonts/Anton/Anton-Regular.ttf';
import './assets/fonts/Roboto/Roboto-Regular.ttf';
import './assets/fonts/Rubik_Bubbles/RubikBubbles-Regular.ttf';
import './assets/fonts/Secular_One/SecularOne-Regular.ttf';
import './assets/fonts/Tillana/Tillana-Regular.ttf';
import './assets/fonts/Digital_7/digital-7.ttf';
import './assets/fonts/BadScript/BadScript-Regular.ttf';
import './assets/fonts/Futurette/Futurette-ExtraLight.ttf';
import './assets/fonts/Larabie/larabiefont.ttf';
import './assets/fonts/Mvboli/mvboli.ttf';
import './assets/fonts/Zektonit/zektonit.ttf';
import './assets/fonts/RoundHand/RoundHand.ttf';

export default function DigitalBook() {
  const { state, action } = useCurrentSlide();

  useEffect(() => enterFullScreen(), [state.editMode]);

  return (
    <>
      {!state.editMode ? (
        <>
          <div className={styles.digitalBookContainer}>
            <div className={styles.headerContainer}>
              <HeaderSlideView />
            </div>
            <div className={styles.whiteboardContainer}>
              <div> {/* Box empty */} </div>
              <SlideViewReadMain />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.digitalBookContainerEdit}>
            <div>
              <HeaderEditMode />
            </div>
            <div className={styles.whiteboardContainer}>
              <ButtonSave onClick={action.saveDataDraft} />

              {state.whiteboardSize.small.width && <ListSlides />}
              <SlideViewMain />
            </div>
          </div>
        </>
      )}
    </>
  );
}
