import React from 'react';
import { ReactComponent as Trash } from 'assets/learningContent/iconsBlue/trash.svg';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';

export default function DeleteSlideTool({ style }) {
  const { action, state } = useCurrentSlide();

  const handleDeleteSlide = () => {
    if (state.currentDraft.length < 2) return;
    if (state.selectedSlideIndex !== null) {
      const newIndex =
        state.selectedSlideIndex > 0
          ? state.selectedSlideIndex - 1
          : state.selectedSlideIndex;

      const updatedSlides = state.currentDraft
        .filter((_, index) => index !== state.selectedSlideIndex)
        .map((el, index) =>
          state.selectedSlideIndex <= index ? { ...el, order: el.order - 1 } : el
        );

      action.setSelectedSlideIndex(newIndex);
      action.setCurrentDraft(updatedSlides);
    }
  };

  return (
    <React.Fragment>
      <Trash onClick={handleDeleteSlide} className={style} />
    </React.Fragment>
  );
}
