import React, { useEffect, useRef, useCallback } from 'react';
import { Stage, Layer } from 'react-konva';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import {
  TextareaEditing,
  TextareaPainted,
  TextareaResize
} from './components/Textarea/Textarea';
import { CanvasMenu, ShapeMenu } from './components/Menu/Menu';
import { elementEnum, shapeEnum } from './slide-types';
import { useElementCurrent } from './hooks/useElementCurrent';
import { useShapeMenu } from 'components/DigitalBook/hooks/useShapeMenu';
import { useKonva } from './hooks/useKonva';
import { ReactComponent as PrevSlideIcon } from 'assets/learningContent/iconsBlue/left arrow-slide.svg';
import { ReactComponent as NextSlideIcon } from 'assets/learningContent/iconsBlue/right arrow-slide.svg';
import { ButtonEdit } from '../Buttons/ButtonSlide';
import ImageKonva from './konva/ImageKonva';
import styles from './styles.module.scss';
import TextKonva from './konva/TextKonva';
import { calculateScaleFullScreen } from './slide-functions';

export const SlideViewMain = () => {
  const { action: actionCurrentSlide, state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText, state: stateConfigText } = useConfigText();
  const { action: actionKonva } = useKonva();
  const { action: actionShapeMenu } = useShapeMenu();

  const { element } = useElementCurrent();
  const styleContainerCanvas = !stateConfigText.editTextMode.openTextTool
    ? styles.containerWhiteboardComponent
    : styles.editingText;
  const styleContainerStage = !stateConfigText.editTextMode.openTextTool
    ? styles.containerStage
    : styles.containerStageText;
  const divRef = useRef(null);

  const elementCurrentView = {
    [elementEnum.NONE]: null,
    [elementEnum.TEXT_RESIZE]: <TextareaResize />,
    [elementEnum.TEXT_PAINTED]: <TextareaPainted />,
    [elementEnum.TEXT_EDITING]: <TextareaEditing />,
    [elementEnum.SHAPE_MENU_ELEMENT]: <ShapeMenu />,
    [elementEnum.SHAPE_MENU_CANVAS]: <CanvasMenu />
  };

  const doMakeCalculation = () => {
    if (!divRef?.current) return;

    const scaleWidth = divRef.current.offsetWidth / stateCurrentSlide.propsLevel.width;
    const scaleHeight = divRef.current.offsetHeight / stateCurrentSlide.propsLevel.height;
    const scale = Math.min(scaleWidth, scaleHeight);

    actionCurrentSlide.handleSaveSizeBoard('normal', {
      width: stateCurrentSlide.propsLevel.width * scale,
      height: stateCurrentSlide.propsLevel.height * scale,
      scale
    });

    actionCurrentSlide.handleSaveSizeBoard('small', {
      width:
        stateCurrentSlide.propsLevel.width * stateCurrentSlide.whiteboardSize.small.scale,
      height:
        stateCurrentSlide.propsLevel.height * stateCurrentSlide.whiteboardSize.small.scale
    });
  };

  const shapesPainted = {
    [shapeEnum.image]: el => (
      <ImageKonva
        key={el.id}
        isSelected={el.id === stateCurrentSlide.selectIdShape}
        shapeProps={{ ...el, draggable: !stateConfigText.editTextMode.openTextTool }}
        onImageSelect={actionKonva.handleImageSelect}
        onContextMenu={actionKonva.handleImageContextMenu}
        onTransformStart={actionKonva.handleShapeTransformStart}
        onTransformEnd={actionKonva.handleShapeTransformEnd}
        onDragStart={actionKonva.handleShapeDragStar}
        onDragEnd={actionKonva.handleShapeDragEnd}
      />
    ),
    [shapeEnum.text]: el => (
      <TextKonva
        key={el.id}
        isSelected={
          el.id === stateCurrentSlide.selectIdShape &&
          (stateConfigText.editTextMode.openTextTool ||
            stateCurrentSlide.shapeMenu.isOpen)
        }
        textProps={{ ...el, draggable: !stateConfigText.editTextMode.openTextTool }}
        onTextSelect={actionKonva.handleTextSelect}
        onTransformEnd={actionKonva.handleShapeTransformEnd}
        onContextMenu={actionKonva.handleTextContextMenu}
        onDragStart={actionKonva.handleShapeDragStar}
        onDragEnd={actionKonva.handleShapeDragEnd}
        onTransform={actionKonva.handleShapeTransform}
        onSaveHistory={actionCurrentSlide.handleSaveHistory}
      />
    )
  };

  const handleKeyDown = e => {
    document.removeEventListener('keydown', handleKeyDown);
    if (stateConfigText.editTextMode.openTextEditing) return;
    if (
      e.key === 'Delete' &&
      stateCurrentSlide.selectIdShape &&
      !stateConfigText.editTextMode.openTextEditing
    ) {
      actionShapeMenu.handleRemoveElement();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [stateCurrentSlide.selectIdShape, stateConfigText.editTextMode]);

  useEffect(() => {
    window.addEventListener('resize', doMakeCalculation);
    setTimeout(() => doMakeCalculation(), 0);
    return () => window.removeEventListener('resize', doMakeCalculation);
  }, [divRef?.current]);

  return (
    <div
      className={styleContainerCanvas}
      onMouseMove={e =>
        actionConfigText.beginAndFinishSelection(e.nativeEvent, 'move', 'text')
      }
      onMouseUp={e =>
        actionConfigText.beginAndFinishSelection(e.nativeEvent, 'finish', 'text')
      }
      ref={divRef}
    >
      <Stage
        width={stateCurrentSlide.whiteboardSize.normal.width}
        height={stateCurrentSlide.whiteboardSize.normal.height}
        scaleX={stateCurrentSlide.whiteboardSize.normal.scale}
        scaleY={stateCurrentSlide.whiteboardSize.normal.scale}
        onMouseDown={actionKonva.handleStageMouseDown}
        onContextMenu={actionKonva.handleStageContextMenu}
        className={styleContainerStage}
        style={{ cursor: 'inherit' }}
      >
        <Layer>
          {stateCurrentSlide.currentDraft[
            stateCurrentSlide.selectedSlideIndex
          ]?.shapes.map(el => shapesPainted[el.shape](el))}
        </Layer>
      </Stage>
      {elementCurrentView[element]}
    </div>
  );
};

export const SlideViewMiniature = ({ slide }) => {
  const { state } = useCurrentSlide();

  const shapesPainted = {
    [shapeEnum.image]: el => (
      <ImageKonva
        key={el.id}
        isSelected={false}
        shapeProps={{ ...el, draggable: false }}
      />
    ),
    [shapeEnum.text]: el => (
      <TextKonva key={el.id} textProps={{ ...el, draggable: false }} isSelected={false} />
    )
  };

  return (
    <div className={styles.containerWhiteboardReadingComponent} key={slide.id}>
      <Stage
        width={state.whiteboardSize.small.width}
        height={state.whiteboardSize.small.height}
        className={styles.containerStageMiniature}
        scaleX={state.whiteboardSize.small.scale}
        scaleY={state.whiteboardSize.small.scale}
      >
        <Layer>{slide.shapes.map(el => shapesPainted[el.shape](el))}</Layer>
      </Stage>
    </div>
  );
};

export const SlideViewReadMain = () => {
  const { state, action } = useCurrentSlide();
  const divRef = useRef(null);

  const styleBoxSlide = {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    left: `-${state.propsLevel.slideIndex * 100}%`,
    transition: 'left ease 1s'
  };

  const styleContentSlide = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%'
  };

  const shapesPainted = {
    [shapeEnum.image]: el => (
      <ImageKonva
        key={el.id}
        isSelected={false}
        shapeProps={{ ...el, draggable: false }}
      />
    ),
    [shapeEnum.text]: el => (
      <TextKonva key={el.id} textProps={{ ...el, draggable: false }} isSelected={false} />
    )
  };

  const handleCLickPrevious = () => {
    if (state.propsLevel.slideIndex === 0) return;
    action.setPropsLevel(propsLevel => ({
      ...propsLevel,
      slideIndex: propsLevel.slideIndex - 1
    }));
  };

  const handleCLickNext = () => {
    if (state.propsLevel.slideIndex + 1 === state.currentDraft.length) return;
    action.setPropsLevel(propsLevel => ({
      ...propsLevel,
      slideIndex: propsLevel.slideIndex + 1
    }));
  };

  const handleResizeWindow = useCallback(
    divRef => {
      if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
        const { width, height } = state.propsLevel;
        const escalaPantallaCompleta = calculateScaleFullScreen(width, height);
        const nuevoAncho = width + escalaPantallaCompleta;
        const nuevoAlto = height + escalaPantallaCompleta;

        const scaleWidth = divRef.current.offsetWidth / nuevoAncho;
        const scaleHeight = divRef.current.offsetHeight / nuevoAlto;
        const scale = Math.min(scaleWidth, scaleHeight);

        action.handleSaveSizeBoard('large', {
          width: nuevoAncho * scale,
          height: nuevoAlto * scale,
          scale
        });
      }
    },
    [action, state.propsLevel.height, state.propsLevel.width]
  );

  useEffect(() => handleResizeWindow(divRef), [divRef?.current]);

  useEffect(() => {
    const eventResize = () => handleResizeWindow(divRef);
    window.addEventListener('resize', eventResize);
    return () => window.removeEventListener('resize', eventResize);
  }, []);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        minHeight: '100%'
      }}
    >
      <PrevSlideIcon className={styles.icon} onClick={handleCLickPrevious} />
      <div className={styles.containerWhiteboardMain} ref={divRef}>
        <ButtonEdit />

        <div style={styleBoxSlide}>
          {state.currentDraft.map(draft => (
            <div style={styleContentSlide}>
              <Stage
                key={draft.id}
                width={state.whiteboardSize.large.width}
                height={state.whiteboardSize.large.height}
                scaleX={state.whiteboardSize.large.scale}
                scaleY={state.whiteboardSize.large.scale}
                className={styles.containerStageRead}
              >
                <Layer>{draft.shapes.map(el => shapesPainted[el.shape](el))}</Layer>
              </Stage>
            </div>
          ))}
        </div>
      </div>
      <NextSlideIcon className={styles.icon} onClick={handleCLickNext} />
    </div>
  );
};
