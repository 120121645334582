import styles from './styles.module.scss';
import plus from '../../assets/plus-icon.svg';
import minus from '../../assets/minus-icon.svg';
import { ColorPalette } from '../colorPalette';
import { Fonts } from '../fonts';

export const TextEditorTools = ({
  editText,
  fontSizes,
  increaseFontSizes,
  decreaseFontSizes,
  setFontFamily,
  setColor
}) => {
  return editText ? (
    <div className={styles.containerEditText}>
      <div className={styles.fonts}>
        <Fonts setFontFamily={setFontFamily} />
      </div>
      <div onClick={decreaseFontSizes} className={styles.icon}>
        <img src={minus} alt="minus icon" />
      </div>
      <div onClick={increaseFontSizes} className={styles.icon}>
        <img src={plus} alt="plus icon" />
      </div>
      <ColorPalette setColor={setColor} />
    </div>
  ) : null;
};
