import React, { useState, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ArrowIcon from '../../../../assets/up arrow clock.svg';
import { FaPlus } from 'react-icons/fa6';
import { FaMinus } from 'react-icons/fa6';

const Skills = ({ skills, setSkills, loading, formRef }) => {
  const skillList = ['listening', 'speaking', 'writing', 'reading'];

  const accordionToggleRef = useRef('');
  const [deployed, setDeployed] = useState(false);

  const saveSkill = newSkill => {
    setSkills({
      ...skills,
      [newSkill.name]: newSkill.value
    });
  };

  const upNumberInput = id => {
    const element = document.getElementById(id);
    const base = element.value === '' ? 0 : parseInt(element.value);
    element.value = base + 1;
    saveSkill(element);
  };

  const downNumberInput = id => {
    const element = document.getElementById(id);
    const base = element.value === '' ? 0 : parseInt(element.value);
    element.value = base - 1;
    saveSkill(element);
  };

  const accordionToggle = () => {
    if (deployed) {
      skillList.forEach(item => {
        formRef.current[item].value = '';
      });
      setSkills({
        listening: '',
        speaking: '',
        writing: '',
        reading: ''
      });
    }

    setDeployed(!deployed);
  };

  // MOUNTED
  useEffect(() => {
    if (skills) {
      skillList.forEach(item => {
        formRef.current[item].value = skills[item];
      });

      setSkills(skills);
      setDeployed(true);
      accordionToggleRef.current.click();
    }
  }, []);

  return (
    <Accordion>
      <Accordion.Collapse eventKey="0">
        <React.Fragment>
          {skillList.map((item, i) => (
            <div className="input-skill" style={{ height: '28px' }} key={i}>
              <label style={{ fontWeight: 'bold' }} htmlFor={item}>
                {item}
              </label>
              <div className="container_input">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <FaMinus color="blue" onClick={() => downNumberInput(item)} />
                </div>

                <input
                  type="number"
                  id={item}
                  value={skills ? skills[item] : ''}
                  name={item}
                  onChange={e => saveSkill(e.target)}
                  min="0"
                  max="100"
                  required={deployed}
                  disabled={loading}
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <FaPlus
                    className="scorep"
                    color="blue"
                    onClick={() => upNumberInput(item)}
                  />
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      </Accordion.Collapse>

      <Accordion.Toggle
        ref={accordionToggleRef}
        className={loading ? 'disabled' : ''}
        variant="link"
        eventKey="0"
        onClick={() => accordionToggle()}
        disabled={loading}
      >
        <div style={{ marginTop: '15px' }}>
          {deployed ? (
            <img src="/static/icons/minus.svg" alt="Icono menos" />
          ) : (
            <img src="/static/icons/plus.svg" alt="Icono más" />
          )}
        </div>
      </Accordion.Toggle>
    </Accordion>
  );
};

export default Skills;
