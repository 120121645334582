import { useMutation } from '@apollo/client';
import { useUser } from 'components/UserManagment/UserProvider';
import { TEACHER_VISITING_CLASSROOM } from 'components/Whiteboard/graphQL/mutations';
import { isUserAdmin } from 'services/cognito.service';

export const ButtonsBelongsAdmin = ({ isActive, setIsActive }) => {
  const handleActive = async () => {
    const isAdmin = await isUserAdmin();
    setIsActive(active => (isAdmin ? !active : false));
  };

  return (
    <div className="button--purper button--outlined" onClick={handleActive}>
      visit
    </div>
  );
};

export const ButtonsNotBelongsAdmin = ({ assignedClassRoom }) => {
  const [teacherVisitingClassroomMutation] = useMutation(TEACHER_VISITING_CLASSROOM);
  const { user } = useUser();

  const handleClickReturn = async () => {
    await teacherVisitingClassroomMutation({
      variables: {
        teacherId: user.attributes?.sub,
        classRoomIdVisiting: 'UNVISITED CLASSROOM'
      }
    });
    window.location.assign(`/whiteBoard/${assignedClassRoom.classRoomId}`);
  };

  return (
    <>
      <div className="button--purper button--outlined">visiting</div>
      <div className="button--rose button--contained" onClick={handleClickReturn}>
        return
      </div>
    </>
  );
};
