import React, { useEffect, useState } from 'react';
import IconsAnimation from './IconsAnimation';
import { useHistory } from 'react-router-dom';

const IconPaths = () => {
  const [animationInit, setAnimationInit] = useState(false);
  const { listen } = useHistory();

  useEffect(() => {
    let timeOutId = null;
    if (!animationInit) {
      setAnimationInit(true);
      let animateHome = new IconsAnimation();
      let initType = Math.floor(Math.random() * 101);

      if (initType > 50) {
        animateHome.Animate();
      } else {
        timeOutId = setTimeout(() => {
          animateHome.Animate();
        }, animateHome.timeForNextAnimation);
      }
    }

    return listen(location => {
      let excludePath = ['/', '/login'];
      if (excludePath.indexOf(location.pathname) == -1) {
        if (timeOutId) {
          clearTimeout(timeOutId);
          timeOutId = null;
        }
      }
    });
  }, []);

  return (
    <>
      <svg
        preserveAspectRatio="none"
        id="animateSVGCanvas"
        style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
        width="100%"
        height="100%"
        viewBox="0 0 1380 901"
      >
        {/* <!--tellaFriend path --> */}
        <path
          fill="none"
          id="share-path"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M1289.761,249.537V60.805H67.6l0.393,562.637
    l307.482-1.099l1.639,138.748l355.732,0.198l2.987-700.678l145.613,1.635l1.237,285.652l-207.149-0.523l606.563,5.236l4.446,374.39
    L1256,727.25l-73.751,0.323l92.25-0.478l12.78-0.346l3.574-481.858l-1054.894-1.062l2.187,320.788l789.384-1.225l-0.591,166.616
    c0,0-4.492-3.08-12.768-10.013c-4.926-4.128-9.381-8.043-14.671-13.662c-8.049-8.549-21.019-34.933-24.309-44.83
    c0,0-0.275-0.563-0.742-1.622c-3.705-8.411-14.067-67.879-0.312-105.842c0,0,10.902-54.805,63.365-77.587
    c0,0,49.013-22.067,92.249-6.227c43.237,15.84,55.663,35.606,55.663,35.606s35.752,51.835,31.194,100.073
    c0,0-3.477,50.593-17.757,76.832c0,0-14.019,30.93-40.025,47.885c0,0-12.112,8.03-27.377,13.572
    c-12.591,4.572-27.229,9.024-39.85,7.635c0,0-32.475-2.789-49.942-11.494c0,0-47.159-30.669-54.932-51.095
    c0,0-26.226-42.907-20.569-85.454c0,0-1.658-63.453,36.958-100.194c0,0,49.522-64.439,136.553-31.335l22.393,10.889l21.303,17.464
    l23.47,38.841l10.333,62.214l-1.627,26.215L1212.167,795L93.357,793.271"
        />
        {/* <!--information path --> */}
        <path
          fill="none"
          id="information-path"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M345.594,842.551l794.901,2.045l0.754-118.737
    l143.182,1.096l-1.391-471.687l-652.459-0.425l534.154-3.562l-273.434-5.262l5.205,598.084l-614.093,2.019V57.793l971.298-1.05
    l1.639,554.892l-268.419-0.912l0.521,78.478l-712.336,3.421l-0.005-347.028l-195.521,0.63l1.534,261.826l756.458-0.968l-1-229.986
    l371.978-3L95.543,371.939"
        />
        {/* <!--Briefcase path --> */}
        <path
          fill="none"
          id="companies-path"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M110.06,431.633l999.696-2.864l-0.332,374.196
    H74.852L73.533,58.362l1147.737-0.005L682.9,60.687l0.228,145.323l-329.36-0.054l-2.718-145.999L82.332,60.479l1093.48-0.631
    l-277.957,0.95l-0.098,254.865l-1.541-253.376l-656.03,0.797l990.31-3.09l-1.094,463.864L97.151,525.42"
        />
        {/* <!--program path --> */}
        <path
          fill="none"
          id="program-path"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M900.354,71.415v363.473l-78.131-0.227v145.003
    l-610.389,1.18V422.243l1042.761-5.314l-1.013,310.467l-132.333,0.462l0.245,116.382l-849.375,1.046V656.418l667.963-4.322
    l-1.506-214.005l258.372-2.501l-0.682-205.857l-1087.846-0.67l-1.642-175.13l989.053,3.909l2.187,217.344l-899.354,4.054
    l-2.287-177.173l931.298,1.899L95.035,99.345"
        />
        {/* <!--envelope path --> */}
        <path
          fill="none"
          id="envelope-path"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M83.458,62.611h129.928L72.39,61.549h360.574
    l1.024,149.396h290.289l-0.729-149.396l558.467-0.531l1.092,268.434c0,0,25.87-264.005-309.679-263.738L411.35,68.8l0.005,172.08
    H93.126l0.919,249.435l390.406-1.186l-3.349-344.773l2.187,611.589l-421.558-4.083l-0.957-307.823l950.163,2.009l-915.805,1.749
    l0.489,272.279l182.48,1l-0.314-111.998l942.547,3.589L962.5,613.5l201.833-3.843l0.833,54.676l-1072.162-1.63"
        />
        {/* <!--key path --> */}
        <path
          fill="none"
          id="key-path"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M955.265,668.336l337.058-1.062l1-88.447
    l-1083.009,2.417l1.619-386.311l2.604,246.855l1047.603,3.837l-0.483,278.375l-120.504,0.189l0.346,121.856L44.79,846.012
    l-1.355-335.185l1078.257-2.039l-154.443,3.016l-0.184,107.385c0,0,5.435,61.811,42.855,96.654c0,0,31.828,42.617,96.814,34.559
    c0,0,45.484-0.736,85.912-54.342c0,0,18.45-32.238,22.947-85.367c0,0-1.232-75.64-31.204-105.975c0,0-40.248-41.03-82.904-39.931
    c0,0-56.486-14.288-107.986,46.545c0,0-30.552,50.498-27.946,95.11c0,0-2.305,68.293,40.299,104.937c0,0,34.8,42.791,86.689,40.135
    c0,0,58.217,1.754,96.451-50.292c0,0,30.012-49.239,27.827-97.569c0,0,2.868-58.238-34.538-100.974c0,0-18.246-24.226-62.505-35.962
    c-69.686-16.235-124.446,41.886-124.446,41.886l80.249,1.362l2.99-274.269l-1.842-88.941l1.196,90.223L475.5,238l620,1L70.774,235"
        />
      </svg>
      <svg
        preserveAspectRatio="none"
        id="animateSVGCanvasMobil"
        style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
        width="100%"
        height="100%"
        viewBox="0 0 240 320"
      >
        {/* <!--tellaFriend path --> */}
        <path
          fill="none"
          id="share-path-mobil"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M23.464,304.381h145.561l-1.484-138.094H50.9
        h52.792l0.956-37.821l-76.219-0.937l-4.505,152.813l0.391-32.009l45.52-1.166L23.464,246l1.551-222.908l191.85-0.834
        l-0.623,137.015l-188.048-0.94l-0.881,144.803h160.471l0.753-127.636l-75.507-28.063L31.75,147l174.034-1.621l-173.676-0.827
        l173.545-1.794L31.19,142.485l108.309-1.437l0.674-108.988L30.5,30.835l139.355,0.06L46.238,32.31l126.265,0.734l0.221,71.722
        l-143.795-1.111l1.818-56.789l-0.518,192.685l147.606,0.606l-0.862,62.113l-151.676-1.622l156.676,1.622
        c0,0,10.694-6.27,1.694-27.719H26.41"
        />
        {/* <!--information path --> */}
        <path
          fill="none"
          id="information-path-mobil"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M148.46,168.094h64.668l-189.433-2.321
            l-1.143-57.497v108.808l128.072,1.226l0.144-108.305l-82.581-0.928l-0.683-45.96L67.15,163.454l133.365,2.062L23.627,162.35
            l0.396-145.424l130.905,1l-131.905-1l1.908,72.199l147.099,2.08l-70.281-1.918l0.501-47.037l-75.884-0.752l-0.34-23.487
            l152.387,1.461l-139.756,0.66L178.413,21.5L39.029,22l139.384,1L39.5,23.5l134.499,0.374L174,78l38.547,1l-0.369,77.141
            l-81.959-0.388l0.375,26.058H84.703l85.27,1.243l0.081-83.405l0.541-22.162L34.826,76.125l167.66,3.145l-3.243,199.47l-111.216-1
            l38.723,0.261l-0.25-43.751l70.548,0.246l1.655-89.686l-81.054-1.324c0,0,11.851,19.514,27.014,0.549l66.577-0.703l-184.325-1.549
            "
        />
        {/* <!--Briefcase path --> */}
        <path
          fill="none"
          id="companies-path-mobil"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M33.742,107.567l182.896,0.519l0.729,104.025
        l-92.49,0.518l1.623,95.037l-105,0.834l164-2.25l-164-0.083l123.434-0.251l-51.688-0.436l93.505-1.979l-151.583,0.334
        l-0.173-79.347l173.998-1.416l0.758,52.679l-109.5,1l-0.585-25.053l44.002,0.868L36.5,252.833L178.833,253l-141,1.5L180,255.667
        l-137.833,1.5l142.5,0.167l-146.164,1.733L194.5,254.5l-0.667-57.333l14.333-1l-34.71,0.126L67,196.025l114.993,0.956L182,157
        l-120-1l-0.5-40.833l152.304,0.008l-0.287-91.155L42.533,24.648L211,25l0.5,122l-61.552-0.512l0.051,41.856L31.5,188l56.397,0.19
        c0,0,8.936-12.69,17.458,0.329L216,187.5l-188.59-0.167"
        />
        {/* <!--program path --> */}
        <path
          fill="none"
          id="program-path-mobil"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M140.964,107.219h-27.238V64.877l-77.393-0.21
    l156.334-0.187L150,65.167l66.412-0.812l2.356,148.871l-196.55-1.393l0.5-100.582l63.907,0.624l0.124-48.365l86.917,0.323
    l-0.498,46.238l-30.739,0.277l-0.172-45.916l37.618-1.554L44.182,61.129l0.476,54.87l125.841,0.417L170,45.5l48.855-0.5
    l0.743,80.041l-97.765,0.126l97.675,0.042l-0.654-61.617L36.667,63.333l143.5-3.833h-144L180,61.833L36.667,61.304l142.832-0.56
    L21.873,59.78v11.053l149.46,1.667l-149.46-0.167l0.843,120.909l182.948-0.034l0.768,51.874l-182.065-0.464l0.477,66.454
    l71.656-0.365L46,310.5L45.506,98.491L21.873,98.75V53.833l38.296,0.465c0,0,7.081-8.798,14.998-0.132L218,53.5v5L26.873,58.697"
        />
        {/* <!--envelope path --> */}
        <path
          fill="none"
          id="envelope-path-mobil"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M42.17,233.907v-26.969l128.354-1.018V105.344
    H23.979V66.67l103.087,0.018l0.252-46.655h96.25l-124.189,1.94l124.189,0.378l-2.324,247.665L98,269.595l0.162-76.973l107.362-0.541
    l-178.196,0.561l-0.632,111.926L172.333,305L39,305.333l142.333,1h-143L173,307.667L38.333,307l139.346,1.689l-147.411-1.531
    L30.25,241.5l145.25-1l-0.235,66.515l-64.997,0.143l-1.089-88.202l103.858-0.361l0.097-54.234H77V147.5l137.992-0.302l0.3,37.308
    L83,185.62l138.961,0.044L91,184.5V232l127.5-0.5H117c0,0-7.496,13.759-14.5,0l-18.482-0.393l83.988-0.684l-140.892,0.128"
        />
        {/* <!--key path --> */}
        <path
          fill="none"
          id="key-path-mobil"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M216.125,250.632H24.3l192.579-2.059l1.116-46.073H177.5l0.494-46.229l-119.492-0.375l103.265-1.159l-0.839-79.37l-1.006,137.091L24.586,212.67l-1.341,46.99l0.809-152.921l191.393-1.022l0.335-66.996l2.157,155.266l-79.106,1.179c1.975,0-81.666,4.834-82.667-54c1.001-4.499-8.333-55.999,64.981-60.667c0,0,67.603,0.001,69.019,55.333c0,0,4.667,56.667-69.167,59c0,0-39.25-0.083-56-25.833c0,0-18-30-5.25-54.75c0,0,12.5-30.25,55-33.75c0,0,30.75-4.75,57.75,16.25c0,0,22,19.5,15.75,51.5c0,0-4,30.25-36.75,42c0,0-27.25,11-57.75-1.75c-5.697-2.242-32.136-9.758-37.25-41.25c0,0-6.75-41,26.25-57.75c0,0,18.341-9.925,41.75-9c0,0,30.5,0.25,49.75,18.75c0,0,15.248,17.105,14.5,37c0,0,5.75,26.5-18,42.75c0,0-21.105,15.625-45.75,15L36.75,193.25l0.463,50.225h144.92l0.535,47.059H25.833v-50.035l89.668-0.931L31.5,239V133.5l136.501-0.304L167.5,52.5H26.167L26,74.5L197,74l-90,0.5l107,0.75v24.301l-186.855-0.04"
        />
      </svg>
    </>
  );
};

export default IconPaths;
