import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag'; // Asegúrate de importar gql de graphql-tag
import React from 'react';
import { S3Service } from 'services/S3.service';
import { DELETE_EXAM_MUTATION, DELETE_FILE_MUTATION } from '../graphQL/mutations';
import './styles/DeleteListFileExam.css';

const DeleteListFileExam = ({ selectExam, setselectExam, setviewFileExams }) => {
  const [deleteFileMutation] = useMutation(DELETE_FILE_MUTATION);
  const [deleteExamMutation] = useMutation(DELETE_EXAM_MUTATION);

  const handleDeleteExam = async () => {
    try {
      const { data } = await deleteExamMutation({
        variables: {
          input: {
            id: selectExam.id
            // ... otras propiedades necesarias para tu mutación
          }
        }
      });

      console.log('Examen eliminado:');
      setviewFileExams(false);
    } catch (error) {
      console.error('Error al eliminar el examen:', error.message);
      alert('Error deleting exam');
    }
  };

  const confirmDeleteFileExam = async () => {
    try {
      const { data } = await deleteFileMutation({
        variables: {
          input: {
            id: selectExam.id
            // ... otras propiedades necesarias para tu mutación
          }
        }
      });

      console.log('Archivo eliminado:');
      setselectExam('examen eliminado');
      setviewFileExams(false);
    } catch (error) {
      console.error('Error al eliminar el archivo:', error.message);
      alert('Error deleting file');
    }
  };

  return (
    <div className="deleteListFileExam__contianer">
      <p>Are you sure you want to delete the exam?</p>
      <div className="deleteListFileExam__optionDelete">
        <p
          onClick={() => {
            confirmDeleteFileExam();
            handleDeleteExam();
          }}
        >
          Yes
        </p>
        <p onClick={() => setselectExam('')}>No</p>
      </div>
    </div>
  );
};

export default DeleteListFileExam;
