import { S3Service } from 'services/S3.service';

const s3Service = new S3Service('public', 'image/png');

export const getBase64 = async file => {
  const resizeImageSizes = (w, h) => {
    const limitSize = 500;
    let width, height;

    if (w > h) {
      width = Math.min(w, limitSize);
      height = (width / w) * h;
    } else {
      height = Math.min(h, limitSize);
      width = (height / h) * w;
    }
    return { width, height };
  };

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      const imagen = new Image();
      imagen.src = event.target.result;
      imagen.onload = () => {
        const { width, height } = resizeImageSizes(
          imagen.naturalWidth,
          imagen.naturalHeight
        );
        resolve({
          result: event.target.result,
          width,
          height
        });
      };
    };
    reader.onerror = error => reject(error);
  });
};

export const orderByFirstLetter = arrStrings => {
  return arrStrings.sort((a, b) => {
    const primeraLetraA = a.charAt(0).toLowerCase();
    const primeraLetraB = b.charAt(0).toLowerCase();

    if (primeraLetraA < primeraLetraB) {
      return -1;
    }
    if (primeraLetraA > primeraLetraB) {
      return 1;
    }
    return 0;
  });
};

export const exitFullScreen = async () => {
  try {
    if (document.exitFullscreen) {
      await document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      await document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      await document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      await document.msExitFullscreen();
    }
  } catch (error) {
    console.error(error);
  }
};

export const enterFullScreen = async () => {
  try {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      await element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      await element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      await element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      await element.msRequestFullscreen();
    }
  } catch (error) {
    console.error(error);
  }
};
