/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useEventAnimateNotes } from '../../../hooks/useEventAnimateNotes';
import { useSocket } from 'providers/SocketProvider';
import { useWhiteBoard } from '../WhiteBoardProvider';
import { TOOLS } from 'enums/whiteBoard.enum';
import { NotesMain } from './NotesMain';
import './notes-styles.scss';

export const NotesView = ({ openNotes, setOpenNotes }) => {
  const { whiteBoardSocket } = useSocket();
  const { emitEventAnimateNotes } = useEventAnimateNotes(whiteBoardSocket);
  const { onChangeTool } = useWhiteBoard();

  const [classWhiteboard, setClassWhiteboard] = useState('');
  const [classWhiteboardFrame, setClassWhiteboardFrame] = useState('whiteboard-frame');
  const [classDraggable, setClassDraggabke] = useState('whiteboard-draggable active');
  const [classWhiteboardContain, setClassWhiteboardContain] = useState(
    'whiteboard-container-notes'
  );

  const handleDragEnd = event => {
    const destination = event.destination;
    setClassWhiteboardFrame('whiteboard-frame');
    setClassDraggabke('whiteboard-draggable active');

    if (!destination) return;

    if (destination.index !== event.source.index) {
      setClassWhiteboard('whiteboard-notes motionActived');
      setTimeout(() => setClassWhiteboard('whiteboard-notes'), [1000]);
      emitEventAnimateNotes({}, resp => {
        const { activeAnimation } = resp;
        if (activeAnimation)
          setClassWhiteboard('whiteboard-notes whiteboard-notes--open');
        else setClassWhiteboard('whiteboard-notes whiteboard-notes--close');
      });
    }
  };

  const handleDragStart = () => {
    setClassWhiteboardFrame('whiteboard-frame active');
    setClassDraggabke('whiteboard-draggable');
    onChangeTool(TOOLS.HAND);
  };

  return (
    <div className={classWhiteboardContain}>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Droppable
          droppableId="notes-main"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={Boolean(false)}
          isCombineEnabled={true}
        >
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="whiteboard-main"
            >
              <NotesMain
                openNotes={openNotes}
                setOpenNotes={setOpenNotes}
                setClassWhiteboardContain={setClassWhiteboardContain}
                classWhiteboard={classWhiteboard}
                setClassWhiteboard={setClassWhiteboard}
                classWhiteboardFrame={classWhiteboardFrame}
                classDraggable={classDraggable}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
