import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import React from 'react';

export default function CloseEditText({ style, testBtn, stylesTextControls }) {
  const { action } = useConfigText();

  return (
    <React.Fragment>
      <div
        className={`
          ${testBtn} 
          ${style} 
          ${stylesTextControls}
          `}
        onClick={action.handleEditTextMode}
      >
        X
      </div>
    </React.Fragment>
  );
}
