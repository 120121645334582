import { gql } from '@apollo/client';

export const GET_LEVEL = gql`
  query level($id: ID!) {
    getDigitalBookLevel(id: $id) {
      id
      digitalBookId
      numeration
      slides {
        items {
          createdAt
          digitalBookLevelId
          updatedAt
          order
          id
          shapes {
            items {
              createdAt
              digitalBookLevelId
              digitalBookSlideId
              shapeId
              style
              updatedAt
              zIndex
            }
          }
        }
      }
    }
  }
`;

export const GET_LIST_SLIDES_LENGTH_BY_LEVEL = gql`
  query listDigitalBookLevels($digitalBookId: ID) {
    listDigitalBookLevels(filter: { digitalBookId: { eq: $digitalBookId } }) {
      items {
        id
        levelName
        slides {
          items {
            id
          }
        }
      }
    }
  }
`;

export const GET_LIST_BOOKS_OTHERS = gql`
  query listDigitalBooks {
    listDigitalBooks(filter: { main: { eq: false } }) {
      items {
        id
        bookName
        levels {
          items {
            id
          }
        }
      }
    }
  }
`;

export const GET_LIST_BOOK_MAIN = gql`
  query listDigitalBooks {
    listDigitalBooks(filter: { main: { eq: true } }) {
      items {
        id
      }
    }
  }
`;

export const GET_LIST_LEVELS_BY_BOOK = gql`
  query listLevelsByBook($id: ID!) {
    listDigitalBookLevels(filter: { digitalBookId: { eq: $id } }) {
      items {
        digitalBookId
        id
        levelName
      }
    }
  }
`;

export const GET_LIST_SLIDES = gql`
  query listSlides($digitalBookLevelId: ID!) {
    listDigitalBookSlides(filter: { digitalBookLevelId: { eq: $digitalBookLevelId } }) {
      items {
        shapes(sortDirection: DESC) {
          items {
            shapeId
            createdAt
            digitalBookLevelId
            digitalBookSlideId
            style
            updatedAt
            zIndex
          }
        }
        id
        digitalBookLevelId
      }
    }
  }
`;

export const GET_LIST_ONLY_SLIDES = gql`
  query listOnlySlides($digitalBookLevelId: ID!) {
    listDigitalBookSlides(filter: { digitalBookLevelId: { eq: $digitalBookLevelId } }) {
      items {
        id
      }
    }
  }
`;

export const GET_LIST_ONLY_SHAPES = gql`
  query listOnlyShapes($digitalBookLevelId: ID!) {
    listDigitalBookShapes(filter: { digitalBookLevelId: { eq: $digitalBookLevelId } }) {
      items {
        id
      }
    }
  }
`;

export const GET_ENGLISH_LEVELS = gql`
  query gettingLevels {
    listStudentsLevels {
      items {
        content
        id
      }
    }
  }
`;

export const GET_USER_STUDY_PROGRESS = gql`
  query gettingLevels($email: String!) {
    listUserStudyProgresses(filter: { owner: { eq: $email } }) {
      items {
        id
        lastSlideID
        level
        name
        owner
        progress
      }
    }
  }
`;

// slides functionality

export const GET_SLIDES_BY_LEVEL = gql`
  query gettingSlideByLevel($id: ID!) {
    listStudentsLevels(id: $id) {
      items {
        id
        slides {
          items {
            id
            exportedImage
            minCalification
            realCalification
            studentsLevelsSlidesId
            numberToSort
            createdAt
            updatedAt
            texts {
              items {
                text
                y
                x
                width
                updatedAt
                learningContentSlideTextsId
                id
                height
                createdAt
                styles
              }
            }
            images {
              items {
                imageUrl
                y
                x
                width
                learningContentSlideImagesId
                id
                height
                updatedAt
                createdAt
                styles
              }
            }
          }
        }
      }
    }
  }
`;
