import { useQuery } from '@apollo/client';
import {
  GET_LIST_BOOKS_OTHERS,
  GET_LIST_BOOK_MAIN,
  GET_LIST_LEVELS_BY_BOOK
} from 'components/DigitalBook/graphQL/queries';

export const useFetchLevels = id => {
  const { data, refetch } = useQuery(GET_LIST_LEVELS_BY_BOOK, { variables: { id } });
  const dataResponse = data?.listDigitalBookLevels?.items || [];
  return { data: dataResponse, refetch };
};

export const useFetchMainBook = () => {
  const { data: mainBook } = useQuery(GET_LIST_BOOK_MAIN);
  return { id: mainBook?.listDigitalBooks?.items[0].id };
};

export const useFetchOtherBooks = () => {
  const { data } = useQuery(GET_LIST_BOOKS_OTHERS);
  const dataResponse = data?.listDigitalBooks?.items || [];
  return { data: dataResponse };
};
