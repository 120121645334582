import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback
} from 'react';
import { v4 as uuid } from 'uuid';
import { elementEnum, shapeEnum } from '../components/Slide/slide-types';
import lodash from 'lodash';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_LEVEL,
  GET_LIST_ONLY_SHAPES,
  GET_LIST_ONLY_SLIDES,
  GET_LIST_SLIDES_LENGTH_BY_LEVEL
} from '../graphQL/queries';
import {
  CREATE_SHAPE,
  CREATE_SLIDE,
  DELETE_SHAPE,
  DELETE_SLIDE,
  UPDATE_NUMERATION_LEVEL
} from '../graphQL/mutations';
import { S3Service } from 'services/S3.service';
import {
  convertUrlToFile,
  sortOnlyShapesByZIndex
} from '../components/Slide/slide-functions';

const s3Service = new S3Service('public');

export const SlideContext = createContext(null);

const defaultSlidePattern = {
  id: uuid(),
  order: 1,
  shapes: []
};

const defaultDrawMenu = {
  element: elementEnum.NONE,
  isOpen: false,
  x: 0,
  y: 0
};

const defaultModiHistory = {
  actualPosition: -1,
  history: []
};

const defaultPropsLevel = {
  numeration: 0,
  digitalBookId: '',
  viewSlide: false,
  slideIndex: 0,
  width: 1344,
  height: 752,
  scale: 1
};

const defaultLoader = {
  message: '',
  load: false
};

const defaultWhiteboardSize = {
  small: {
    width: 0,
    height: 0,
    scale: 0.05
  },
  normal: {
    width: 0,
    height: 0,
    scale: 0.95
  },
  large: {
    width: 0,
    height: 0,
    scale: 1
  },
  full: {
    width: 0,
    height: 0,
    scale: 0.15
  }
};

export const SlidesProvider = ({ children }) => {
  const [modificationHistory, setModificationHistory] = useState(defaultModiHistory);
  const [whiteboardSize, setWhiteboardSize] = useState(defaultWhiteboardSize);
  const [currentDraft, setCurrentDraft] = useState([defaultSlidePattern]);
  const [propsLevel, setPropsLevel] = useState(defaultPropsLevel);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [shapeMenu, setShapeMenu] = useState(defaultDrawMenu);
  const [selectIdShape, setSelectIdShape] = useState(null);
  const [copiedElement, setCopiedElement] = useState(null);
  const [loader, setLoader] = useState(defaultLoader);
  const [editMode, setEditMode] = useState(false);

  const { level } = useParams();
  const { refetch: fetchLevel } = useQuery(GET_LEVEL, { variables: { id: level } });
  const { refetch: fetchListOnlySlides } = useQuery(GET_LIST_ONLY_SLIDES, {
    variables: { digitalBookLevelId: level }
  });
  const { refetch: fetchListOnlyShapes } = useQuery(GET_LIST_ONLY_SHAPES, {
    variables: { digitalBookLevelId: level }
  });
  const { refetch: fetchListLengthByLevel } = useQuery(GET_LIST_SLIDES_LENGTH_BY_LEVEL, {
    variables: { digitalBookId: propsLevel.digitalBookId }
  });

  const [createSlide] = useMutation(CREATE_SLIDE);
  const [createShape] = useMutation(CREATE_SHAPE);
  const [deleteSlide] = useMutation(DELETE_SLIDE);
  const [deleteShape] = useMutation(DELETE_SHAPE);
  const [updateLevel] = useMutation(UPDATE_NUMERATION_LEVEL);

  const handleEditSlideMode = () => setEditMode(!editMode);

  const handleOpenMenu = (event, props) => {
    event.evt.preventDefault();
    const objConfig = {
      ...props,
      isOpen: true,
      x: event.evt.offsetX,
      y: event.evt.offsetY
    };
    setShapeMenu(shapeMenu => ({ ...shapeMenu, ...objConfig }));
  };

  const handleSaveHistory = useCallback(
    (draft = currentDraft) => {
      setModificationHistory(modificationHistory => {
        const { actualPosition, history } = modificationHistory;
        let returnChanged;
        if (actualPosition === history.length - 1) {
          returnChanged = {
            actualPosition: actualPosition + 1,
            history: [
              ...history,
              {
                idSelectSlide: selectedSlideIndex,
                draft: lodash.cloneDeep(draft)
              }
            ]
          };
        } else {
          const arrHistory = lodash.cloneDeep(history)?.slice(0, actualPosition + 1);
          returnChanged = {
            actualPosition: arrHistory.length,
            history: [
              ...arrHistory,
              {
                idSelectSlide: selectedSlideIndex,
                draft: lodash.cloneDeep(draft)
              }
            ]
          };
        }
        return returnChanged;
      });
    },
    [modificationHistory, selectedSlideIndex]
  );

  const handleChangeShape = (id, newAttrs, saveHistory) => {
    setCurrentDraft(prevDraft => {
      const updatedDraft = [...prevDraft];
      const index = prevDraft[selectedSlideIndex].shapes.findIndex(el => el.id === id);
      if (updatedDraft[selectedSlideIndex] && updatedDraft[selectedSlideIndex].shapes) {
        const img = [...updatedDraft[selectedSlideIndex].shapes];
        if (index >= 0 && index < img.length) {
          img[index] = {
            ...img[index],
            ...newAttrs
          };
          updatedDraft[selectedSlideIndex].shapes = img;
        }
      }
      if (saveHistory) handleSaveHistory(updatedDraft);
      return updatedDraft;
    });
  };

  const handleSaveSizeBoard = useCallback((size, props) => {
    setWhiteboardSize(whiteboardSize => ({
      ...whiteboardSize,
      [size]: {
        ...whiteboardSize[size],
        ...props
      }
    }));
  }, []);

  const fetchDataDraft = async () => {
    const { data, loading } = await fetchLevel({ variables: { id: level } });
    const arrDataDraft = [...data.getDigitalBookLevel?.slides?.items];
    const sortedArray = arrDataDraft.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });

    const arrDraft = sortedArray.map(el => {
      return {
        ...el,
        shapes: sortOnlyShapesByZIndex(el.shapes.items).map(item => {
          const { style, ...props } = item;
          const { image, ...styleShape } = JSON.parse(item.style);
          return { ...props, ...styleShape, id: item.shapeId };
        })
      };
    });

    setPropsLevel(propsLevel => ({
      ...propsLevel,
      digitalBookId: data.getDigitalBookLevel.digitalBookId,
      numeration: data.getDigitalBookLevel.numeration
    }));

    setCurrentDraft(arrDraft.length ? arrDraft : [defaultSlidePattern]);
    setLoader({ load: loading, message: 'Loading...' });
  };

  // NOTE -> Refactor
  const saveDataDraft = async () => {
    try {
      const id = window.sessionStorage.getItem('user_Id');
      console.log('id', id);
      setLoader({ load: true, message: 'Saving...' });

      const listImages = await s3Service.listFileFromFolder(`digitalBook/${level}`);
      const arrImagesKey = listImages.map(({ key }) => key);

      const arrImages = currentDraft.flatMap(innerArray =>
        innerArray.shapes
          .filter(({ fileName }) => fileName)
          .map(({ id, fileName }) => `digitalBook/${level}/${id}/${fileName}`)
      );

      const arrNotCommon = arrImagesKey
        .filter(element => !arrImages.includes(element))
        .concat(arrImages.filter(element => !arrImagesKey.includes(element)));

      const deleteFilesPromises = arrNotCommon.map(
        async url => await s3Service.deleteFile(url)
      );

      const { data: listOnlySlides } = await fetchListOnlySlides({
        variables: { digitalBookLevelId: level }
      });
      const { data: listOnlyShapes } = await fetchListOnlyShapes({
        variables: { digitalBookLevelId: level }
      });

      const deleteSlidesPromises = listOnlySlides.listDigitalBookSlides.items.map(
        async ({ id }) => await deleteSlide({ variables: { id } })
      );
      const deleteShapesPromises = listOnlyShapes.listDigitalBookShapes.items.map(
        async ({ id }) => await deleteShape({ variables: { id } })
      );

      await Promise.all([
        ...deleteFilesPromises,
        ...deleteSlidesPromises,
        ...deleteShapesPromises
      ]);

      // Saving slides
      await Promise.all(
        currentDraft.map(async draft => {
          const { data } = await createSlide({
            variables: { levelId: level, order: draft.order }
          });

          // Saving shapes of each slide
          await Promise.all(
            draft.shapes.map(async shapeObj => {
              const { id, zIndex, imageUrl, file, ...style } = shapeObj;
              let variables;

              if (shapeObj.shape === shapeEnum.image) {
                let fileImage =
                  file ||
                  (await convertUrlToFile(imageUrl, `${style?.fileName}`, 'image/png'));

                const uploadS3 = await s3Service.uploadFile({
                  name: `digitalBook/${level}/${id}/${fileImage.name}`,
                  blob: fileImage
                });

                const propsStyle = {
                  ...style,
                  imageUrl: file ? uploadS3.fileUrl : imageUrl,
                  fileName: fileImage.name
                };

                variables = {
                  levelId: level,
                  slideId: data.createDigitalBookSlide.id,
                  style: JSON.stringify(propsStyle),
                  shapeId: id,
                  zIndex
                };
              }

              if (shapeObj.shape === shapeEnum.text) {
                variables = {
                  levelId: level,
                  slideId: data.createDigitalBookSlide.id,
                  style: JSON.stringify(style),
                  shapeId: id,
                  zIndex
                };
              }

              await createShape({ variables });
            })
          );
        })
      );

      const { data: dataLevels } = await fetchListLengthByLevel({
        variables: { digitalBookId: propsLevel.digitalBookId }
      });

      const arrLengthSlidesByLevel = [];

      const sortedLevels = [...dataLevels.listDigitalBookLevels.items].sort(
        (a, b) => parseInt(a.levelName) - parseInt(b.levelName)
      );

      let lengthPreviousLevel = 0;

      sortedLevels.forEach(el => {
        const objLevels = {
          length: lengthPreviousLevel,
          numeration: lengthPreviousLevel,
          id: el.id
        };

        lengthPreviousLevel += el.slides.items.length;
        arrLengthSlidesByLevel.push(objLevels);
      });
      // Update numeration of each level
      await Promise.all(
        arrLengthSlidesByLevel.map(
          async el =>
            await updateLevel({ variables: { id: el.id, numeration: el.numeration } })
        )
      );

      setLoader({ load: false, message: '' });
    } catch (error) {
      setLoader({ load: false, message: '' });
      alert('Error: Has not been saved correctly');
      console.error(error);
    }
  };

  useEffect(() => {
    handleSaveHistory();
    fetchDataDraft();
    return () => {};
  }, []);

  const value = useMemo(
    () => ({
      state: {
        propsLevel,
        currentDraft,
        editMode,
        selectedSlideIndex,
        whiteboardSize,
        selectIdShape,
        copiedElement,
        modificationHistory,
        shapeMenu,
        loader
      },

      action: {
        setCurrentDraft,
        setSelectedSlideIndex,
        setWhiteboardSize,
        setShapeMenu,
        setCopiedElement,
        setModificationHistory,
        setSelectIdShape,
        setPropsLevel,
        setLoader,

        handleChangeShape,
        handleEditSlideMode,
        handleOpenMenu,
        handleSaveHistory,
        handleSaveSizeBoard,

        saveDataDraft
      }
    }),
    [
      editMode,
      currentDraft,
      shapeMenu,
      selectedSlideIndex,
      whiteboardSize,
      selectIdShape,
      modificationHistory,
      propsLevel,
      loader
    ]
  );

  return <SlideContext.Provider value={value}>{children}</SlideContext.Provider>;
};

export const useCurrentSlide = () => {
  const context = useContext(SlideContext);
  if (!context) {
    throw new Error('review use of useCurrentSlide');
  }
  return context;
};
