import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import DeleteSlideTool from './DeleteSlideTool/DeleteSlideTool';
import AddSlideTool from './AddSlideTool/AddSlideTool';
import AddTextTool from './TextTool/AddTextTool';
import AddImageTool from './AddImageTool/AddImageTool';
import SlideViewTool from './SlideViewTool/SlideViewTool';
import DigitalBookTestTool from './DigitalBookTestTool/DigitalBookTestTool';
import ProgressGateSlideTool from './ProgressGateSlideTool/ProgressGateSlideTool';
import BoldTool from './TextControlButtons/BoldTool';
import ItalicTool from './TextControlButtons/ItalicTool';
import ColorTextTool from './TextControlButtons/ColorTextTool';
import CloseEditText from './TextControlButtons/CloseEditText';
import FontTextTool from './TextControlButtons/FontTextTool';
import DecreaseTextTool from './TextControlButtons/DecreaseTextTool';
import IncreaseTextTool from './TextControlButtons/IncreaseTextTool';
import styles from './styles.module.scss';
import UndoChangeTool from './UndoChangeTool/UndoChangeTool';
import RedoChangeTool from './RedoChangeTool/RedoChangeTool';

export default function EditSlideToolbar() {
  const { state } = useConfigText();
  return (
    <div className={styles.realTools}>
      {!state.editTextMode.openTextTool && (
        <>
          <UndoChangeTool stylesTestButton={styles.testButton} style={styles.buttons} />
          <RedoChangeTool stylesTestButton={styles.testButton} style={styles.buttons} />
          <AddSlideTool style={styles.buttons} />
          <DeleteSlideTool style={styles.buttons} />
          <AddTextTool style={styles.buttons} />
          <AddImageTool style={styles.buttons} />
          <SlideViewTool stylesTestButton={styles.testButton} style={styles.buttons} />
          <DigitalBookTestTool testBtn={styles.testButton} style={styles.buttons} />
          <ProgressGateSlideTool testBtn={styles.testButton} style={styles.buttons} />
        </>
      )}
      {state.editTextMode.openTextTool && (
        <>
          <CloseEditText
            testBtn={styles.testButton}
            stylesTextControls={styles.textControls}
            style={styles.buttons}
          />
          <FontTextTool
            testBtn={styles.testButton}
            stylesTextControls={styles.textControls}
            style={styles.buttons}
          />
          <BoldTool
            testBtn={styles.testButton}
            stylesTextControls={styles.textControls}
            style={styles.buttons}
          />
          <ItalicTool
            testBtn={styles.testButton}
            stylesTextControls={styles.textControls}
            style={styles.buttons}
          />
          <ColorTextTool
            testBtn={styles.testButton}
            stylesTextControls={styles.textControls}
            style={styles.buttons}
          />
          <DecreaseTextTool
            testBtn={styles.testButton}
            stylesTextControls={styles.textControls}
            style={styles.buttons}
          />
          <IncreaseTextTool
            testBtn={styles.testButton}
            stylesTextControls={styles.textControls}
            style={styles.buttons}
          />
        </>
      )}
    </div>
  );
}
