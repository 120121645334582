import React, { useEffect, useRef } from 'react';
import { Transformer, Text } from 'react-konva';

export default function TextKonva({
  textProps,
  isSelected,
  onTextSelect = () => {},
  onContextMenu = () => {},
  onDragStart = () => {},
  onDragEnd = () => {},
  onTransform = () => {},
  onTransformEnd = () => {}
}) {
  const textRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
      trRef.current.moveToTop();
    }
  }, [isSelected]);

  useEffect(() => {
    if (trRef.current) trRef.current.moveToTop();
  }, [textProps]);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.setZIndex(textProps.zIndex);
    }
  }, [textProps]);
  return (
    <React.Fragment>
      <Text
        {...textProps}
        ref={textRef}
        onClick={e => onTextSelect(e, textProps)}
        onTap={e => onTextSelect(e, textProps)}
        onContextMenu={onContextMenu}
        lineHeight={1.3}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onTransform={e => onTransform(e, textRef, textProps)}
        onTransformEnd={onTransformEnd}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) return oldBox;
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
}
