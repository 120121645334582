export const VoiceEvents = {
  CALLING_VOICE: 'CALLING_VOICE',
  EXIT_VOICE_CALL: 'EXIT_VOICE_CALL',
  VOICE_CALL_ACCEPTED: 'VOICE_CALL_ACCEPTED',
  VOICE_CALL_CANCELLED: 'VOICE_CALL_CANCELLED',
  MUTE_USER_MICRO_VOICE_CALL: 'MUTE_USER_MICRO_VOICE_CALL',
  SILENCE_REMOTE_VOICE_CALL: 'SILENCE_REMOTE_VOICE_CALL',
  DELETE_USER_VOICE_CALL: 'DELETE_USER_VOICE_CALL',
  GUEST_CONNECTED_VOICE_CALL: 'GUEST_CONNECTED_VOICE_CALL'
};

export const VoiceType = {
  TOKEN: 'TOKEN',
  ON_MICRO_MUTED: 'ON_MICRO_MUTED',
  ON_SILENCE_REMOTE: 'ON_SILENCE_REMOTE',
  ON_SPEAKER_MUTED: 'ON_SPEAKER_MUTED',
  STATE_LIVE_ICON: 'STATE_LIVE_ICON',
  ON_CALL: 'ON_CALL',
  INCOMING_CALL: 'INCOMING_CALL',
  SHOW_ICON: 'SHOW_ICON',
  GUEST_CONNECTED: 'GUEST_CONNECTED',
  EXIT_CALL: 'EXIT_CALL'
};

export const VoiceStateConnect = {
  CONNECTED: 'connected',
  DISCONECTED: 'disconected'
};
