import React, { useRef } from 'react';
import { ReactComponent as AddImage } from 'assets/learningContent/iconsBlue/addImage.svg';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { v4 as uuid } from 'uuid';
import { getBase64 } from 'components/DigitalBook/funtions/utils';
import { shapeEnum } from 'components/DigitalBook/components/Slide/slide-types';

function AddImageTool({ style }) {
  const { action, state } = useCurrentSlide();
  const fileInputRef = useRef(null);

  const handleUploadImage = e => {
    const image = e.target.files[0];

    getBase64(image)
      .then(imageBase64 => {
        const newDraft = [...state.currentDraft];
        newDraft[state.selectedSlideIndex].shapes = [
          ...newDraft[state.selectedSlideIndex].shapes,
          {
            id: uuid(),
            shape: shapeEnum.image,
            imageUrl: imageBase64.result,
            file: image,
            draggable: true,
            width: imageBase64.width,
            height: imageBase64.height,
            zIndex: newDraft[state.selectedSlideIndex].shapes.length + 1
          }
        ];
        action.handleSaveHistory(newDraft);
        action.setCurrentDraft(newDraft);
        fileInputRef.current.target = null;
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleFileInputChange = e => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleUploadImage(e);
    }
  };

  const openFileSelector = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />
      <AddImage onClick={openFileSelector} className={style} />
    </React.Fragment>
  );
}

export default React.memo(AddImageTool);

// {
// coords: {x: 0, y: 0},
// draftUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgA,
// draggable: true,
// height: 79.2,
// id: "c3a7204a-49b0-4fbd-a29c-359b35809b9c",
// image: img,
// imageUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgA,
// offsetX: 0,
// offsetY: 0,
// rotation: 0
// scaleX: 0.9999999999999993,
// scaleY: 1.214646464646464,
// skewX: 0,
// skewY: 0,
// width: 101.10000000000001,
// x: 66.99999999999991,
// y: 48.000000000000014
// }
