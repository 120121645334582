import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { ReactComponent as ColorBlack } from 'assets/whiteboard/black/color-palette-black.svg';

export default function ColorTextTool({ style, stylesTextControls }) {
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText, state: stateConfigText } = useConfigText();
  const handleClickOption = e =>
    actionConfigText.handleEditConfigText(
      { fill: e.target.value },
      stateCurrentSlide.selectIdShape,
      false
    );

  const handleBlur = e => {
    actionConfigText.handleEditConfigText(
      { fill: e.target.value },
      stateCurrentSlide.selectIdShape,
      true
    );
  };

  return (
    <>
      <label
        htmlFor="colorinput"
        className={`
          ${style} 
          ${stylesTextControls}
          `}
      >
        <ColorBlack style={{ width: 25 }} />
        <input
          type="color"
          id="colorinput"
          value={stateConfigText.configText.fill}
          onChange={handleClickOption}
          onBlurCapture={handleBlur}
          style={{ position: 'absolute', zIndex: -1, opacity: 0 }}
        />
      </label>
    </>
  );
}
