import { useHistory } from 'react-router-dom';
import { ListLevels } from './components/ListLevels';
import {
  useFetchLevels,
  useFetchMainBook,
  useFetchOtherBooks
} from './hooks/useRequestLevels';
import IconBack from 'assets/learningContent/iconsBlue/IconBack';
import styles from './LevelsDigitalBook.module.scss';

export default function LevelsDigitalBook() {
  const { id } = useFetchMainBook();
  const { data: dataBooks } = useFetchOtherBooks();
  const { data: dataLevels } = useFetchLevels(id);
  const { push } = useHistory();

  const handleBack = () => push('/student-profile');

  return (
    <div className={styles.container}>
      <div className={styles.backContainer} onClick={handleBack}>
        <IconBack />
      </div>
      <div className={styles.containerLevels}>
        <div className={styles.titleContainer}>
          <p>Choose your english level to go!</p>
        </div>
        <div className={styles.pageContainer}>
          <ListLevels levels={dataLevels} />

          <div className={styles.levelsContainer}>
            {dataBooks.map(el => (
              <div
                key={el.id}
                className={styles.levelBox}
                onClick={() =>
                  push('/learning-content/document/' + el.levels.items[0].id)
                }
              >
                <div className={styles.level}>
                  <span>{el.bookName.substring(0, 1)?.toUpperCase()}</span>
                </div>
                <p>{el.bookName}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.message}>
          <p>
            If you don´t know your English level, take a level test, or talk to your
            teacher to asign you one, then come back here.
          </p>
        </div>
      </div>
    </div>
  );
}
