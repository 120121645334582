import React from 'react';
import { useUserRole } from 'services/cognito.service';
import '../PublicChat.css';

const PublicWBCAvatar = ({
  avatar,
  setChangedata,
  avatarStatus,
  bellNotification,
  userId,
  color,
  isModeAdmin
}) => {
  const role = useUserRole();

  const isAdminRender = () => {
    if (avatarStatus && avatar !== '') {
      if (userId === window.sessionStorage.getItem('user_Id')) {
        return (
          <img
            className="image"
            src={avatar}
            alt="avatar"
            onClick={e => setChangedata(e)}
          />
        );
      } else {
        return (
          <img
            className="image"
            src={avatar}
            alt="avatar"
            onDoubleClick={() => bellNotification(userId)}
          />
        );
      }
    } else {
      if (userId === window.sessionStorage.getItem('user_Id')) {
        return (
          <div
            style={{
              display: 'fixed',
              margin: 'auto',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              borderRadius: '50%',
              background: color
            }}
            onClick={e => setChangedata(e)}
          ></div>
        );
      } else {
        return (
          <div
            style={{
              display: 'fixed',
              margin: 'auto',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              borderRadius: '50%',
              background: color
            }}
            onDoubleClick={() => bellNotification(userId)}
          ></div>
        );
      }
    }
  };

  const handleClick = e => {
    if (userId === window.sessionStorage.getItem('user_Id')) setChangedata(e);
  };

  const handleDoubleClick = () => {
    if (userId !== window.sessionStorage.getItem('user_Id')) bellNotification(userId);
  };

  const isUserRender = e => {
    if (avatarStatus && avatar !== '') {
      return (
        <img
          className="image"
          src={avatar}
          alt="avatar"
          onClick={e => handleClick(e)}
          onDoubleClick={() => handleDoubleClick()}
        />
      );
    } else {
      return (
        <div
          id="colorAvatar"
          style={{
            display: 'fixed',
            margin: 'auto',
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            borderRadius: '50%',
            background: color
          }}
          onClick={e => handleClick(e)}
          onDoubleClick={() => handleDoubleClick()}
        ></div>
      );
    }
  };

  return (
    <div className="avatar-container">
      {isModeAdmin ? isAdminRender : isUserRender(role)}
    </div>
  );
};

export default PublicWBCAvatar;
