import { useCallback, useEffect, useState } from 'react';
import { elementEnum } from '../slide-types';
import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { useKonva } from './useKonva';

export const useElementCurrent = () => {
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText, state: stateConfigText } = useConfigText();
  const [element, setElement] = useState(elementEnum.NONE);

  const handleChangeElement = useCallback(() => {
    if (
      stateConfigText.editTextMode.textareaPainted &&
      !stateConfigText.selectAreaDimentions.mouseDown
    ) {
      // actionKonva.handleShapeSelect(null, null);
      setElement(elementEnum.TEXT_RESIZE);
    }
    if (
      stateConfigText.editTextMode.textareaPainted &&
      stateConfigText.selectAreaDimentions.mouseDown
    ) {
      setElement(elementEnum.TEXT_PAINTED);
    }
    if (
      stateConfigText.editTextMode.openTextEditing &&
      !stateConfigText.selectAreaDimentions.mouseDown
    ) {
      setElement(elementEnum.TEXT_EDITING);
    }
    if (
      stateCurrentSlide.shapeMenu.isOpen &&
      stateCurrentSlide.shapeMenu.element === elementEnum.SHAPE_MENU_ELEMENT
    ) {
      setElement(elementEnum.SHAPE_MENU_ELEMENT);
    }
    if (
      stateCurrentSlide.shapeMenu.isOpen &&
      stateCurrentSlide.shapeMenu.element === elementEnum.SHAPE_MENU_CANVAS &&
      stateCurrentSlide.copiedElement
    ) {
      setElement(elementEnum.SHAPE_MENU_CANVAS);
      actionConfigText.setEditTextMode(editTextMode => ({
        ...editTextMode,
        openTextEditing: false
      }));
    }

    if (
      !stateCurrentSlide.shapeMenu.isOpen &&
      !stateConfigText.editTextMode.openTextEditing &&
      !stateConfigText.editTextMode.textareaPainted &&
      !stateConfigText.selectAreaDimentions.mouseDown
    ) {
      setElement(elementEnum.NONE);
      actionConfigText.setEditTextMode(editTextMode => ({
        ...editTextMode,
        openTextEditing: false
      }));
    }
  }, [
    stateConfigText.editTextMode.openTextEditing,
    stateConfigText.editTextMode.textareaPainted,
    stateConfigText.selectAreaDimentions.mouseDown,
    stateCurrentSlide.shapeMenu.isOpen,
    stateCurrentSlide.selectIdShape
  ]);

  useEffect(() => handleChangeElement(), [handleChangeElement]);

  return { element, setElement };
};
