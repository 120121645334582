import React from 'react';
import { ReactComponent as AddText } from 'assets/learningContent/iconsBlue/addText.svg';
import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';

export default function AddTextTool({ style }) {
  const { action } = useConfigText();

  return (
    <React.Fragment>
      <AddText onClick={action.handleEditTextMode} className={`${style}`} />
    </React.Fragment>
  );
}
