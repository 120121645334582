export const sortedArrayByLevelname = (data = []) => {
  return [...data].sort((a, b) => {
    const levelA = parseInt(a.levelName);
    const levelB = parseInt(b.levelName);

    if (isNaN(levelA)) return -1;
    if (isNaN(levelB)) return 1;

    return levelA - levelB;
  });
};
