import { useEffect } from 'react';
import { CREATE_FILE } from '../graphQL/mutations';
import { useMutation } from '@apollo/client';
import { FILES_BY_USERS } from '../graphQL/queries';
import { useUser } from 'components/UserManagment/UserProvider';

/**
 * creates a new file and refetches all files to update the cache
 *
 */
export const useCreateFile = () => {
  const { user } = useUser();
  const [createFile, { loading, error }] = useMutation(CREATE_FILE, {
    refetchQueries: [
      { query: FILES_BY_USERS, variables: { userId: user.attributes.sub } }
    ]
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return { createFile, loading, error };
};
