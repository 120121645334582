import { useRef, useEffect } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

export default function ImageKonva({
  shapeProps,
  isSelected,
  onImageSelect = () => {},
  onContextMenu = () => {},
  onDragStart = () => {},
  onDragEnd = () => {},
  onTransformStart = () => {},
  onTransformEnd = () => {}
}) {
  const [image] = useImage(shapeProps.imageUrl, 'anonymous', 'origin');
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    if (trRef.current) trRef.current.moveToTop();
  }, [shapeProps]);

  useEffect(() => {
    if (shapeRef.current && shapeProps) {
      shapeRef.current.setAttrs(shapeProps);
    }
  }, [shapeProps]);

  return (
    <>
      <Image
        {...shapeProps}
        ref={shapeRef}
        image={image}
        height={shapeProps.height}
        width={shapeProps.width}
        onClick={e => onImageSelect(e, shapeProps)}
        onTap={e => onImageSelect(e, shapeProps)}
        onDragEnd={e => onDragEnd(e, shapeProps)}
        onTransformStart={onTransformStart}
        onTransformEnd={onTransformEnd}
        onContextMenu={onContextMenu}
        onDragStart={onDragStart}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          // rotateEnabled={false} //se desactiva la rotacion de la imagen
        />
      )}
    </>
  );
}
