import React from 'react';
import '../Chat.css';
import ChatAvatar from '../avatar/ChatAvatar';
import moment from 'moment';

const Message = ({
  name,
  message,
  data,
  setChangedata,
  avatar,
  avatarStatus,
  textColor,
  isUser,
  ownerMode,
  bellNotification
}) => {
  const msText = textColor === 'alternado' ? 'changeColorToViolet' : 'changeColorTowhite';
  const msTextA = textColor === 'alternado' ? 'changeColorToBlue' : 'changeColorTowhite';

  return (
    <div className="message" style={{ width: '100%' }}>
      <ChatAvatar
        avatar={avatar}
        setChangedata={setChangedata}
        isUser={isUser}
        avatarStatus={avatarStatus}
        ownerMode={ownerMode}
        bellNotification={bellNotification}
      />

      <div className={isUser ? msText : msTextA}>
        <h5 className={isUser ? msText : msTextA}>{name}</h5>
        <p className={isUser ? msText : msTextA} style={{ wordWrap: 'break-word' }}>
          {message}
          <span className={isUser ? `${msText} timeD` : `${msTextA} timeD`}>
            {moment(data).format('hh:mm:A')}
          </span>
        </p>
      </div>
    </div>
  );
};

Message.defaultProps = {
  name: '',
  message: '',
  data: new Date(),
  setChangedata: () => {},
  avatar: '',
  avatarStatus: false,
  textColor: '',
  isUser: false,
  ownerMode: false,
  bellNotification: () => {}
};

export default Message;
