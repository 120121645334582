import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import { SlideViewMiniature } from '../Slide/Slide';
import { elementEnum } from '../Slide/slide-types';
import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from './styles.module.scss';

export default function ListSlides() {
  const { action: actionCurrentSlide, state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText } = useConfigText();

  const handleSlideClick = index => {
    actionCurrentSlide.setSelectedSlideIndex(index);
    actionCurrentSlide.setShapeMenu(shapeMenu => ({
      ...shapeMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));
    actionConfigText.setEditTextMode({
      openTextTool: false,
      textareaPainted: false,
      openTextEditing: false
    });
  };
  const handleDrop = droppedItem => {
    const orderSource = droppedItem.source.index;
    const orderDest = droppedItem.destination?.index;

    if (isNaN(orderDest) || isNaN(orderSource)) return;
    if (orderDest === orderSource) return;
    if (stateCurrentSlide.selectedSlideIndex === orderSource)
      actionCurrentSlide.setSelectedSlideIndex(orderDest);
    else if (
      orderSource < stateCurrentSlide.selectedSlideIndex &&
      orderDest >= stateCurrentSlide.selectedSlideIndex
    )
      actionCurrentSlide.setSelectedSlideIndex(stateCurrentSlide.selectedSlideIndex - 1);
    else if (
      orderSource > stateCurrentSlide.selectedSlideIndex &&
      orderDest <= stateCurrentSlide.selectedSlideIndex
    )
      actionCurrentSlide.setSelectedSlideIndex(stateCurrentSlide.selectedSlideIndex + 1);

    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const arrayOrdered = [...currentDraft];
      const [elementMoved] = arrayOrdered.splice(orderSource, 1);
      arrayOrdered.splice(orderDest, 0, elementMoved);

      arrayOrdered.forEach((item, index) => {
        item.order = index + 1;
      });

      actionCurrentSlide.handleSaveHistory(arrayOrdered);
      return arrayOrdered;
    });
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId="list-container">
        {provided => (
          <div
            className={styles.containerListSlides}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {stateCurrentSlide.currentDraft.map((slide, i) => (
              <Draggable key={slide.id} draggableId={slide.id} index={i}>
                {provided => (
                  <li
                    className={styles.containerSlide}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <p>{stateCurrentSlide.propsLevel.numeration + slide.order}</p>
                    <div
                      className={
                        stateCurrentSlide.selectedSlideIndex === i
                          ? styles.selectSlide
                          : styles.noSelectSlide
                      }
                      onClick={() => handleSlideClick(i)}
                    >
                      <SlideViewMiniature slide={slide} styleInput={styles.styleInput} />
                    </div>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
