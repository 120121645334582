import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import React from 'react';

export default function ItalicTool({ style, testBtn, stylesTextControls }) {
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText, state: stateConfigText } = useConfigText();
  const validateStyleIfExist = text => {
    if (text.split(' ')?.includes('italic')) return text.replace('italic', '').trim();
    else return `${text} italic`.trim();
  };

  const handleClickOption = () => {
    actionConfigText.handleEditConfigText(
      { fontStyle: validateStyleIfExist(stateConfigText.configText.fontStyle) },
      stateCurrentSlide.selectIdShape,
      true
    );
  };

  return (
    <React.Fragment>
      <div
        onClick={handleClickOption}
        className={`
            ${testBtn} 
            ${style} 
            ${stylesTextControls}
          `}
      >
        <p style={{ fontSize: 16 }}>
          <i>i</i>
        </p>
      </div>
    </React.Fragment>
  );
}
