export const objList = [
  {
    id: 'tab_1',
    value: 'select',
    label: 'Select',
    select: false,
    list: [
      {
        select: false,
        value: 'profile',
        label: 'Profile'
      },
      {
        select: false,
        value: 'book',
        label: 'Book',
        list: []
      },
      {
        select: false,
        value: 'classroom',
        label: 'Classroom',
        list: []
      }
    ]
  },
  {
    id: 'tab_2',
    value: 'select',
    label: 'Select',
    select: false,
    list: [
      {
        select: false,
        value: 'teacher',
        label: 'Teacher',
        list: []
      },
      {
        select: false,
        value: 'student',
        label: 'Student',
        list: []
      }
    ]
  },
  {
    id: 'tab_3',
    value: 'select',
    label: 'Select',
    select: false,
    list: [
      {
        select: false,
        value: 'incognito',
        label: 'Incognito'
      },
      {
        select: false,
        value: 'public',
        label: 'public'
      }
    ]
  }
];
