import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { sortedArrayByLevelname } from '../levels-functions';
import styles from '../LevelsDigitalBook.module.scss';

export const ListLevels = ({ levels }) => {
  const { push } = useHistory();

  return (
    <div className={styles.levelsContainer}>
      {sortedArrayByLevelname(levels).map(level => (
        <div
          key={level.id}
          onClick={() => push('/learning-content/document/' + level.id)}
          className={styles.levelBox}
        >
          <div className={styles.level}>
            <p>{level.levelName}</p>
          </div>
          <p>Level</p>
        </div>
      ))}
    </div>
  );
};
