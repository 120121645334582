import React from 'react';
import { ReactComponent as SlideViewButton } from 'assets/learningContent/iconsBlue/viewSlideIcon.svg';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';

export default function SlideViewTool({ style, stylesTestButton }) {
  const { state, action } = useCurrentSlide();

  const handleSlideView = () => {
    action.setPropsLevel(propsLevel => ({
      ...propsLevel,
      viewSlide: true,
      slideIndex: state.selectedSlideIndex
    }));

    action.handleEditSlideMode();
  };

  return (
    <React.Fragment>
      <div
        className={`${stylesTestButton} ${style}`}
        style={{ borderColor: 'transparent' }}
        onClick={handleSlideView}
      >
        <SlideViewButton
          style={{ width: '40px', height: 'auto', transform: 'scale(1.25)' }}
        />
      </div>
    </React.Fragment>
  );
}
