import { IMG_UPLOAD_KEYS, USER_ROLES } from 'enums/constants.enum';
import React from 'react';

const SaveBranakOptions = ({
  isLoading,
  saveBranak,
  setScoreForm,
  userRole,
  closeModal
}) => {
  return (
    <div className="saving-image-branak-from-gallery">
      <div className="btn-container" style={{ flexDirection: 'column' }}>
        <button
          className="saving-image-branak-from-gallery-btns"
          style={{
            marginBottom: '10px'
          }}
          onClick={() =>
            saveBranak(
              'replace',
              window.sessionStorage.getItem('storedUploadedImage') !== 'false'
                ? true
                : false
            )
          }
          disabled={isLoading}
        >
          <p>Replace existent</p>
        </button>
        <button
          className="saving-image-branak-from-gallery-btns"
          onClick={() => {
            saveBranak('newFromDevice');
          }}
          disabled={isLoading}
        >
          <p>Keep both</p>
        </button>
        {userRole === USER_ROLES.STUDENTS ? (
          ''
        ) : (
          <button
            className="saving-image-branak-from-gallery-btns"
            style={{
              marginBottom: '10px'
            }}
            onClick={() => setScoreForm(true)}
            disabled={isLoading}
          >
            <p>Save as practice</p>
          </button>
        )}
        <button
          className="saving-image-branak-from-gallery-btns"
          onClick={() => {
            closeModal();
          }}
          disabled={isLoading}
        >
          <p>Cancel</p>
        </button>
      </div>
    </div>
  );
};

export default SaveBranakOptions;
