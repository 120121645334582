import React from 'react';
import './styles/evaluationsTable.css';

const EvaluationsTable = ({ ondeData }) => {
  return (
    <ul key={ondeData.no} className="evaluationsTable__ul">
      <li className="evaluationsTable__li-date">{ondeData.date}</li>
      <li className="evaluationsTable__li-no">{ondeData.no}</li>
      <li className="evaluationsTable__li-name">{ondeData.name}</li>
      <li className="evaluationsTable__li-process">{ondeData.process}</li>
      <li className="evaluationsTable__li-score">{ondeData.score}</li>
    </ul>
  );
};

export default EvaluationsTable;
