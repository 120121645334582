import { useEffect, useState } from 'react';
import { ButtonActions } from './components/ButtonActions';
import { USER_ROLES } from 'enums/constants.enum';
import { isUserAdmin, useUserRole } from 'services/cognito.service';
import { useUserClassRoom } from 'components/UserManagment/hooks/useUserClassRoom';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ButtonsBelongsAdmin, ButtonsNotBelongsAdmin } from './components/ButtonAdmin';
import './styles.scss';
import { useMutation } from '@apollo/client';
import { TEACHER_VISITING_CLASSROOM } from 'components/Whiteboard/graphQL/mutations';
import { useUser } from 'components/UserManagment/UserProvider';

export const AdminVisits = () => {
  const { location } = useHistory();
  const { assignedClassRoom } = useUserClassRoom();
  const { user } = useUser();
  const { userId } = useParams();
  const [isActive, setIsActive] = useState(false);
  const [teacherVisitingClassroomMutation] = useMutation(TEACHER_VISITING_CLASSROOM);
  const paramPathname = location.pathname.split('/')[2];
  const belongIdClassroom =
    paramPathname === assignedClassRoom.classRoomId && !userId ? 'BELONG' : 'NOT_BELONG';
  const role = useUserRole();

  const VIEW_BY_ROLES = {
    [USER_ROLES.ADMINS]: <ButtonActions />,
    [USER_ROLES.TEACHERS]: <></>
  };

  const VIEW_BOTTOMS = {
    NOT_BELONG: <ButtonsNotBelongsAdmin assignedClassRoom={assignedClassRoom} />,
    BELONG: <ButtonsBelongsAdmin setIsActive={setIsActive} />
  };

  const updateClassroomMatchVisiting = async () => {
    if (!(await isUserAdmin())) return;
    if (!assignedClassRoom.classRoomId) return;

    if (assignedClassRoom.classRoomId === paramPathname)
      return await teacherVisitingClassroomMutation({
        variables: {
          teacherId: user.attributes?.sub,
          classRoomIdVisiting: 'UNVISITED CLASSROOM'
        }
      });

    if (assignedClassRoom.classRoomIdVisiting !== paramPathname)
      return await teacherVisitingClassroomMutation({
        variables: { teacherId: user.attributes?.sub, classRoomIdVisiting: paramPathname }
      });
  };

  useEffect(() => {
    updateClassroomMatchVisiting();
    return () => {};
  }, []);

  return (
    <div className="whiteborard-admin-visits">
      {role === USER_ROLES.ADMINS && VIEW_BOTTOMS[belongIdClassroom]}
      {isActive && VIEW_BY_ROLES[role]}
    </div>
  );
};
