import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import styles from './styles.module.scss';
import { useShapeMenu } from 'components/DigitalBook/hooks/useShapeMenu';

export const ShapeMenu = () => {
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionShapeMenu } = useShapeMenu();
  const styleShapeMenu = {
    top: stateCurrentSlide.shapeMenu.y,
    left: stateCurrentSlide.shapeMenu.x
  };

  return (
    <div
      onContextMenu={e => e.preventDefault()}
      className={styles.menuWhiteboard}
      style={styleShapeMenu}
    >
      <ul>
        <li onClick={actionShapeMenu.handleRemoveElement}>Remove element</li>
        <li onClick={actionShapeMenu.handleMoveToFront}>Move to front</li>
        <li onClick={actionShapeMenu.handleMoveOneFordward}>Move one forward</li>
        <li onClick={actionShapeMenu.handleMoveBack}>Move back</li>
        <li onClick={actionShapeMenu.handleMoveOneBack}>Move one back</li>
        <li onClick={actionShapeMenu.handleCopyElement}>Copy element</li>
        {stateCurrentSlide.copiedElement && (
          <li onClick={actionShapeMenu.handlePasteElement}>Paste Element</li>
        )}
      </ul>
    </div>
  );
};

export const CanvasMenu = () => {
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionShapeMenu } = useShapeMenu();
  const styleShapeMenu = {
    top: stateCurrentSlide.shapeMenu.y,
    left: stateCurrentSlide.shapeMenu.x
  };

  return (
    <div
      onContextMenu={e => e.preventDefault()}
      className={styles.menuWhiteboard}
      style={styleShapeMenu}
    >
      <ul>
        <li onClick={actionShapeMenu.handlePasteElement}>Paste Element</li>
      </ul>
    </div>
  );
};
