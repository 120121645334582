import { USER_ROLES } from 'enums/constants.enum';
import moment from 'moment-timezone';

const date = moment().tz('America/Santo_Domingo');
const dateMilisecond = date.valueOf();

export const filterNotesLastDays = (days, data) => {
  if (data === undefined) {
    // Si data es undefined, devolver un arreglo vacío
    return [];
  }
  const milliseconds = days * 24 * 60 * 60 * 1000;
  const lastDays = dateMilisecond - milliseconds;
  const notesLastDays = data.filter(message => parseInt(message.date) >= lastDays);

  return notesLastDays;
};

export const filterNotesByUser = (data, role, userId) => {
  return data.filter(el => {
    return el.createBy === userId
      ? true
      : el.role === role
      ? true
      : el.role === userId
      ? true
      : el.role === 'ALL'
      ? role === USER_ROLES.ADMINS && el.role !== 'default'
      : false;
  });
};
