import React from 'react';
import '../Chat.css';

const ChatAvatar = ({
  ownerMode,
  avatarStatus,
  avatar,
  isUser,
  bellNotification,
  setChangedata
}) => {
  const isAdminRender = () => {
    if (avatarStatus && avatar) {
      if (isUser) {
        return (
          <img className="image" onClick={bellNotification} src={avatar} alt="avatar" />
        );
      } else {
        return (
          <img className="image" onClick={setChangedata} src={avatar} alt="avatar" />
        );
      }
    } else {
      if (isUser) {
        return <div className="avatarUser" onClick={bellNotification}></div>;
      } else {
        return <div className="avatar" onClick={setChangedata}></div>;
      }
    }
  };

  const isUserRender = () => {
    if (avatarStatus && avatar) {
      if (isUser) {
        return (
          <img className="image" onClick={setChangedata} src={avatar} alt="avatar" />
        );
      } else {
        return <img className="image" src={avatar} alt="avatar" />;
      }
    } else {
      if (isUser) {
        return <div className="avatarUser" onClick={setChangedata}></div>;
      } else {
        return <div className="avatar"></div>;
      }
    }
  };

  return (
    <div className="avatar-container">{ownerMode ? isAdminRender() : isUserRender()}</div>
  );
};

ChatAvatar.defaultProps = {
  ownerMode: false,
  avatarStatus: false,
  avatar: '',
  isUser: false,
  bellNotification: () => {},
  setChangedata: () => {}
};

export default ChatAvatar;
