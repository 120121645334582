import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useCurrentSlide } from './useCurrentSlide';
import {
  calcDimensionsText,
  returnShapeFinded
} from '../components/Slide/slide-functions';
import { elementEnum, shapeEnum } from '../components/Slide/slide-types';

const ConfigTextContext = createContext(null);
const defaultConfigText = {
  fontStyle: '',
  fill: '#000',
  fontSize: 16,
  text: '',
  fontFamily: 'Arial',
  rotation: 0
};
const defaultEditTextMode = {
  openTextTool: false,
  textareaPainted: false,
  openTextEditing: false
};
const defaultTextareaDimentions = {
  firstPosition: null,
  lastPosition: null,
  x: 0,
  y: 0,
  rotation: 0
};

export const ConfigTextProvider = ({ children }) => {
  const [editTextMode, setEditTextMode] = useState(defaultEditTextMode);
  const [configText, setConfigText] = useState(defaultConfigText);
  const [selectAreaDimentions, setSelectAreaDimentions] = useState(
    defaultTextareaDimentions
  );
  const { state: stateCurrentSlide, action: actionCurrentSlide } = useCurrentSlide();

  const handleEditConfigText = (config, selectedId, saveHistory) => {
    const updateSelectedText = (shapes, selectedId, config) =>
      shapes.map(txt => (txt.id === selectedId ? { ...txt, ...config } : txt));

    if (selectedId) {
      actionCurrentSlide.setCurrentDraft(currentDraft => {
        const arrTextEdit = currentDraft.map((el, index) =>
          stateCurrentSlide.selectedSlideIndex === index
            ? { ...el, shapes: updateSelectedText(el.shapes, selectedId, config) }
            : el
        );
        if (saveHistory) actionCurrentSlide.handleSaveHistory(arrTextEdit);
        return arrTextEdit;
      });
    }
    setConfigText(configText => ({ ...configText, ...config }));
  };

  const handleEditTextMode = () => {
    setEditTextMode(editTextMode => ({
      editTextMode: false,
      openTextEditing: false,
      openTextTool: !editTextMode.openTextTool
    }));
    if (!stateCurrentSlide.selectIdShape) setConfigText(defaultConfigText);
  };

  const handleDblClickText = e => {
    if (e.evt.button !== 0) return;
    setEditTextMode(editText => ({
      ...editText,
      openTextEditing: true,
      textareaPainted: false
    }));
    setConfigText({ ...e.target.attrs });
  };

  const handleChangeTextEdit = e => {
    setConfigText(configText => ({ ...configText, text: e.target.value }));
    actionCurrentSlide.setCurrentDraft(currentDraft => {
      const arrDraft = currentDraft;
      arrDraft[stateCurrentSlide.selectedSlideIndex] = {
        ...arrDraft[stateCurrentSlide.selectedSlideIndex],
        shapes: arrDraft[stateCurrentSlide.selectedSlideIndex].shapes.map(el => {
          if (el.id === stateCurrentSlide.selectIdShape)
            return { ...el, text: e.target.value };
          else return el;
        })
      };
      return [...arrDraft];
    });
  };

  const afterSelection = useCallback(
    (target, lastPosition, firstPosition) => {
      if (target !== 'text') return;
      const newTextDimentions = calcDimensionsText(
        firstPosition,
        lastPosition,
        'firstPosition'
      );

      setEditTextMode(editTextMode => ({ ...editTextMode, textareaPainted: true }));
      setSelectAreaDimentions(selectAreaDimentions => ({
        ...selectAreaDimentions,
        ...newTextDimentions,
        mouseDown: false,
        mouseUp: false
      }));
    },
    [selectAreaDimentions]
  );

  const listenBeginTextSelect = event => {
    const { offsetX: x, offsetY: y } = event.evt;
    const firstPosition = { x, y };
    const shapedFinded = returnShapeFinded(
      stateCurrentSlide.currentDraft[stateCurrentSlide.selectedSlideIndex].shapes,
      stateCurrentSlide.selectIdShape
    );

    if (event.evt.button !== 0) return; // Valid when right clicked
    if (!editTextMode.openTextTool) return;

    if (shapedFinded?.shape === shapeEnum.text) {
      setEditTextMode(editTextMode => ({
        ...editTextMode,
        textareaPainted: false,
        openTextEditing: false
      }));

      actionCurrentSlide.setShapeMenu({
        element: elementEnum.NONE,
        isOpen: false,
        x: 0,
        y: 0
      });

      // actionCurrentSlide.setSelectIdShape(null);

      return;
    }

    setSelectAreaDimentions(selectAreaDimentions => ({
      ...selectAreaDimentions,
      mouseDown: true,
      lastPosition: null,
      movePosition: firstPosition,
      firstPosition,
      rotation: 0,
      x: 0,
      y: 0
    }));

    setConfigText(configText => ({
      ...configText,
      rotation: 0,
      text: ''
    }));

    setEditTextMode(editTextMode => ({
      ...editTextMode,
      textareaPainted: false
    }));

    actionCurrentSlide.setShapeMenu(shapeMenu => ({
      ...shapeMenu,
      isOpen: false,
      element: elementEnum.NONE
    }));
  };

  const listenMoveTextSelect = event => {
    if (
      event.target.tagName.toLowerCase() !== 'canvas' ||
      !editTextMode.openTextTool ||
      !selectAreaDimentions.mouseDown
    )
      return;

    if (!selectAreaDimentions.mouseDown) {
      setSelectAreaDimentions(selectAreaDimentions => ({
        ...selectAreaDimentions,
        mouseDown: false
      }));
      return event.preventDefault();
    }

    const { offsetX: x, offsetY: y } = event;
    const { firstPosition } = selectAreaDimentions;
    const lastPosition = { x, y };
    const newTextDimentions = calcDimensionsText(
      firstPosition,
      lastPosition,
      'movePosition'
    );
    setEditTextMode(editTextMode => ({ ...editTextMode, textareaPainted: true }));
    setSelectAreaDimentions(selectAreaDimentions => ({
      ...selectAreaDimentions,
      ...newTextDimentions
    }));
  };

  const listenFinishTextSelect = (event, target) => {
    const { firstPosition } = selectAreaDimentions;
    const lastPosition = { x: event?.offsetX, y: event?.offsetY };
    if (!editTextMode.openTextTool || !selectAreaDimentions.mouseDown) return;
    if (firstPosition?.x === lastPosition?.x && firstPosition?.y === lastPosition?.y) {
      setEditTextMode(editTextMode => ({ ...editTextMode, textareaPainted: false }));
      setSelectAreaDimentions(selectAreaDimentions => ({
        ...selectAreaDimentions,
        lastPosition: null,
        mouseDown: false,
        mouseUp: false
      }));
      return;
    }
    if (event.target.tagName.toLowerCase() !== 'canvas') {
      if (selectAreaDimentions.mouseDown) {
        setSelectAreaDimentions(selectAreaDimentions => ({
          ...selectAreaDimentions,
          x: 50,
          y: 50,
          mouseDown: false,
          mouseUp: false
        }));
        setEditTextMode(editTextMode => ({ ...editTextMode, textareaPainted: true }));
        return event.preventDefault();
      }
      return event.preventDefault();
    }

    afterSelection(target, lastPosition, firstPosition);
  };

  const beginAndFinishSelection = useCallback(
    (e, type, target) => {
      if (type === 'begin') listenBeginTextSelect(e);
      if (type === 'move') listenMoveTextSelect(e);
      if (type === 'finish') listenFinishTextSelect(e, target);
    },
    [selectAreaDimentions, editTextMode, stateCurrentSlide.selectIdShape, configText]
  );

  const value = useMemo(
    () => ({
      state: {
        editTextMode,
        configText,
        selectAreaDimentions
      },
      action: {
        setConfigText,
        setEditTextMode,
        setSelectAreaDimentions,
        handleEditTextMode,
        handleEditConfigText,
        handleDblClickText,
        handleChangeTextEdit,
        beginAndFinishSelection
      }
    }),
    [configText, editTextMode, stateCurrentSlide, selectAreaDimentions, stateCurrentSlide]
  );

  return (
    <ConfigTextContext.Provider value={value}>{children}</ConfigTextContext.Provider>
  );
};

export const useConfigText = () => {
  const context = useContext(ConfigTextContext);
  return context;
};
