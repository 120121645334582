export const stylesTextResize = configText => ({
  fontSize: `${configText.fontSize}px`,
  fontWeight: configText.fontStyle?.includes('bold') && 'bold',
  fontStyle: configText.fontStyle?.includes('italic') && 'italic',
  fontFamily: configText.fontFamily,
  color: configText.fill,
  position: 'absolute'
});

export const stylesTextEditing = configText => ({
  fontSize: `${configText.fontSize}px`,
  fontWeight: configText.fontStyle?.includes('bold') && 'bold',
  fontStyle: configText.fontStyle?.includes('italic') && 'italic',
  fontFamily: configText.fontFamily,
  caretColor: configText.fill
});
