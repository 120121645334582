/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWhiteBoardEvent = /* GraphQL */ `
  mutation CreateWhiteBoardEvent(
    $input: CreateWhiteBoardEventInput!
    $condition: ModelWhiteBoardEventConditionInput
  ) {
    createWhiteBoardEvent(input: $input, condition: $condition) {
      id
      classRoomId
      page
      updatedAt
      payload
      createdAt
      __typename
    }
  }
`;
export const updateWhiteBoardEvent = /* GraphQL */ `
  mutation UpdateWhiteBoardEvent(
    $input: UpdateWhiteBoardEventInput!
    $condition: ModelWhiteBoardEventConditionInput
  ) {
    updateWhiteBoardEvent(input: $input, condition: $condition) {
      id
      classRoomId
      page
      updatedAt
      payload
      createdAt
      __typename
    }
  }
`;
export const deleteWhiteBoardEvent = /* GraphQL */ `
  mutation DeleteWhiteBoardEvent(
    $input: DeleteWhiteBoardEventInput!
    $condition: ModelWhiteBoardEventConditionInput
  ) {
    deleteWhiteBoardEvent(input: $input, condition: $condition) {
      id
      classRoomId
      page
      updatedAt
      payload
      createdAt
      __typename
    }
  }
`;
export const createClassRoom = /* GraphQL */ `
  mutation CreateClassRoom(
    $input: CreateClassRoomInput!
    $condition: ModelClassRoomConditionInput
  ) {
    createClassRoom(input: $input, condition: $condition) {
      id
      name
      page
      backgroundImageUrl
      iconsColor
      blur
      isDisabled
      isCrystalTheme
      teachers {
        nextToken
        __typename
      }
      students {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClassRoom = /* GraphQL */ `
  mutation UpdateClassRoom(
    $input: UpdateClassRoomInput!
    $condition: ModelClassRoomConditionInput
  ) {
    updateClassRoom(input: $input, condition: $condition) {
      id
      name
      page
      backgroundImageUrl
      iconsColor
      blur
      isDisabled
      isCrystalTheme
      teachers {
        nextToken
        __typename
      }
      students {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClassRoom = /* GraphQL */ `
  mutation DeleteClassRoom(
    $input: DeleteClassRoomInput!
    $condition: ModelClassRoomConditionInput
  ) {
    deleteClassRoom(input: $input, condition: $condition) {
      id
      name
      page
      backgroundImageUrl
      iconsColor
      blur
      isDisabled
      isCrystalTheme
      teachers {
        nextToken
        __typename
      }
      students {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      profilePicture
      files {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      profilePicture
      files {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      profilePicture
      files {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTeacher = /* GraphQL */ `
  mutation CreateTeacher(
    $input: CreateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    createTeacher(input: $input, condition: $condition) {
      id
      classRoomId
      classRoom {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      classRoomIdVisiting
      classRoomVisiting {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        email
        profilePicture
        createdAt
        updatedAt
        __typename
      }
      students {
        nextToken
        __typename
      }
      callingState
      callingInit
      callingBlock
      callingOneBlock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeacher = /* GraphQL */ `
  mutation UpdateTeacher(
    $input: UpdateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    updateTeacher(input: $input, condition: $condition) {
      id
      classRoomId
      classRoom {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      classRoomIdVisiting
      classRoomVisiting {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        email
        profilePicture
        createdAt
        updatedAt
        __typename
      }
      students {
        nextToken
        __typename
      }
      callingState
      callingInit
      callingBlock
      callingOneBlock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeacher = /* GraphQL */ `
  mutation DeleteTeacher(
    $input: DeleteTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    deleteTeacher(input: $input, condition: $condition) {
      id
      classRoomId
      classRoom {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      classRoomIdVisiting
      classRoomVisiting {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        email
        profilePicture
        createdAt
        updatedAt
        __typename
      }
      students {
        nextToken
        __typename
      }
      callingState
      callingInit
      callingBlock
      callingOneBlock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      classRoomId
      classRoom {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        email
        profilePicture
        createdAt
        updatedAt
        __typename
      }
      evaluations {
        nextToken
        __typename
      }
      callingState
      callingInit
      callingBlock
      callingOneBlock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      classRoomId
      classRoom {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        email
        profilePicture
        createdAt
        updatedAt
        __typename
      }
      evaluations {
        nextToken
        __typename
      }
      callingState
      callingInit
      callingBlock
      callingOneBlock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      classRoomId
      classRoom {
        id
        name
        page
        backgroundImageUrl
        iconsColor
        blur
        isDisabled
        isCrystalTheme
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        email
        profilePicture
        createdAt
        updatedAt
        __typename
      }
      evaluations {
        nextToken
        __typename
      }
      callingState
      callingInit
      callingBlock
      callingOneBlock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvaluation = /* GraphQL */ `
  mutation CreateEvaluation(
    $input: CreateEvaluationInput!
    $condition: ModelEvaluationConditionInput
  ) {
    createEvaluation(input: $input, condition: $condition) {
      id
      fileId
      studentId
      no
      name
      process
      score
      skills {
        listenning
        speaking
        writting
        reading
        __typename
      }
      date
      file {
        id
        userId
        name
        url
        mode
        currentFolder
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvaluation = /* GraphQL */ `
  mutation UpdateEvaluation(
    $input: UpdateEvaluationInput!
    $condition: ModelEvaluationConditionInput
  ) {
    updateEvaluation(input: $input, condition: $condition) {
      id
      fileId
      studentId
      no
      name
      process
      score
      skills {
        listenning
        speaking
        writting
        reading
        __typename
      }
      date
      file {
        id
        userId
        name
        url
        mode
        currentFolder
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvaluation = /* GraphQL */ `
  mutation DeleteEvaluation(
    $input: DeleteEvaluationInput!
    $condition: ModelEvaluationConditionInput
  ) {
    deleteEvaluation(input: $input, condition: $condition) {
      id
      fileId
      studentId
      no
      name
      process
      score
      skills {
        listenning
        speaking
        writting
        reading
        __typename
      }
      date
      file {
        id
        userId
        name
        url
        mode
        currentFolder
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile($input: CreateFileInput!, $condition: ModelFileConditionInput) {
    createFile(input: $input, condition: $condition) {
      id
      userId
      name
      url
      mode
      currentFolder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile($input: UpdateFileInput!, $condition: ModelFileConditionInput) {
    updateFile(input: $input, condition: $condition) {
      id
      userId
      name
      url
      mode
      currentFolder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($input: DeleteFileInput!, $condition: ModelFileConditionInput) {
    deleteFile(input: $input, condition: $condition) {
      id
      userId
      name
      url
      mode
      currentFolder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLevel = /* GraphQL */ `
  mutation CreateLevel($input: CreateLevelInput!, $condition: ModelLevelConditionInput) {
    createLevel(input: $input, condition: $condition) {
      id
      name
      studyDocumentSlides {
        nextToken
        __typename
      }
      orderArray
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLevel = /* GraphQL */ `
  mutation UpdateLevel($input: UpdateLevelInput!, $condition: ModelLevelConditionInput) {
    updateLevel(input: $input, condition: $condition) {
      id
      name
      studyDocumentSlides {
        nextToken
        __typename
      }
      orderArray
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLevel = /* GraphQL */ `
  mutation DeleteLevel($input: DeleteLevelInput!, $condition: ModelLevelConditionInput) {
    deleteLevel(input: $input, condition: $condition) {
      id
      name
      studyDocumentSlides {
        nextToken
        __typename
      }
      orderArray
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudyDocumentSlide = /* GraphQL */ `
  mutation CreateStudyDocumentSlide(
    $input: CreateStudyDocumentSlideInput!
    $condition: ModelStudyDocumentSlideConditionInput
  ) {
    createStudyDocumentSlide(input: $input, condition: $condition) {
      id
      levelId
      studyDocumentElements {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudyDocumentSlide = /* GraphQL */ `
  mutation UpdateStudyDocumentSlide(
    $input: UpdateStudyDocumentSlideInput!
    $condition: ModelStudyDocumentSlideConditionInput
  ) {
    updateStudyDocumentSlide(input: $input, condition: $condition) {
      id
      levelId
      studyDocumentElements {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudyDocumentSlide = /* GraphQL */ `
  mutation DeleteStudyDocumentSlide(
    $input: DeleteStudyDocumentSlideInput!
    $condition: ModelStudyDocumentSlideConditionInput
  ) {
    deleteStudyDocumentSlide(input: $input, condition: $condition) {
      id
      levelId
      studyDocumentElements {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudyDocumentElement = /* GraphQL */ `
  mutation CreateStudyDocumentElement(
    $input: CreateStudyDocumentElementInput!
    $condition: ModelStudyDocumentElementConditionInput
  ) {
    createStudyDocumentElement(input: $input, condition: $condition) {
      id
      pageId
      type
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudyDocumentElement = /* GraphQL */ `
  mutation UpdateStudyDocumentElement(
    $input: UpdateStudyDocumentElementInput!
    $condition: ModelStudyDocumentElementConditionInput
  ) {
    updateStudyDocumentElement(input: $input, condition: $condition) {
      id
      pageId
      type
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudyDocumentElement = /* GraphQL */ `
  mutation DeleteStudyDocumentElement(
    $input: DeleteStudyDocumentElementInput!
    $condition: ModelStudyDocumentElementConditionInput
  ) {
    deleteStudyDocumentElement(input: $input, condition: $condition) {
      id
      pageId
      type
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExam = /* GraphQL */ `
  mutation CreateExam($input: CreateExamInput!, $condition: ModelExamConditionInput) {
    createExam(input: $input, condition: $condition) {
      id
      minScore
      maxSCore
      scoreFeedback
      correctAnswers
      correctAnswersFeedback
      inCorrectAnswers
      inCorrectAnswersFeedback
      ranks
      selectedEvaluations
      examSections {
        nextToken
        __typename
      }
      examColorScheme
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateExam = /* GraphQL */ `
  mutation UpdateExam($input: UpdateExamInput!, $condition: ModelExamConditionInput) {
    updateExam(input: $input, condition: $condition) {
      id
      minScore
      maxSCore
      scoreFeedback
      correctAnswers
      correctAnswersFeedback
      inCorrectAnswers
      inCorrectAnswersFeedback
      ranks
      selectedEvaluations
      examSections {
        nextToken
        __typename
      }
      examColorScheme
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteExam = /* GraphQL */ `
  mutation DeleteExam($input: DeleteExamInput!, $condition: ModelExamConditionInput) {
    deleteExam(input: $input, condition: $condition) {
      id
      minScore
      maxSCore
      scoreFeedback
      correctAnswers
      correctAnswersFeedback
      inCorrectAnswers
      inCorrectAnswersFeedback
      ranks
      selectedEvaluations
      examSections {
        nextToken
        __typename
      }
      examColorScheme
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGlobal = /* GraphQL */ `
  mutation CreateGlobal(
    $input: CreateGlobalInput!
    $condition: ModelGlobalConditionInput
  ) {
    createGlobal(input: $input, condition: $condition) {
      id
      imageUrl
      homeImageUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGlobal = /* GraphQL */ `
  mutation UpdateGlobal(
    $input: UpdateGlobalInput!
    $condition: ModelGlobalConditionInput
  ) {
    updateGlobal(input: $input, condition: $condition) {
      id
      imageUrl
      homeImageUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGlobal = /* GraphQL */ `
  mutation DeleteGlobal(
    $input: DeleteGlobalInput!
    $condition: ModelGlobalConditionInput
  ) {
    deleteGlobal(input: $input, condition: $condition) {
      id
      imageUrl
      homeImageUrl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExamSection = /* GraphQL */ `
  mutation CreateExamSection(
    $input: CreateExamSectionInput!
    $condition: ModelExamSectionConditionInput
  ) {
    createExamSection(input: $input, condition: $condition) {
      id
      command
      createdAt
      examId
      questions {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateExamSection = /* GraphQL */ `
  mutation UpdateExamSection(
    $input: UpdateExamSectionInput!
    $condition: ModelExamSectionConditionInput
  ) {
    updateExamSection(input: $input, condition: $condition) {
      id
      command
      createdAt
      examId
      questions {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteExamSection = /* GraphQL */ `
  mutation DeleteExamSection(
    $input: DeleteExamSectionInput!
    $condition: ModelExamSectionConditionInput
  ) {
    deleteExamSection(input: $input, condition: $condition) {
      id
      command
      createdAt
      examId
      questions {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createExamQuestion = /* GraphQL */ `
  mutation CreateExamQuestion(
    $input: CreateExamQuestionInput!
    $condition: ModelExamQuestionConditionInput
  ) {
    createExamQuestion(input: $input, condition: $condition) {
      id
      createdAt
      examSectionId
      statement
      optionA
      optionB
      optionC
      optionD
      correctOptions
      image
      audio
      needsRecording
      updatedAt
      __typename
    }
  }
`;
export const updateExamQuestion = /* GraphQL */ `
  mutation UpdateExamQuestion(
    $input: UpdateExamQuestionInput!
    $condition: ModelExamQuestionConditionInput
  ) {
    updateExamQuestion(input: $input, condition: $condition) {
      id
      createdAt
      examSectionId
      statement
      optionA
      optionB
      optionC
      optionD
      correctOptions
      image
      audio
      needsRecording
      updatedAt
      __typename
    }
  }
`;
export const deleteExamQuestion = /* GraphQL */ `
  mutation DeleteExamQuestion(
    $input: DeleteExamQuestionInput!
    $condition: ModelExamQuestionConditionInput
  ) {
    deleteExamQuestion(input: $input, condition: $condition) {
      id
      createdAt
      examSectionId
      statement
      optionA
      optionB
      optionC
      optionD
      correctOptions
      image
      audio
      needsRecording
      updatedAt
      __typename
    }
  }
`;
export const createSlideObjectCoords = /* GraphQL */ `
  mutation CreateSlideObjectCoords(
    $input: CreateSlideObjectCoordsInput!
    $condition: ModelSlideObjectCoordsConditionInput
  ) {
    createSlideObjectCoords(input: $input, condition: $condition) {
      id
      x
      y
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSlideObjectCoords = /* GraphQL */ `
  mutation UpdateSlideObjectCoords(
    $input: UpdateSlideObjectCoordsInput!
    $condition: ModelSlideObjectCoordsConditionInput
  ) {
    updateSlideObjectCoords(input: $input, condition: $condition) {
      id
      x
      y
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSlideObjectCoords = /* GraphQL */ `
  mutation DeleteSlideObjectCoords(
    $input: DeleteSlideObjectCoordsInput!
    $condition: ModelSlideObjectCoordsConditionInput
  ) {
    deleteSlideObjectCoords(input: $input, condition: $condition) {
      id
      x
      y
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSlideImage = /* GraphQL */ `
  mutation CreateSlideImage(
    $input: CreateSlideImageInput!
    $condition: ModelSlideImageConditionInput
  ) {
    createSlideImage(input: $input, condition: $condition) {
      id
      x
      y
      width
      height
      imageUrl
      styles
      coords {
        id
        x
        y
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      learningContentSlideImagesId
      slideImageCoordsId
      __typename
    }
  }
`;
export const updateSlideImage = /* GraphQL */ `
  mutation UpdateSlideImage(
    $input: UpdateSlideImageInput!
    $condition: ModelSlideImageConditionInput
  ) {
    updateSlideImage(input: $input, condition: $condition) {
      id
      x
      y
      width
      height
      imageUrl
      styles
      coords {
        id
        x
        y
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      learningContentSlideImagesId
      slideImageCoordsId
      __typename
    }
  }
`;
export const deleteSlideImage = /* GraphQL */ `
  mutation DeleteSlideImage(
    $input: DeleteSlideImageInput!
    $condition: ModelSlideImageConditionInput
  ) {
    deleteSlideImage(input: $input, condition: $condition) {
      id
      x
      y
      width
      height
      imageUrl
      styles
      coords {
        id
        x
        y
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      learningContentSlideImagesId
      slideImageCoordsId
      __typename
    }
  }
`;
export const createSlideText = /* GraphQL */ `
  mutation CreateSlideText(
    $input: CreateSlideTextInput!
    $condition: ModelSlideTextConditionInput
  ) {
    createSlideText(input: $input, condition: $condition) {
      id
      x
      y
      width
      height
      text
      styles
      coords {
        id
        x
        y
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      learningContentSlideTextsId
      slideTextCoordsId
      __typename
    }
  }
`;
export const updateSlideText = /* GraphQL */ `
  mutation UpdateSlideText(
    $input: UpdateSlideTextInput!
    $condition: ModelSlideTextConditionInput
  ) {
    updateSlideText(input: $input, condition: $condition) {
      id
      x
      y
      width
      height
      text
      styles
      coords {
        id
        x
        y
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      learningContentSlideTextsId
      slideTextCoordsId
      __typename
    }
  }
`;
export const deleteSlideText = /* GraphQL */ `
  mutation DeleteSlideText(
    $input: DeleteSlideTextInput!
    $condition: ModelSlideTextConditionInput
  ) {
    deleteSlideText(input: $input, condition: $condition) {
      id
      x
      y
      width
      height
      text
      styles
      coords {
        id
        x
        y
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      learningContentSlideTextsId
      slideTextCoordsId
      __typename
    }
  }
`;
export const createLearningContentSlide = /* GraphQL */ `
  mutation CreateLearningContentSlide(
    $input: CreateLearningContentSlideInput!
    $condition: ModelLearningContentSlideConditionInput
  ) {
    createLearningContentSlide(input: $input, condition: $condition) {
      id
      timestamp
      texts {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      exportedImage
      numberToSort
      minCalification
      realCalification
      createdAt
      updatedAt
      studentsLevelsSlidesId
      __typename
    }
  }
`;
export const updateLearningContentSlide = /* GraphQL */ `
  mutation UpdateLearningContentSlide(
    $input: UpdateLearningContentSlideInput!
    $condition: ModelLearningContentSlideConditionInput
  ) {
    updateLearningContentSlide(input: $input, condition: $condition) {
      id
      timestamp
      texts {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      exportedImage
      numberToSort
      minCalification
      realCalification
      createdAt
      updatedAt
      studentsLevelsSlidesId
      __typename
    }
  }
`;
export const deleteLearningContentSlide = /* GraphQL */ `
  mutation DeleteLearningContentSlide(
    $input: DeleteLearningContentSlideInput!
    $condition: ModelLearningContentSlideConditionInput
  ) {
    deleteLearningContentSlide(input: $input, condition: $condition) {
      id
      timestamp
      texts {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      exportedImage
      numberToSort
      minCalification
      realCalification
      createdAt
      updatedAt
      studentsLevelsSlidesId
      __typename
    }
  }
`;
export const createStudentsLevels = /* GraphQL */ `
  mutation CreateStudentsLevels(
    $input: CreateStudentsLevelsInput!
    $condition: ModelStudentsLevelsConditionInput
  ) {
    createStudentsLevels(input: $input, condition: $condition) {
      id
      content
      slides {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentsLevels = /* GraphQL */ `
  mutation UpdateStudentsLevels(
    $input: UpdateStudentsLevelsInput!
    $condition: ModelStudentsLevelsConditionInput
  ) {
    updateStudentsLevels(input: $input, condition: $condition) {
      id
      content
      slides {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentsLevels = /* GraphQL */ `
  mutation DeleteStudentsLevels(
    $input: DeleteStudentsLevelsInput!
    $condition: ModelStudentsLevelsConditionInput
  ) {
    deleteStudentsLevels(input: $input, condition: $condition) {
      id
      content
      slides {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserStudyProgress = /* GraphQL */ `
  mutation CreateUserStudyProgress(
    $input: CreateUserStudyProgressInput!
    $condition: ModelUserStudyProgressConditionInput
  ) {
    createUserStudyProgress(input: $input, condition: $condition) {
      id
      owner
      name
      progress
      lastSlideID
      level
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserStudyProgress = /* GraphQL */ `
  mutation UpdateUserStudyProgress(
    $input: UpdateUserStudyProgressInput!
    $condition: ModelUserStudyProgressConditionInput
  ) {
    updateUserStudyProgress(input: $input, condition: $condition) {
      id
      owner
      name
      progress
      lastSlideID
      level
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserStudyProgress = /* GraphQL */ `
  mutation DeleteUserStudyProgress(
    $input: DeleteUserStudyProgressInput!
    $condition: ModelUserStudyProgressConditionInput
  ) {
    deleteUserStudyProgress(input: $input, condition: $condition) {
      id
      owner
      name
      progress
      lastSlideID
      level
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTextNote = /* GraphQL */ `
  mutation CreateTextNote(
    $input: CreateTextNoteInput!
    $condition: ModelTextNoteConditionInput
  ) {
    createTextNote(input: $input, condition: $condition) {
      id
      message
      from
      for
      date
      role
      type
      createBy
      fontSize
      fontFamily
      fontColor
      deadline
      typing
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTextNote = /* GraphQL */ `
  mutation UpdateTextNote(
    $input: UpdateTextNoteInput!
    $condition: ModelTextNoteConditionInput
  ) {
    updateTextNote(input: $input, condition: $condition) {
      id
      message
      from
      for
      date
      role
      type
      createBy
      fontSize
      fontFamily
      fontColor
      deadline
      typing
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTextNote = /* GraphQL */ `
  mutation DeleteTextNote(
    $input: DeleteTextNoteInput!
    $condition: ModelTextNoteConditionInput
  ) {
    deleteTextNote(input: $input, condition: $condition) {
      id
      message
      from
      for
      date
      role
      type
      createBy
      fontSize
      fontFamily
      fontColor
      deadline
      typing
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBackgroundNote = /* GraphQL */ `
  mutation CreateBackgroundNote(
    $input: CreateBackgroundNoteInput!
    $condition: ModelBackgroundNoteConditionInput
  ) {
    createBackgroundNote(input: $input, condition: $condition) {
      id
      BackgroundColor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBackgroundNote = /* GraphQL */ `
  mutation UpdateBackgroundNote(
    $input: UpdateBackgroundNoteInput!
    $condition: ModelBackgroundNoteConditionInput
  ) {
    updateBackgroundNote(input: $input, condition: $condition) {
      id
      BackgroundColor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBackgroundNote = /* GraphQL */ `
  mutation DeleteBackgroundNote(
    $input: DeleteBackgroundNoteInput!
    $condition: ModelBackgroundNoteConditionInput
  ) {
    deleteBackgroundNote(input: $input, condition: $condition) {
      id
      BackgroundColor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDigitalBook = /* GraphQL */ `
  mutation CreateDigitalBook(
    $input: CreateDigitalBookInput!
    $condition: ModelDigitalBookConditionInput
  ) {
    createDigitalBook(input: $input, condition: $condition) {
      id
      bookName
      main
      levels {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDigitalBook = /* GraphQL */ `
  mutation UpdateDigitalBook(
    $input: UpdateDigitalBookInput!
    $condition: ModelDigitalBookConditionInput
  ) {
    updateDigitalBook(input: $input, condition: $condition) {
      id
      bookName
      main
      levels {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDigitalBook = /* GraphQL */ `
  mutation DeleteDigitalBook(
    $input: DeleteDigitalBookInput!
    $condition: ModelDigitalBookConditionInput
  ) {
    deleteDigitalBook(input: $input, condition: $condition) {
      id
      bookName
      main
      levels {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDigitalBookLevel = /* GraphQL */ `
  mutation CreateDigitalBookLevel(
    $input: CreateDigitalBookLevelInput!
    $condition: ModelDigitalBookLevelConditionInput
  ) {
    createDigitalBookLevel(input: $input, condition: $condition) {
      id
      levelName
      numeration
      digitalBookId
      digitalBook {
        id
        bookName
        main
        createdAt
        updatedAt
        __typename
      }
      createdAt
      slides {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateDigitalBookLevel = /* GraphQL */ `
  mutation UpdateDigitalBookLevel(
    $input: UpdateDigitalBookLevelInput!
    $condition: ModelDigitalBookLevelConditionInput
  ) {
    updateDigitalBookLevel(input: $input, condition: $condition) {
      id
      levelName
      numeration
      digitalBookId
      digitalBook {
        id
        bookName
        main
        createdAt
        updatedAt
        __typename
      }
      createdAt
      slides {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteDigitalBookLevel = /* GraphQL */ `
  mutation DeleteDigitalBookLevel(
    $input: DeleteDigitalBookLevelInput!
    $condition: ModelDigitalBookLevelConditionInput
  ) {
    deleteDigitalBookLevel(input: $input, condition: $condition) {
      id
      levelName
      numeration
      digitalBookId
      digitalBook {
        id
        bookName
        main
        createdAt
        updatedAt
        __typename
      }
      createdAt
      slides {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createDigitalBookSlide = /* GraphQL */ `
  mutation CreateDigitalBookSlide(
    $input: CreateDigitalBookSlideInput!
    $condition: ModelDigitalBookSlideConditionInput
  ) {
    createDigitalBookSlide(input: $input, condition: $condition) {
      id
      digitalBookLevelId
      digitalBookLevel {
        id
        levelName
        numeration
        digitalBookId
        createdAt
        updatedAt
        __typename
      }
      shapes {
        nextToken
        __typename
      }
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDigitalBookSlide = /* GraphQL */ `
  mutation UpdateDigitalBookSlide(
    $input: UpdateDigitalBookSlideInput!
    $condition: ModelDigitalBookSlideConditionInput
  ) {
    updateDigitalBookSlide(input: $input, condition: $condition) {
      id
      digitalBookLevelId
      digitalBookLevel {
        id
        levelName
        numeration
        digitalBookId
        createdAt
        updatedAt
        __typename
      }
      shapes {
        nextToken
        __typename
      }
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDigitalBookSlide = /* GraphQL */ `
  mutation DeleteDigitalBookSlide(
    $input: DeleteDigitalBookSlideInput!
    $condition: ModelDigitalBookSlideConditionInput
  ) {
    deleteDigitalBookSlide(input: $input, condition: $condition) {
      id
      digitalBookLevelId
      digitalBookLevel {
        id
        levelName
        numeration
        digitalBookId
        createdAt
        updatedAt
        __typename
      }
      shapes {
        nextToken
        __typename
      }
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDigitalBookShape = /* GraphQL */ `
  mutation CreateDigitalBookShape(
    $input: CreateDigitalBookShapeInput!
    $condition: ModelDigitalBookShapeConditionInput
  ) {
    createDigitalBookShape(input: $input, condition: $condition) {
      id
      shapeId
      style
      zIndex
      digitalBookSlideId
      digitalBookLevelId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDigitalBookShape = /* GraphQL */ `
  mutation UpdateDigitalBookShape(
    $input: UpdateDigitalBookShapeInput!
    $condition: ModelDigitalBookShapeConditionInput
  ) {
    updateDigitalBookShape(input: $input, condition: $condition) {
      id
      shapeId
      style
      zIndex
      digitalBookSlideId
      digitalBookLevelId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDigitalBookShape = /* GraphQL */ `
  mutation DeleteDigitalBookShape(
    $input: DeleteDigitalBookShapeInput!
    $condition: ModelDigitalBookShapeConditionInput
  ) {
    deleteDigitalBookShape(input: $input, condition: $condition) {
      id
      shapeId
      style
      zIndex
      digitalBookSlideId
      digitalBookLevelId
      createdAt
      updatedAt
      __typename
    }
  }
`;
