import { useEffect, useState } from 'react';
import { ASSIGN_TEACHER_TO_CLASSROOM } from '../graphql/mutations';
import { useApolloClient, useMutation, useSubscription } from '@apollo/client';
import { LIST_CLASSROOMS, SUBS_ON_UPDATE_TEACHERS } from '../graphql/queries';

export const useAssignTeacherToClassRoom = () => {
  const [
    assignTeacherToClassRoomMutation,
    { loading: assignTeacherLoading, error: assignTeacherMutationError }
  ] = useMutation(ASSIGN_TEACHER_TO_CLASSROOM, {
    refetchQueries: ['listClassRooms']
  });
  const [teacherAssignedError, setTeacherAssignedError] = useState(null);
  const onSubsUpdateTeacher = useSubscription(SUBS_ON_UPDATE_TEACHERS);

  useEffect(() => {
    setTeacherAssignedError(assignTeacherMutationError);
  }, [assignTeacherMutationError]);

  const client = useApolloClient();
  const [classRoomsData, setClassRoomsData] = useState();

  function fetchClassRoomsData() {
    try {
      let data = client.readQuery({ query: LIST_CLASSROOMS });
      setClassRoomsData(data);
    } catch (error) {}
  }

  function isTeacherAssignedToClassRoom(classRoomId) {
    if (classRoomsData) {
      let data = client.readQuery({ query: LIST_CLASSROOMS });
      const classRoom = data.listClassRooms.items.find(
        classRoom => classRoom.id === classRoomId
      ) || { teachers: { items: [] } };
      return classRoom.teachers.items[0];
    }
    return false;
  }

  function assignTeacherToClassRoom(mutationOptions) {
    setTeacherAssignedError(null);
    if (isTeacherAssignedToClassRoom(mutationOptions.variables.classRoomId)) {
      return setTeacherAssignedError(
        new Error('There is a teacher assigned to this classRoom')
      );
    }
    return assignTeacherToClassRoomMutation(mutationOptions);
  }

  useEffect(() => {
    fetchClassRoomsData();
  }, [onSubsUpdateTeacher]);

  useEffect(() => {
    fetchClassRoomsData();
    return () => {};
  }, []);

  return {
    assignTeacherToClassRoom,
    isTeacherAssignedToClassRoom,
    assignTeacherLoading,
    teacherAssignedError,
    setTeacherAssignedError
  };
};
