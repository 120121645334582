import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Group } from 'react-konva';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import ReplayIcon from '@material-ui/icons/Replay';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Html } from 'react-konva-utils';
import Bar from './Bar';
import './AudioPlayerDashboard.sass';
import CloseIcon from '@material-ui/icons/Close';
import {
  useWhiteBoard,
  whiteBoardContext
} from 'components/Whiteboard/WhiteBoardProvider';
import { Height } from '@material-ui/icons';
import zIndex from '@material-ui/core/styles/zIndex';

const AudioPlayerDashboard = ({
  x,
  y,
  name,
  url,
  isCrystal,
  deleteCanvasAudio,
  toggleHideElements,
  selectedTool,
  shapes
}) => {
  const audioRef = useRef(null);
  const [audioBar, setAudioBar] = useState(false);
  const [duration, setDuration] = useState(1);
  const [curTime, setCurTime] = useState(0);
  const [urls, setUrls] = useState(0);
  const [shape, setShapes] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState(null);
  const [lastPlayPauseClick, setLastPlayPauseClick] = useState(0);
  const [volume, setVolume] = useState(1);
  const [volumeVisible, setVolumeVisible] = useState(false);
  const viewportWidth = Math.round(document.documentElement.clientWidth / 100);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
        setCurTime(audio.currentTime);
      };

      setShapes(shape);

      if (viewportWidth < 11) {
        toggleHideElements(audioBar);
      }

      if (urls == null || urls != url) {
        setUrls(url);
      }
      const handleTimeUpdate = () => {
        setCurTime(audio.currentTime);
      };

      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [audioBar]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      if (playing) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [playing, audioBar]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio && clickedTime !== null) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }
  }, [clickedTime]);

  useEffect(() => {
    const handleKeyDown = event => {
      switch (event.key) {
        case 'ArrowRight':
          handleSeek(1);
          break;
        case 'ArrowLeft':
          handleSeek(-1);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (curTime >= duration) {
      setPlaying(false);
    }
  }, [curTime, duration]);

  const toggleElements = () => {
    setAudioBar(!audioBar);
    setPlaying(false);
    if (viewportWidth < 11) {
      toggleHideElements(!audioBar);
    }
  };

  const handlePlayPause = () => {
    const now = Date.now();
    if (now - lastPlayPauseClick < 300) return;
    setLastPlayPauseClick(now);
    setPlaying(prev => !prev);
  };

  const handleSeek = seconds => {
    const now = Date.now();
    if (now - lastPlayPauseClick < 300) return;
    setLastPlayPauseClick(now);
    if (audioRef.current) {
      let newTime = audioRef.current.currentTime + seconds;
      if (newTime > audioRef.current.duration) {
        newTime = audioRef.current.duration;
      } else if (newTime < 0) {
        newTime = 0;
      }
      setClickedTime(newTime);
    }
  };

  const handleRestart = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = 0;
      setCurTime(0);
    }
  };

  const handleTimeUpdate = time => {
    if (audioRef.current) {
      audioRef.current.currentTime = time;
      setCurTime(time);
    }
  };

  const handleVolumeChange = event => {
    setVolume(parseFloat(event.target.value));
  };

  const toggleVolumeControl = () => {
    setVolumeVisible(prev => !prev);
  };

  const formatName = (name, maxLength) => {
    return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
  };

  const validAudioExtensions = ['mp3', 'wav', 'ogg', 'm4a', 'flac'];
  const audioExtension = name.split('.').pop().toLowerCase();

  if (!validAudioExtensions.includes(audioExtension)) {
    alert('Error: El archivo proporcionado no es un archivo de audio válido.');
    return null;
  }

  return (
    <Group x={50} y={50}>
      {!audioBar && (
        <Html divProps={{}}>
          <div className="audio-player">
            <div className="audio-button">
              <VolumeUpIcon className="audio-icon" />
              <span
                className={isCrystal ? 'audio-name-crystal' : 'audio-name'}
                onClick={toggleElements}
              >
                {formatName(name, 24)}
              </span>
              <button className="audio-close" onClick={deleteCanvasAudio}>
                <CloseIcon />
              </button>
            </div>
          </div>
        </Html>
      )}
      {audioBar && (
        <Group x={0} y={-20}>
          <Html
            divProps={
              selectedTool !== 'POINTER' &&
              viewportWidth > 11 && {
                style: {
                  position: 'absolute',
                  zIndex: -1
                }
              }
            }
          >
            <div
              className={isCrystal ? 'audio-modal crystal' : 'audio-modal'}
              style={
                selectedTool !== 'POINTER' && viewportWidth > 11
                  ? { pointerEvents: 'none', position: 'absolute' }
                  : {}
              }
            >
              <div className="controls">
                <button onClick={() => handleSeek(-5)}>
                  <FastRewindIcon className="left" />
                </button>
                <button onClick={() => handleSeek(-1)}>
                  <SkipPreviousIcon className="left" />
                </button>
                <button onClick={handlePlayPause} className="play">
                  {playing ? <PauseIcon /> : <PlayArrowIcon />}
                </button>
                <button onClick={handleRestart} className="restart">
                  <ReplayIcon />
                </button>
                <button onClick={() => handleSeek(1)}>
                  <SkipNextIcon />
                </button>
                <button onClick={() => handleSeek(5)}>
                  <FastForwardIcon />
                </button>
                <button onClick={toggleVolumeControl} className="volume-button">
                  {volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
                {volumeVisible && (
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={handleVolumeChange}
                    className="volume-control"
                    style={{
                      background: `linear-gradient(to right, #7719BA ${
                        volume * 100
                      }%, rgba(0, 0, 0, 0.3) ${volume * 100}%)`
                    }}
                  />
                )}
              </div>
              <Bar
                duration={duration}
                curTime={curTime}
                onTimeUpdate={handleTimeUpdate}
                isCrystal={isCrystal}
                toggleAudioBar={toggleElements}
                playing={playing}
                selectedTool={selectedTool}
              />
            </div>
          </Html>
        </Group>
      )}
      <Html>
        <audio ref={audioRef} src={urls} style={{ display: 'none' }} />
      </Html>
    </Group>
  );
};

export default AudioPlayerDashboard;
