import React, { useEffect, useState } from 'react';
import { useConfigText } from 'components/DigitalBook/hooks/useConfigText';
import { useCurrentSlide } from 'components/DigitalBook/hooks/useCurrentSlide';
import minus from 'assets/icons/minus-icon.svg';

export default function DecreaseTextTool({ style, testBtn, stylesTextControls }) {
  const [pressTime, setPressTime] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const { state: stateCurrentSlide } = useCurrentSlide();
  const { action: actionConfigText, state: stateConfigText } = useConfigText();

  const handleDecrease = () => {
    setIsClick(true);
    actionConfigText.handleEditConfigText(
      { fontSize: stateConfigText.configText.fontSize - 1 },
      stateCurrentSlide.selectIdShape,
      false
    );
  };

  const handleMouseUp = () => {
    setIsClick(false);
    actionConfigText.handleEditConfigText(
      { fontSize: stateConfigText.configText.fontSize - 1 },
      stateCurrentSlide.selectIdShape,
      true
    );
  };

  useEffect(() => {
    if (isClick)
      setPressTime(
        setTimeout(() => {
          actionConfigText.handleEditConfigText(
            { fontSize: stateConfigText.configText.fontSize - 1 },
            stateCurrentSlide.selectIdShape,
            false
          );
        }, 100)
      );
    else clearTimeout(pressTime);
  }, [
    isClick,
    stateConfigText.configText.fontSize,
    actionConfigText.handleEditConfigText,
    stateCurrentSlide.selectIdShape
  ]);

  return (
    <React.Fragment>
      <div
        title="Decrease text size"
        onMouseDown={handleDecrease}
        onMouseUp={handleMouseUp}
        className={`
          ${style} 
          ${stylesTextControls}
          `}
      >
        <img src={minus} width={20} alt="increase" />
      </div>
    </React.Fragment>
  );
}
