import React from 'react';
import '../PublicChat.css';
import moment from 'moment';
import PublicWBCAvatar from '../avatar/PublicWBCAvatar';

const Message = ({
  name,
  message,
  data,
  setChangedata,
  avatar,
  avatarStatus,
  textColor,
  isModeAdmin,
  isAdmin,
  ownerMode,
  color,
  bellNotification,
  userId,
  setUserMessagePrivate,
  privat,
  recipient
}) => {
  const getTextColorClass = isCurrentUser => {
    const msText =
      textColor === 'alternado'
        ? 'changeColorToViolet'
        : textColor === 'grey'
        ? 'changeColorToGrey'
        : 'changeColorTowhite';

    const msTextA =
      textColor === 'alternado'
        ? 'changeColorToBlue'
        : textColor === 'grey'
        ? 'changeColorToGrey'
        : 'changeColorTowhite';

    return isCurrentUser ? msText : msTextA;
  };

  const isPrivateDiv = () => {
    if (
      recipient === window.sessionStorage.getItem('user_Id') ||
      userId === window.sessionStorage.getItem('user_Id')
    ) {
      const messageClass = getTextColorClass(
        userId === window.sessionStorage.getItem('user_Id')
      );

      return (
        <div
          className="message-private"
          onDoubleClick={() =>
            recipient === window.sessionStorage.getItem('user_Id')
              ? setUserMessagePrivate(userId, name)
              : null
          }
        >
          <PublicWBCAvatar
            avatar={avatar}
            setChangedata={setChangedata}
            isAdmin={isAdmin}
            avatarStatus={avatarStatus}
            ownerMode={ownerMode}
            bellNotification={bellNotification}
            userId={userId}
            color={color}
            isModeAdmin={isModeAdmin}
          />
          <div className={`${messageClass} message-privat`}>
            <h5
              className={messageClass}
              onDoubleClick={() => setUserMessagePrivate(userId, name)}
              style={{ cursor: 'pointer', textTransform: 'capitalize' }}
            >
              {recipient === window.sessionStorage.getItem('user_Id')
                ? 'Private message from ' + name
                : name}
            </h5>
            <p
              id="tal"
              className={messageClass}
              style={{ wordWrap: 'break-word', whiteSpace: '-moz-pre-wrap' }}
            >
              {message}
              <span className={`${messageClass} timeD`}>
                {moment(data).format('hh:mm:A')}
              </span>
            </p>
          </div>
        </div>
      );
    }
  };

  const isPublicDiv = () => {
    const messageClass = getTextColorClass(
      userId === window.sessionStorage.getItem('user_Id')
    );

    return (
      <div
        className="message"
        style={{ maxWidth: window.innerWidth <= 600 ? '60%' : '100%' }}
      >
        <PublicWBCAvatar
          avatar={avatar}
          setChangedata={setChangedata}
          isAdmin={isAdmin}
          avatarStatus={avatarStatus}
          ownerMode={ownerMode}
          bellNotification={bellNotification}
          userId={userId}
          color={color}
          isModeAdmin={isModeAdmin}
        />
        <div
          className={messageClass}
          style={{ boxSizing: 'border-box', maxWidth: '100%', background: 'transparent' }}
        >
          <h5
            className={messageClass}
            onDoubleClick={() => setUserMessagePrivate(userId, name)}
            style={{ cursor: 'pointer', textTransform: 'capitalize' }}
          >
            {name}
          </h5>
          <p
            className={messageClass}
            style={{ wordWrap: 'break-word', whiteSpace: '-moz-pre-wrap' }}
          >
            {message}
            <span className={`${messageClass} timeD`}>
              {moment(data).format('hh:mm:A')}
            </span>
          </p>
        </div>
      </div>
    );
  };

  return <React.Fragment>{privat ? isPrivateDiv() : isPublicDiv()}</React.Fragment>;
};

export default Message;
